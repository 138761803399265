<template>
  <div class="mainBody" style="padding-left: 0px; padding-right: 0px">
    <div class="mainContainer" style="padding-left: 0px; padding-right: 0px">
      <div
        id="primary"
        style="padding-left: 0px; padding-right: 0px"
        class="content-area primary container woocommerce"
      >
        <!-- <main id="main" class="site-main"> -->
        <div id="main" class="site-main">
          <div class="style_page">
            <div
              class="post-11879 page type-page status-publish ast-article-single"
              id="post-11879"
              itemtype="https://schema.org/CreativeWork"
              itemscope="itemscope"
            >
              <!-- <header class="entry-header ast-header-without-markup"></header> -->
              <!-- .entry-header -->

              <div
                class="entry-content clear woocommerce-account"
                itemprop="text"
              >
                <div class="moduleBox flex" style="margin-top: -10px">
                  <section class="section">
                    <div class="container row">
                      <div class="col-md-6 mt10">
                        <img src="./images/img1.png" alt="" />
                      </div>
                      <div class="col-md-6 mt20">
                        <p>OLL and PLL is vertical view</p>
                        <p>
                          OLL: The black bars and square represent the yellow
                          pieces
                        </p>
                        <p>
                          PLL: The black bars represent the samecolored pieces
                        </p>
                      </div>
                    </div>
                  </section>

                  <section class="section">
                    <div class="container">
                      <div class="exampleGrid2">
                        <div class="unit">
                          <div class="exampleImg">
                            <img src="./images/img2.png" alt="" />
                          </div>
                        </div>
                        <div class="unit">
                          <div class="exampleImg">
                            <img src="./images/img3.png" alt="" />
                          </div>
                        </div>
                        <div class="unit">
                          <div class="exampleImg">
                            <img src="./images/img4.png" alt="" />
                          </div>
                        </div>
                        <div class="unit">
                          <div class="exampleImg">
                            <img src="./images/img5.png" alt="" />
                          </div>
                        </div>
                        <div class="unit">
                          <div class="exampleImg">
                            <img src="./images/img6.png" alt="" />
                          </div>
                        </div>
                        <div class="unit">
                          <div class="exampleImg">
                            <img src="./images/img7.png" alt="" />
                          </div>
                        </div>
                        <div class="unit">
                          <div class="exampleImg">
                            <img src="./images/img8.png" alt="" />
                          </div>
                        </div>
                        <div class="unit">
                          <div class="exampleImg">
                            <img src="./images/img9.png" alt="" />
                          </div>
                        </div>
                        <div class="unit">
                          <div class="exampleImg">
                            <img src="./images/img10.png" alt="" />
                          </div>
                        </div>
                        <div class="unit">
                          <div class="exampleImg">
                            <img src="./images/img11.png" alt="" />
                          </div>
                        </div>
                        <div class="unit">
                          <div class="exampleImg">
                            <img src="./images/img12.png" alt="" />
                          </div>
                        </div>
                        <div class="unit">
                          <div class="exampleImg">
                            <img src="./images/img13.png" alt="" />
                          </div>
                        </div>
                        <div class="unit">
                          <div class="exampleImg">
                            <img src="./images/img14.png" alt="" />
                          </div>
                        </div>
                        <div class="unit">
                          <div class="exampleImg">
                            <img src="./images/img15.png" alt="" />
                          </div>
                        </div>
                        <div class="unit">
                          <div class="exampleImg">
                            <img src="./images/img16.png" alt="" />
                          </div>
                        </div>
                        <div class="unit">
                          <div class="exampleImg">
                            <img src="./images/img17.png" alt="" />
                          </div>
                        </div>
                        <div class="unit">
                          <div class="exampleImg">
                            <img src="./images/img18.png" alt="" />
                          </div>
                        </div>
                        <div class="unit">
                          <div class="exampleImg">
                            <img src="./images/img19.png" alt="" />
                          </div>
                        </div>
                        <div class="unit">
                          <div class="exampleImg">
                            <img src="./images/img20.png" alt="" />
                          </div>
                        </div>
                        <div class="unit">
                          <div class="exampleImg">
                            <img src="./images/img21.png" alt="" />
                          </div>
                        </div>
                        <div class="unit">
                          <div class="exampleImg">
                            <img src="./images/img22.png" alt="" />
                          </div>
                        </div>
                      </div>
                      <div class="module mt20">
                        <div class="exampleGrid exampleGrid3">
                          <div class="unit">
                            <p class="">
                              Lowercase: Turn 2 Layers,
                              <b>x (R), y (U), z (F)</b>: Whole rotation<br />
                              Letter+ &#8216;:Turn 90% counterclockwise<br />
                              Letter+2: Turn 180% clockwise<br />
                              Letter+&#8217;2: Turn 180% counterclockwise
                            </p>
                          </div>
                          <div class="unit">
                            <p class="">
                              <span style="color: #0075ce"
                                >Blue font: By left index finger</span
                              ><br />
                              <span style="color: #e50012"
                                >Red font: By right thumb</span
                              ><br />
                              <span style="color: #ef8200"
                                >Orange font: By right ring finger</span
                              >
                            </p>
                          </div>
                          <div class="unit">
                            <p class="">
                              (): A series of movements<br />
                              ()+2: Repeat the same series of movements once.
                              e.g<br />
                              (R U R&#8217;
                              <span class="mainColor2">U&#8217;</span>)2=(RU
                              R&#8217;
                              <span class="mainColor2">U&#8217;</span>)(R U
                              R&#8217; <span class="mainColor2">U&#8217;</span>)
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <!-- F2L -->
                  <section class="section">
                    <div class="container">
                      <!-- <button @click="showF2LAll()">Show All</button>
											<button @click="showBothUp()">Both pieces up</button> -->

                      <div style="margin-left: 3px">
                        <div style="margin-bottom: -5px">
                          <div
                            id="btn-showAll-F2L"
                            class="buy"
                            @click="showF2LAll()"
                          >
                            <span class="white-text" rel="noopener noreferrer"
                              >Show All</span
                            >
                          </div>
                          <!-- <div id="btn-hideAll-F2L" class="buy " @click="hideF2LAll()"><a
														class="" rel="noopener noreferrer">Hide All</a></div> -->
                        </div>

                        <div style="margin-bottom: -5px">
                          <!-- <div class="buy " @click="showMixedUp()"><a class=""
														rel="noopener noreferrer">Mixed</a></div> -->

                          <div
                            style="margin-right: 2px"
                            id="btn-bothDown-F2L"
                            class="buy"
                            @click="showBothDown()"
                          >
                            <span class="white-text" rel="noopener noreferrer"
                              >Both down</span
                            >
                          </div>

                          <div
                            style="margin-right: 2px"
                            id="btn-upAndMid-F2L"
                            class="buy"
                            @click="showUpAndMid()"
                          >
                            <span class="white-text" rel="noopener noreferrer"
                              >Up & Mid</span
                            >
                          </div>

                          <div
                            style="margin-right: 2px"
                            id="btn-upAndDown-F2L"
                            class="buy"
                            @click="showUpAndDown()"
                          >
                            <span class="white-text" rel="noopener noreferrer"
                              >Up & down</span
                            >
                          </div>

                          <div
                            id="btn-bothUp-F2L"
                            class="buy"
                            @click="showBothUp()"
                          >
                            <span class="white-text" rel="noopener noreferrer"
                              >Both up</span
                            >
                          </div>
                        </div>

                        <div style="margin-bottom: -5px">
                          <div
                            style="width: 240px; margin-right: 2px"
                            id="btn-bothUpWhiteUp-F2L"
                            class="buy"
                            @click="showBothUpWhiteUp()"
                          >
                            <!-- <a class="white-text" rel="noopener noreferrer"
                            >Both up-White up</a
                          > -->
                            <span class="white-text" rel=""
                              >Both up-White up</span
                            >
                          </div>
                          <div
                            style="width: 240px; margin-right: 2px"
                            id="btn-bothUpWhiteFront-F2L"
                            class="buy"
                            @click="showBothUpWhiteFront()"
                          >
                            <span class="white-text" rel="noopener noreferrer"
                              >Both up-White front</span
                            >
                          </div>
                          <div
                            style="width: 240px"
                            id="btn-bothUpWhiteSide-F2L"
                            class="buy"
                            @click="showBothUpWhiteSide()"
                          >
                            <span class="white-text" rel="noopener noreferrer"
                              >Both up-White right</span
                            >
                          </div>
                          <!-- <div id="btn-bothUpBlock-F2L" class="buy " @click="showBothUpBlock()">
													<a class="" rel="noopener noreferrer">Both up (blc)</a>
												</div> -->
                        </div>

                        <div style="margin-bottom: -5px"></div>

                        <div style="margin-bottom: 10px">
                          <div
                            style="width: 240px; margin-right: 2px"
                            id="btn-bothUp-whiteUp-block-F2L"
                            class="buy"
                            @click="showBothUpWhiteUpBlock()"
                          >
                            <span class="white-text" rel="noopener noreferrer"
                              >Up-White up(blc)</span
                            >
                          </div>

                          <div
                            style="width: 240px; margin-right: 2px"
                            id="btn-bothUp-whiteFront-block-F2L"
                            class="buy"
                            @click="showBothUpWhiteFrontBlock()"
                          >
                            <span class="white-text" rel="noopener noreferrer"
                              >Up-White front(blc)</span
                            >
                          </div>

                          <div
                            style="width: 240px"
                            id="btn-bothUp-whiteRight-block-F2L"
                            class="buy"
                            @click="showBothUpWhiteRightBlock()"
                          >
                            <span class="white-text" rel="noopener noreferrer"
                              >Up-White right(blc)</span
                            >
                          </div>
                        </div>
                      </div>

                      <div
                        class="exampleGrid exampleGrid4 flex"
                        id="First2LayerSticker"
                      >
                        <div class="firstUnit">
                          <div class="exampleText">
                            <h2 class="fsize24 mainColor">F2L</h2>
                            <p>First 2 Layers</p>
                            <p>
                              <b
                                >[SX] = R U R&#8217;<span class="mainColor2">
                                  U&#8217;</span
                                ></b
                              >
                            </p>
                            <p>
                              <b
                                >[sh] = R&#8217; F R F&#8217;<span
                                  class="mainColor2"
                                >
                                </span
                              ></b>
                            </p>
                          </div>
                        </div>

                        <!-- qwer F2L -->

                        <div id="F2L-01-DIV" class="unit" data-index="0">
                          <div class="orderNum">01</div>

                          <div id="F2L-01-IMG" class="exampleImg">
                            <img src="./images/img28.png" alt="" />
                          </div>
                          <p id="F2L-01-P" class="exampleText">
                            (R<span class="mainColor2">U&#8217;</span
                            >R&#8217;U)y'(R&#8217;U2R<span class="mainColor3"
                              >U&#8217;</span
                            ><span class="mainColor2">U&#8217;</span
                            >)(R&#8217;UR)<br /><br /><b
                              ><span class="scramble"> SC: </span></b
                            >01
                          </p>
                        </div>
                        <div id="F2L-02-DIV" class="unit" data-index="1">
                          <div class="orderNum">02</div>
                          <div id="F2L-02-IMG" class="exampleImg">
                            <img src="./images/img34.png" alt="" />
                          </div>
                          <p id="F2L-02-P" class="exampleText">
                            (UR<span class="mainColor2">U&#8217;</span
                            >R&#8217;<span class="mainColor2">U&#8217;</span
                            >)y'(R&#8217;UR)<br /><br /><b
                              ><span class="scramble"> SC: </span></b
                            ><br />F'U'FU[SX]
                          </p>
                        </div>
                        <div id="F2L-03-DIV" class="unit" data-index="2">
                          <div class="orderNum">03</div>
                          <div id="F2L-03-IMG" class="exampleImg">
                            <img src="./images/img40.png" alt="" />
                          </div>
                          <p id="F2L-03-P" class="exampleText">
                            (R&#8217;<span class="mainColor3">F&#8217;</span
                            >RU)(R<span class="mainColor2">U&#8217;</span
                            >R&#8217;F)<br /><br /><span
                              class="scramble_orange"
                            >
                              SC: 03+U</span
                            >
                          </p>
                        </div>
                        <div id="F2L-04-DIV" class="unit" data-index="3">
                          <div class="orderNum">04</div>
                          <div id="F2L-04-IMG" class="exampleImg">
                            <img src="./images/img46.png" alt="" />
                          </div>
                          <p id="F2L-04-P" class="exampleText">
                            [SX](RU2R&#8217;<span class="mainColor3"
                              >U&#8217;</span
                            >)(RUR&#8217;)<br /><br /><span class="scramblok">
                              SC: 08</span
                            >
                          </p>
                        </div>
                        <div id="F2L-05-DIV" class="unit" data-index="4">
                          <div class="orderNum">05</div>
                          <div id="F2L-05-IMG" class="exampleImg">
                            <img src="./images/img52.png" alt="" />
                          </div>
                          <p id="F2L-05-P" class="exampleText">
                            (RF)U(R<span class="mainColor2">U&#8217;</span
                            >R&#8217;<span class="mainColor3">F&#8217;</span
                            >)(<span class="mainColor2">U&#8217;</span
                            >R&#8217;)<br /><br /><b
                              ><span class="scramble"> SC: </span></b
                            >09
                          </p>
                        </div>
                        <div id="F2L-06-DIV" class="unit" data-index="5">
                          <div class="orderNum">06</div>
                          <div id="F2L-06-IMG" class="exampleImg">
                            <img src="./images/img58.png" alt="" />
                          </div>
                          <p id="F2L-06-P" class="exampleText">
                            y'(R&#8217;<span class="mainColor2">U&#8217;</span
                            >RU)(R&#8217;<span class="mainColor2">U&#8217;</span
                            >R)<br /><br /><b
                              ><span class="scramble"> SC: </span></b
                            >10
                          </p>
                        </div>
                        <div id="F2L-07-DIV" class="unit" data-index="6">
                          <div class="orderNum">07</div>
                          <div id="F2L-07-IMG" class="exampleImg">
                            <img src="./images/img23.png" alt="" />
                          </div>
                          <p id="F2L-07-P" class="exampleText">
                            (R<span class="mainColor2">U&#8217;</span
                            >R&#8217;U)(R<span class="mainColor2">U&#8217;</span
                            >R&#8217;)<br /><br /><b
                              ><span class="scramble"> SC: </span></b
                            >11
                          </p>
                        </div>
                        <div id="F2L-08-DIV" class="unit" data-index="7">
                          <div class="orderNum">08</div>
                          <div id="F2L-08-IMG" class="exampleImg">
                            <img src="./images/img29.png" alt="" />
                          </div>
                          <p id="F2L-08-P" class="exampleText">
                            (R<span class="mainColor2">U&#8217;</span
                            >R&#8217;U)(R<span class="mainColor3">U&#8217;</span
                            ><span class="mainColor2">U&#8217;</span
                            >R&#8217;U)(R<span class="mainColor2">U&#8217;</span
                            >R&#8217;)<br /><br /><b
                              ><span class="scramble"> SC: </span></b
                            >04
                          </p>
                        </div>
                        <div id="F2L-09-DIV" class="unit" data-index="8">
                          <div class="orderNum">09</div>
                          <div id="F2L-09-IMG" class="exampleImg">
                            <img src="./images/img35.png" alt="" />
                          </div>
                          <p id="F2L-09-P" class="exampleText">
                            RUF<br />[SX]<br /><span class="mainColor3"
                              >F&#8217;</span
                            >R&#8217;<br /><br /><b
                              ><span class="scramble"> SC: </span></b
                            >05
                          </p>
                        </div>
                        <div id="F2L-10-DIV" class="unit" data-index="9">
                          <div class="orderNum">10</div>
                          <div id="F2L-10-IMG" class="exampleImg">
                            <img src="./images/img41.png" alt="" />
                          </div>
                          <p id="F2L-10-P" class="exampleText">
                            y'(R&#8217;UR<span class="mainColor2">U&#8217;</span
                            >)(R&#8217;UR)<br /><br /><b
                              ><span class="scramble"> SC: </span></b
                            >06
                          </p>
                        </div>
                        <div id="F2L-11-DIV" class="unit" data-index="10">
                          <div class="orderNum">11</div>
                          <div id="F2L-11-IMG" class="exampleImg">
                            <img src="./images/img47.png" alt="" />
                          </div>
                          <p id="F2L-11-P" class="exampleText">
                            [SX](RUR&#8217;)<br /><br /><b
                              ><span class="scramble"> SC: </span></b
                            >07
                          </p>
                        </div>
                        <div id="F2L-12-DIV" class="unit" data-index="11">
                          <div class="orderNum">12</div>
                          <div id="F2L-12-IMG" class="exampleImg">
                            <img src="./images/img53.png" alt="" />
                          </div>
                          <p id="F2L-12-P" class="exampleText">
                            [SX]2(RUR&#8217;)<br /><br /><b
                              ><span class="scramble"> SC: </span></b
                            >[SX]3
                          </p>
                        </div>
                        <div id="F2L-13-DIV" class="unit" data-index="12">
                          <div class="orderNum">13</div>
                          <div id="F2L-13-IMG" class="exampleImg">
                            <img src="./images/img59.png" alt="" />
                          </div>
                          <p id="F2L-13-P" class="exampleText">
                            (R<span class="mainColor2">U&#8217;</span
                            >R&#8217;U)y'(R&#8217;UR) <br /><br /><b
                              ><span class="scramble"> SC: </span></b
                            >23, 33
                          </p>
                        </div>
                        <div id="F2L-14-DIV" class="unit" data-index="13">
                          <div class="orderNum">14</div>
                          <div id="F2L-14-IMG" class="exampleImg">
                            <img src="./images/img24.png" alt="" />
                          </div>
                          <p id="F2L-14-P" class="exampleText">
                            y'(R&#8217;U2)[SX] R<br /><br /><b
                              ><span class="scramble"> SC: </span></b
                            ><br />R' [URU'R'] U2 R
                          </p>
                        </div>
                        <div id="F2L-15-DIV" class="unit" data-index="14">
                          <div class="orderNum">15</div>
                          <div id="F2L-15-IMG" class="exampleImg">
                            <img src="./images/img30.png" alt="" />
                          </div>
                          <p id="F2L-15-P" class="exampleText">
                            y&#8217;<span class="mainColor2">U&#8217;</span
                            >(R&#8217;U2)(R<span class="mainColor2"
                              >U&#8217;</span
                            >R&#8217;UR)<br /><br /><b
                              ><span class="scramble"> SC: </span></b
                            ><br />R'U' [SX] U'RU
                          </p>
                        </div>
                        <div id="F2L-16-DIV" class="unit" data-index="15">
                          <div class="orderNum">16</div>
                          <div id="F2L-16-IMG" class="exampleImg">
                            <img src="./images/img36.png" alt="" />
                          </div>
                          <p id="F2L-16-P" class="exampleText">
                            y'(R&#8217;UR<span class="mainColor3">U&#8217;</span
                            ><span class="mainColor2">U&#8217;</span
                            >)(R&#8217;<span class="mainColor2">U&#8217;</span
                            >R)<br /><br /><b
                              ><span class="scramble"> SC: </span></b
                            >16, 36
                          </p>
                        </div>
                        <div id="F2L-17-DIV" class="unit" data-index="16">
                          <div class="orderNum">17</div>
                          <div id="F2L-17-IMG" class="exampleImg">
                            <img src="./images/img42.png" alt="" />
                          </div>
                          <p id="F2L-17-P" class="exampleText">
                            FU(R<span class="mainColor2">U&#8217;</span
                            >R&#8217;<span class="mainColor3">F&#8217;</span
                            >)(R<span class="mainColor2">U&#8217;</span
                            >R&#8217;)<br /><br /><b
                              ><span class="scramble"> SC: </span></b
                            ><br />RUR' F[SX]F'
                          </p>
                        </div>
                        <div id="F2L-18-DIV" class="unit" data-index="17">
                          <div class="orderNum">18</div>
                          <div id="F2L-18-IMG" class="exampleImg">
                            <img src="./images/img48.png" alt="" />
                          </div>
                          <p id="F2L-18-P" class="exampleText">
                            U(R<span class="mainColor2">U&#8217;</span
                            >R&#8217;<span class="mainColor2">U&#8217;</span
                            >)(R<span class="mainColor2">U&#8217;</span
                            >R&#8217;U)(R<span class="mainColor2">U&#8217;</span
                            >R&#8217;)<br /><br /><b
                              ><span class="scramble"> SC: </span></b
                            >38<br />[SX](RUR'U)[SX]
                          </p>
                        </div>
                        <div id="F2L-19-DIV" class="unit" data-index="18">
                          <div class="orderNum">19</div>
                          <div id="F2L-19-IMG" class="exampleImg">
                            <img src="./images/img54.png" alt="" />
                          </div>
                          <p id="F2L-19-P" class="exampleText">
                            (R<span class="mainColor2">U&#8217;</span
                            >R&#8217;U2)(RUR&#8217;) <br /><br /><b
                              ><span class="scramble"> SC: </span></b
                            >19, 29
                          </p>
                        </div>
                        <div id="F2L-20-DIV" class="unit" data-index="19">
                          <div class="orderNum">20</div>
                          <div id="F2L-20-IMG" class="exampleImg">
                            <img src="./images/img60.png" alt="" />
                          </div>
                          <p id="F2L-20-P" class="exampleText">
                            U(R<span class="mainColor2">U&#8217;</span
                            ><span class="mainColor3">U&#8217;</span
                            >)(R&#8217;UR<span class="mainColor2">U&#8217;</span
                            >R&#8217;)<br /><br /><b
                              ><span class="scramble"> SC: </span></b
                            >30
                          </p>
                        </div>
                        <div id="F2L-21-DIV" class="unit" data-index="20">
                          <div class="orderNum">21</div>
                          <div id="F2L-21-IMG" class="exampleImg">
                            <img src="./images/img25.png" alt="" />
                          </div>
                          <p id="F2L-21-P" class="exampleText">
                            (R<span class="mainColor3">U&#8217;</span
                            ><span class="mainColor2">U&#8217;</span
                            >)(R&#8217;<span class="mainColor2">U&#8217;</span
                            >RUR&#8217;)<br /><br /><b
                              ><span class="scramble"> SC: </span></b
                            ><br />RU'R'U RU2R'
                          </p>
                        </div>
                        <div id="F2L-22-DIV" class="unit" data-index="21">
                          <div class="orderNum">22</div>
                          <div id="F2L-22-IMG" class="exampleImg">
                            <img src="./images/img31.png" alt="" />
                          </div>
                          <p id="F2L-22-P" class="exampleText">
                            <span class="mainColor2">U&#8217;</span>(R<span
                              class="mainColor2"
                              >U&#8217;</span
                            >R&#8217;U2)(R<span class="mainColor2"
                              >U&#8217;</span
                            >R&#8217;)<br /><br /><b
                              ><span class="scramble"> SC: </span></b
                            >32
                          </p>
                        </div>
                        <div id="F2L-23-DIV" class="unit" data-index="22">
                          <div class="orderNum">23</div>
                          <div id="F2L-23-IMG" class="exampleImg">
                            <img src="./images/img37.png" alt="" />
                          </div>
                          <p id="F2L-23-P" class="exampleText">
                            <span class="mainColor2">U&#8217;</span
                            >(RUR&#8217;)y'(UR&#8217;<span class="mainColor2"
                              >U&#8217;</span
                            >R)<br /><br /><b
                              ><span class="scramble"> SC: </span></b
                            ><br />F'U'FU RU'R'U2
                          </p>
                        </div>
                        <div id="F2L-24-DIV" class="unit" data-index="23">
                          <div class="orderNum">24</div>
                          <div id="F2L-24-IMG" class="exampleImg">
                            <img src="./images/img43.png" alt="" />
                          </div>
                          <p id="F2L-24-P" class="exampleText">
                            y&#8217;U(R&#8217;UR<span class="mainColor2"
                              >U&#8217;</span
                            >)(R&#8217;<span class="mainColor2">U&#8217;</span
                            >R)<br /><br /><b
                              ><span class="scramble"> SC: </span></b
                            ><br />y' R'U [SX] RU'
                          </p>
                        </div>
                        <div id="F2L-25-DIV" class="unit" data-index="24">
                          <div class="orderNum">25</div>
                          <div id="F2L-25-IMG" class="exampleImg">
                            <img src="./images/img49.png" alt="" />
                          </div>
                          <p id="F2L-25-P" class="exampleText">
                            y'(R&#8217;<span class="mainColor2">U&#8217;</span
                            >R)<br /><br /><b
                              ><span class="scramble"> SC: </span></b
                            >34
                          </p>
                        </div>
                        <div id="F2L-26-DIV" class="unit" data-index="25">
                          <div class="orderNum">26</div>
                          <div id="F2L-26-IMG" class="exampleImg">
                            <img src="./images/img55.png" alt="" />
                          </div>
                          <p id="F2L-26-P" class="exampleText">
                            y&#8217;U(R&#8217;<span class="mainColor2"
                              >U&#8217;</span
                            >R<span class="mainColor2">U&#8217;</span
                            >)(R&#8217;<span class="mainColor2">U&#8217;</span
                            >R)<br /><br /><b
                              ><span class="scramble"> SC: </span></b
                            >24
                          </p>
                        </div>
                        <div id="F2L-27-DIV" class="unit" data-index="26">
                          <div class="orderNum">27</div>
                          <div id="F2L-27-IMG" class="exampleImg">
                            <img src="./images/img61.png" alt="" />
                          </div>
                          <p id="F2L-27-P" class="exampleText">
                            y'(R<span class="mainColor3">U&#8217;</span
                            ><span class="mainColor2">U&#8217;</span
                            >)(R&#8217;2<span class="mainColor2">U&#8217;</span
                            >)(R2<span class="mainColor2">U&#8217;</span
                            >R&#8217;)<br /><br /><b
                              ><span class="scramble"> SC: </span></b
                            ><br />y' RUR2U<br />R2U2R'
                          </p>
                        </div>
                        <div id="F2L-28-DIV" class="unit" data-index="27">
                          <div class="orderNum">28</div>
                          <div id="F2L-28-IMG" class="exampleImg">
                            <img src="./images/img26.png" alt="" />
                          </div>
                          <p id="F2L-28-P" class="exampleText">
                            (lU)(r<span class="mainColor2">U&#8217;</span>r<span
                              class="mainColor2"
                              >&#8216;U&#8217;</span
                            >)l&#8217;<br /><br /><b
                              ><span class="scramble"> SC: </span></b
                            >37
                          </p>
                        </div>
                        <div id="F2L-29-DIV" class="unit" data-index="28">
                          <div class="orderNum">29</div>
                          <div id="F2L-29-IMG" class="exampleImg">
                            <img src="./images/img32.png" alt="" />
                          </div>
                          <p id="F2L-29-P" class="exampleText">
                            <span class="mainColor2">U&#8217;</span>(R<span
                              class="mainColor3"
                              >U&#8217;</span
                            ><span class="mainColor2">U&#8217;</span
                            >)(R&#8217;U2)(R<span class="mainColor2"
                              >U&#8217;</span
                            >R&#8217;)<br /><br /><b
                              ><span class="scramble"> SC: </span></b
                            ><br />RUR'U2<br />RU2R'U
                          </p>
                        </div>
                        <div id="F2L-30-DIV" class="unit" data-index="29">
                          <div class="orderNum">30</div>
                          <div id="F2L-30-IMG" class="exampleImg">
                            <img src="./images/img38.png" alt="" />
                          </div>
                          <p id="F2L-30-P" class="exampleText">
                            <span class="mainColor2">U&#8217;</span>[SX](R<span
                              class="mainColor3"
                              >U&#8217;</span
                            ><span class="mainColor2">U&#8217;</span
                            >R&#8217;)<br /><br /><b
                              ><span class="scramble"> SC: </span></b
                            >18, 20
                          </p>
                        </div>
                        <div id="F2L-31-DIV" class="unit" data-index="30">
                          <div class="orderNum">31</div>
                          <div id="F2L-31-IMG" class="exampleImg">
                            <img src="./images/img44.png" alt="" />
                          </div>
                          <p id="F2L-31-P" class="exampleText">
                            UR<span class="mainColor2">U&#8217;</span
                            >R&#8217;<br /><br /><b
                              ><span class="scramble"> SC: </span></b
                            >[SX]
                          </p>
                        </div>
                        <div id="F2L-32-DIV" class="unit" data-index="31">
                          <div class="orderNum">32</div>
                          <div id="F2L-32-IMG" class="exampleImg">
                            <img src="./images/img50.png" alt="" />
                          </div>
                          <p id="F2L-32-P" class="exampleText">
                            <span class="mainColor2">U&#8217;</span>(R<span
                              class="mainColor3"
                              >U&#8217;</span
                            ><span class="mainColor2">U&#8217;</span
                            >R&#8217;U)(RUR&#8217;)<br /><br /><b
                              ><span class="scramble"> SC: </span></b
                            >22
                          </p>
                        </div>
                        <div id="F2L-33-DIV" class="unit" data-index="32">
                          <div class="orderNum">33</div>
                          <div id="F2L-33-IMG" class="exampleImg">
                            <img src="./images/img56.png" alt="" />
                          </div>
                          <p id="F2L-33-P" class="exampleText">
                            y&#8217;U(R&#8217;<span class="mainColor2"
                              >U&#8217;</span
                            >R)<span class="mainColor">d&#8217;</span
                            >(RUR&#8217;)<br /><br /><b
                              ><span class="scramble"> SC: </span></b
                            >13
                          </p>
                        </div>
                        <div id="F2L-34-DIV" class="unit" data-index="33">
                          <div class="orderNum">34</div>
                          <div id="F2L-34-IMG" class="exampleImg">
                            <img src="./images/img62.png" alt="" />
                          </div>
                          <p id="F2L-34-P" class="exampleText">
                            y&#8217;<span class="mainColor2">U&#8217;</span
                            >(R&#8217;UR)<br /><br /><b
                              ><span class="scramble"> SC: </span></b
                            >F'U'FU
                          </p>
                        </div>
                        <div id="F2L-35-DIV" class="unit" data-index="34">
                          <div class="orderNum">35</div>
                          <div id="F2L-35-IMG" class="exampleImg">
                            <img src="./images/img27.png" alt="" />
                          </div>
                          <p id="F2L-35-P" class="exampleText">
                            y&#8217;U(R&#8217;<span class="mainColor2"
                              >U&#8217;</span
                            >R<span class="mainColor3">U&#8217;</span
                            ><span class="mainColor2">U&#8217;</span
                            >)(R&#8217;UR)<br /><br /><b
                              ><span class="scramble"> SC: </span></b
                            >15, 35
                          </p>
                        </div>
                        <div id="F2L-36-DIV" class="unit" data-index="35">
                          <div class="orderNum">36</div>
                          <div id="F2L-36-IMG" class="exampleImg">
                            <img src="./images/img33.png" alt="" />
                          </div>
                          <p id="F2L-36-P" class="exampleText">
                            y&#8217;U(R&#8217;U2R<span class="mainColor3"
                              >U&#8217;</span
                            ><span class="mainColor2">U&#8217;</span
                            >)(R&#8217;UR)<br /><br /><b
                              ><span class="scramble"> SC: </span></b
                            ><br />y' R'U'R U2<br />R' U2 RU'
                          </p>
                        </div>
                        <div id="F2L-37-DIV" class="unit" data-index="36">
                          <div class="orderNum">37</div>
                          <div id="F2L-37-IMG" class="exampleImg">
                            <img src="./images/img39.png" alt="" />
                          </div>
                          <p id="F2L-37-P" class="exampleText">
                            (R<span class="mainColor2">U&#8217;</span
                            >R&#8217;U2)y'(R&#8217;<span class="mainColor2"
                              >U&#8217;</span
                            >R)<br /><br /><b
                              ><span class="scramble"> SC: </span></b
                            ><br />F'UF<br />U2 RUR'
                          </p>
                        </div>
                        <div id="F2L-38-DIV" class="unit" data-index="37">
                          <div class="orderNum">38</div>
                          <div id="F2L-38-IMG" class="exampleImg">
                            <img src="./images/img45.png" alt="" />
                          </div>
                          <p id="F2L-38-P" class="exampleText">
                            (R<span class="mainColor2">U&#8217;</span
                            >R&#8217;U)(R<span class="mainColor2">U&#8217;</span
                            >R&#8217;U2)(R<span class="mainColor2"
                              >U&#8217;</span
                            >R&#8217;)<br /><br /><b
                              ><span class="scramble"> SC: </span></b
                            ><br />RUR' U2 [SX] RUR'
                          </p>
                        </div>
                        <div id="F2L-39-DIV" class="unit" data-index="38">
                          <div class="orderNum">39</div>
                          <div id="F2L-39-IMG" class="exampleImg">
                            <img src="./images/img51.png" alt="" />
                          </div>
                          <p id="F2L-39-P" class="exampleText">
                            <span class="mainColor2">U&#8217;</span
                            >(RUR&#8217;U)(RUR&#8217;) <br /><br /><b
                              ><span class="scramble"> SC: </span></b
                            >41+U2
                          </p>
                        </div>
                        <div id="F2L-40-DIV" class="unit" data-index="39">
                          <div class="orderNum">40</div>
                          <div id="F2L-40-IMG" class="exampleImg">
                            <img src="./images/img57.png" alt="" />
                          </div>
                          <p id="F2L-40-P" class="exampleText">
                            (RUR&#8217;)<br /><br /><b
                              ><span class="scramble"> SC: </span></b
                            >17, 31, 39 or RU&#8217;R&#8217;
                          </p>
                        </div>
                        <div id="F2L-41-DIV" class="unit" data-index="40">
                          <div class="orderNum">41</div>
                          <div id="F2L-41-IMG" class="exampleImg">
                            <img src="./images/img63.png" alt="" />
                          </div>
                          <p id="F2L-41-P" class="exampleText">
                            U'(R<span class="mainColor2">U&#8217;</span
                            >R&#8217;U)(RUR&#8217;)<br /><br /><b
                              ><span class="scramble"> SC: </span></b
                            ><br />RU'R'U'<br />RUR'U
                          </p>
                        </div>
                      </div>
                    </div>
                  </section>

                  <!-- OOL -->
                  <section class="section">
                    <div class="container">
                      <!-- <div style="margin-bottom: -5px;">
												<div id="btn-showAll-OLL" class="buy " @click="showOLLAll()"><a
														class="" rel="noopener noreferrer">Show All</a></div>
											</div> -->

                      <!-- <div style="margin-bottom: -5px;">
												<div id="btn-OLL-1piece" class="buy " @click="showOLL1piece()"><a
														class="" rel="noopener noreferrer">1 piece</a></div>
												<div id="btn-OLL-2piece" class="buy " @click="showOLL2piece()"><a
														class="" rel="noopener noreferrer">2 pieces</a></div>
												<div id="btn-OLL-3piece" class="buy " @click="showOLL3piece()"><a
														class="" rel="noopener noreferrer">3 pieces</a></div>
												<div id="btn-OLL-4piece" class="buy" @click="showOLL4piece()"><a
														class="" rel="noopener noreferrer">4 pieces</a></div>
												<div id="btn-OLL-5piece" class="buy" @click="showOLL5piece()"><a
														class="" rel="noopener noreferrer">5 pieces</a></div>
											</div>


											<div style="margin-bottom: 10px;">

												<div id="btn-OLL-3missing" class="buy" @click="showOLL3missing()"><a
														class="" rel="noopener noreferrer">3 missing</a></div>
												<div id="btn-OLL-2missing" class="buy" @click="showOLL2missing()"><a
														class="" rel="noopener noreferrer">2 missing</a></div>
											</div> -->

                      <!-- <div style="margin-bottom: -5px;">
												<div id="btn-OLL_Lshape" class="buy " @click="showOLL_Lshape()"><a
														class="" rel="noopener noreferrer">L shape</a></div>
												<div id="btn-OLL_Tshape" class="buy " @click="showOLL_Tshape()"><a
														class="" rel="noopener noreferrer">T shape</a></div>
												<div id="btn-OLL_Lineshape" class="buy " @click="showOLL_Lineshape()">
													<a class="" rel="noopener noreferrer">Line</a>
												</div>
												<div id="btn-OLL_Crossshape" class="buy "
													@click="showOLL_Crossshape()"><a class=""
														rel="noopener noreferrer">Cross</a></div>
											</div> -->

                      <div style="margin-bottom: 10px">
                        <span class="img_span"
                          ><img
                            id="img-showAll-OLL"
                            @click="showOLLAll()"
                            class="img_btn"
                            src="./img-all-40.png"
                        /></span>
                      </div>

                      <div style="margin-bottom: 10px">
                        <span class="img_span"
                          ><img
                            id="img-OLL_1piece"
                            @click="showOLL1piece()"
                            class="img_btn"
                            src="./img1-40.png"
                        /></span>
                        <span class="img_span"
                          ><img
                            id="img-OLL_2piece"
                            @click="showOLL2piece()"
                            class="img_btn"
                            src="./img2-40.png"
                        /></span>
                        <span class="img_span"
                          ><img
                            id="img-OLL_3piece"
                            @click="showOLL3piece()"
                            class="img_btn"
                            src="./img3-40.png"
                        /></span>
                        <span class="img_span"
                          ><img
                            id="img-OLL_4piece"
                            @click="showOLL4piece()"
                            class="img_btn"
                            src="./img4-40.png"
                        /></span>
                        <span class="img_span"
                          ><img
                            id="img-OLL_5piece"
                            @click="showOLL5piece()"
                            class="img_btn"
                            src="./img5-40.png"
                        /></span>
                        <span class="img_span"
                          ><img
                            id="img-OLL-3missing"
                            @click="showOLL3missing()"
                            class="img_btn"
                            src="./img-empty3-40.png"
                        /></span>
                        <span class="img_span"
                          ><img
                            id="img-OLL-2missing"
                            @click="showOLL2missing()"
                            class="img_btn"
                            src="./img-empty2-40.png"
                        /></span>
                      </div>

                      <div style="margin-bottom: 15px">
                        <span class="img_span"
                          ><img
                            id="img-OLL_Rocketshape"
                            @click="showOLL_Rocketshape()"
                            class="img_btn"
                            src="./img100-40.png"
                        /></span>
                        <span class="img_span"
                          ><img
                            id="img-OLL_Lshape1"
                            @click="showOLL_Lshape1()"
                            class="img_btn"
                            src="./img109-40.png"
                        /></span>
                        <span class="img_span"
                          ><img
                            id="img-OLL_Lshape2"
                            @click="showOLL_Lshape2()"
                            class="img_btn"
                            src="./img119-40.png"
                        /></span>
                        <span class="img_span"
                          ><img
                            id="img-OLL_Lineshape"
                            @click="showOLL_Lineshape()"
                            class="img_btn"
                            src="./img101-40.png"
                        /></span>
                        <span class="img_span"
                          ><img
                            id="img-OLL_Sshape"
                            @click="showOLL_Sshape()"
                            class="img_btn"
                            src="./img103-40.png"
                        /></span>
                      </div>

                      <div style="margin-bottom: 15px">
                        <span class="img_span"
                          ><img
                            id="img-OLL_buildingshape"
                            @click="showOLL_buildingshape()"
                            class="img_btn"
                            src="./img99-40.png"
                        /></span>
                        <span class="img_span"
                          ><img
                            id="img-OLL_Tshape"
                            @click="showOLL_Tshape()"
                            class="img_btn"
                            src="./img111-40.png"
                        /></span>
                        <span class="img_span"
                          ><img
                            id="img-OLL_Crossshape"
                            @click="showOLL_Crossshape()"
                            class="img_btn"
                            src="./img98-40.png"
                        /></span>
                        <span class="img_span"
                          ><img
                            id="img-OLL_Cshape"
                            @click="showOLL_Cshape()"
                            class="img_btn"
                            src="./img78-40.png"
                        /></span>
                        <span class="img_span"
                          ><img
                            id="img-learn-40"
                            @click="showOLL_toLearn()"
                            class="img_btn"
                            src="./img-learn-40.png"
                        /></span>
                      </div>

                      <div
                        class="exampleGrid exampleGrid4 flex"
                        id="orientationLayers"
                      >
                        <div class="firstUnit">
                          <div class="exampleText">
                            <h2 class="fsize24 mainColor">OLL</h2>
                            <p>Orientation of Last Layer</p>
                            <p>
                              <b
                                >[SX] = R U R&#8217;<span class="mainColor2">
                                  U&#8217;</span
                                ></b
                              >
                            </p>
                            <p>
                              <b
                                >[sh] = R&#8217; F R F&#8217;<span
                                  class="mainColor2"
                                >
                                </span
                              ></b>
                            </p>
                          </div>
                        </div>

                        <!-- qwer OLL -->

                        <div id="OLL-01-DIV" class="unit" data-index="0">
                          <div class="orderNum">01</div>
                          <div id="OLL-01-IMG" class="exampleImg">
                            <img src="./images/img96.png" alt="" />
                          </div>
                          <p id="OLL-01-P" class="exampleText">
                            (RU2R&#8217;)<br />[sh]<br />U2[sh]<br />
                            <span class="scramblok">
                              SC: (FR'F'R) U2 (FR'F') R2 U2 R'</span
                            ><br />
                          </p>
                        </div>
                        <div id="OLL-02-DIV" class="unit" data-index="1">
                          <div class="orderNum">02</div>
                          <div id="OLL-02-IMG" class="exampleImg">
                            <img src="./images/img102.png" alt="" />
                          </div>
                          <p id="OLL-02-P" class="exampleText">
                            F[SX]<span class="mainColor3">F&#8217;</span
                            ><br />f[SX]<span class="mainColor3">f&#8217;</span
                            ><br /><br /><span class="scramblok"
                              >SC: <br />02+U2</span
                            >
                          </p>
                        </div>

                        <div id="OLL-03-DIV" class="unit" data-index="2">
                          <div class="orderNum">03</div>
                          <div id="OLL-03-IMG" class="exampleImg">
                            <img src="./images/img108.png" alt="" />
                          </div>
                          <p id="OLL-03-P" class="exampleText">
                            f[SX]<span class="mainColor3">f&#8217;</span
                            ><span class="mainColor2"> U&#8217;</span
                            ><br />F[SX]<span class="mainColor3">F&#8217;</span
                            ><br /><span class="scramblok">
                              SC: <br />04+U2</span
                            >
                          </p>
                        </div>
                        <div id="OLL-04-DIV" class="unit" data-index="3">
                          <div class="orderNum">04</div>
                          <div id="OLL-04-IMG" class="exampleImg">
                            <img src="./images/img114.png" alt="" />
                          </div>
                          <p id="OLL-04-P" class="exampleText">
                            f[SX]<span class="mainColor3">f&#8217;</span>
                            U<br />F[SX]<span class="mainColor3">F&#8217;</span
                            ><br /><span class="scramblok">
                              SC: <br />03+U2</span
                            >
                          </p>
                        </div>
                        <div id="OLL-05-DIV" class="unit" data-index="4">
                          <div class="orderNum">05</div>
                          <div id="OLL-05-IMG" class="exampleImg">
                            <img src="./images/img68.png" alt="" />
                          </div>
                          <p id="OLL-05-P" class="exampleText">
                            (r&#8217;U2)(RUR&#8217;U)r<br /><br /><span
                              class="scramblok"
                            >
                              SC: 08, 12+U</span
                            >
                          </p>
                        </div>
                        <div id="OLL-06-DIV" class="unit" data-index="5">
                          <div class="orderNum">06</div>
                          <div id="OLL-06-IMG" class="exampleImg">
                            <img src="./images/img74.png" alt="" />
                          </div>
                          <p id="OLL-06-P" class="exampleText">
                            (r<span class="mainColor3">U&#8217;</span
                            ><span class="mainColor2">U&#8217;</span
                            >)(R&#8217;<span class="mainColor2">U&#8217;</span
                            >R<span class="mainColor2">U&#8217;</span
                            >r&#8217;)<br /><span class="scramblok">
                              SC: 07, 11+U', 13+U</span
                            >
                          </p>
                        </div>
                        <div id="OLL-07-DIV" class="unit" data-index="6">
                          <div class="orderNum">07</div>
                          <div id="OLL-07-IMG" class="exampleImg">
                            <img src="./images/img80.png" alt="" />
                          </div>
                          <p id="OLL-07-P" class="exampleText">
                            (rUR&#8217;U)(R<span class="mainColor3"
                              >U&#8217;</span
                            ><span class="mainColor2">U&#8217;</span
                            >r&#8217;)<br /><span class="scramble_orange">
                              SC: 06, 16</span
                            >
                          </p>
                        </div>
                        <div id="OLL-08-DIV" class="unit" data-index="7">
                          <div class="orderNum">08</div>
                          <div id="OLL-08-IMG" class="exampleImg">
                            <img src="./images/img86.png" alt="" />
                          </div>
                          <p id="OLL-08-P" class="exampleText">
                            (r&#8217;<span class="mainColor2">U&#8217;</span
                            >R<span class="mainColor2">U&#8217;</span
                            >)(R&#8217;U2r)<br /><span class="scramble_orange">
                              SC: 05, 10, 15</span
                            >
                          </p>
                        </div>
                        <div id="OLL-09-DIV" class="unit" data-index="8">
                          <div class="orderNum">09</div>
                          <div id="OLL-09-IMG" class="exampleImg">
                            <img src="./images/img91.png" alt="" />
                          </div>
                          <p id="OLL-09-P" class="exampleText">
                            [SX](R&#8217;FR)[SX]<span class="mainColor3"
                              >F&#8217;</span
                            ><br /><span class="scramble_orange">
                              SC: F (URU'R')(R'F'R)(URU'R')</span
                            >
                          </p>
                        </div>
                        <div id="OLL-10-DIV" class="unit" data-index="9">
                          <div class="orderNum">10</div>
                          <div id="OLL-10-IMG" class="exampleImg">
                            <img src="./images/img64.png" alt="" />
                          </div>
                          <p id="OLL-10-P" class="exampleText">
                            (RUR&#8217;<span class="mainColor">U</span>)
                            <br />[sh]<br />(R<span class="mainColor2"
                              >U&#8217;</span
                            ><span class="mainColor2">U&#8217;</span
                            >R&#8217;)<br />
                            <span class="scramble_orange">
                              SC: 14+U&#8217;</span
                            >
                          </p>
                        </div>
                        <div id="OLL-11-DIV" class="unit" data-index="10">
                          <div class="orderNum">11</div>
                          <div id="OLL-11-IMG" class="exampleImg">
                            <img src="./images/img97.png" alt="" />
                          </div>
                          <p id="OLL-11-P" class="exampleText">
                            (r&#8217;R2UR&#8217;U)(R<span class="mainColor3"
                              >U&#8217;</span
                            ><span class="mainColor2">U&#8217;</span
                            >R&#8217;U)<span class="mainColor">M&#8217;</span
                            ><br /><span class="scramble"> SC:</span><br />M
                            U'RU2<br />(R'U'RU')<br />R2r
                          </p>
                        </div>
                        <div id="OLL-12-DIV" class="unit" data-index="11">
                          <div class="orderNum">12</div>
                          <div id="OLL-12-IMG" class="exampleImg">
                            <img src="./images/img103.png" alt="" />
                          </div>
                          <p id="OLL-12-P" class="exampleText">
                            <span class="mainColor">M&#8217;</span
                            >(R&#8217;<span class="mainColor2">U&#8217;</span
                            >R<span class="mainColor2">U&#8217;</span
                            >)(R&#8217;U2R<span class="mainColor2"
                              >U&#8217;</span
                            >)M<br /><span class="scramble"> SC: </span
                            >M'(UR'U2R)(UR'UR)M
                          </p>
                        </div>
                        <div id="OLL-13-DIV" class="unit" data-index="12">
                          <div class="orderNum">13</div>
                          <div id="OLL-13-IMG" class="exampleImg">
                            <img src="./images/img109.png" alt="" />
                          </div>
                          <p id="OLL-13-P" class="exampleText">
                            f(RU)(R2<span class="mainColor2">U&#8217;</span
                            >)(R&#8217;UR<span class="mainColor2">U&#8217;</span
                            >)<span class="mainColor">f&#8217;</span
                            ><br /><br /><span class="scramble"> SC: </span
                            >09+U2
                          </p>
                        </div>
                        <div id="OLL-14-DIV" class="unit" data-index="13">
                          <div class="orderNum">14</div>
                          <div id="OLL-14-IMG" class="exampleImg">
                            <img src="./images/img115.png" alt="" />
                          </div>
                          <p id="OLL-14-P" class="exampleText">
                            (R&#8217;F)(RUR&#8217;<span class="mainColor3"
                              >F&#8217;</span
                            >R)(F<span class="mainColor2">U&#8217;</span
                            ><span class="mainColor3">F&#8217;</span
                            >)<br /><span class="scramble"> SC: </span
                            >(FUF')(R'FRU'R')(F'R)
                          </p>
                        </div>
                        <div id="OLL-15-DIV" class="unit" data-index="14">
                          <div class="orderNum">15</div>
                          <div id="OLL-15-IMG" class="exampleImg">
                            <img src="./images/img69.png" alt="" />
                          </div>
                          <p id="OLL-15-P" class="exampleText">
                            (r&#8217;<span class="mainColor2">U&#8217;</span
                            >r)(R&#8217;<span class="mainColor2">U&#8217;</span
                            >RU)(r&#8217;Ur)<br /><span class="scramble_orange">
                              SC: (r'U'r)(U'R'UR)(r'Ur)</span
                            >
                          </p>
                        </div>
                        <div id="OLL-16-DIV" class="unit" data-index="15">
                          <div class="orderNum">16</div>
                          <div id="OLL-16-IMG" class="exampleImg">
                            <img src="./images/img75.png" alt="" />
                          </div>
                          <p id="OLL-16-P" class="exampleText">
                            (rUr&#8217;)<br />[SX]<br />(r<span
                              class="mainColor2"
                              >U&#8217;</span
                            ><span class="mainColor">r&#8217;</span>)<br /><span
                              class="scramble_orange"
                            >
                              SC:<br />(rUr')(URU'R')(rU'r')</span
                            >
                          </p>
                        </div>
                        <div id="OLL-17-DIV" class="unit" data-index="16">
                          <div class="orderNum">17</div>
                          <div id="OLL-17-IMG" class="exampleImg">
                            <img src="./images/img81.png" alt="" />
                          </div>
                          <p id="OLL-17-P" class="exampleText">
                            (l<span class="mainColor2">U&#8217;</span
                            >l&#8217;)f(R2BR&#8217;UR&#8217;<span
                              class="mainColor2"
                              >U&#8217;</span
                            ><span class="mainColor3">f&#8217;</span
                            >)<br /><span class="scramble_learn"> SC: 19</span>
                          </p>
                        </div>
                        <div id="OLL-18-DIV" class="unit" data-index="17">
                          <div class="orderNum">18</div>
                          <div id="OLL-18-IMG" class="exampleImg">
                            <img src="./images/img87.png" alt="" />
                          </div>
                          <p id="OLL-18-P" class="exampleText">
                            (rUR&#8217;U)(R<span class="mainColor3"
                              >U&#8217;</span
                            ><span class="mainColor2">U&#8217;</span
                            >r&#8217;)(r&#8217;<span class="mainColor2"
                              >U&#8217;</span
                            >R<span class="mainColor2">U&#8217;</span
                            >)(R&#8217;U2r)<br /><span style="color: #00a650">
                              OR</span
                            ><br />U f[SX]2 f&#8217; U2 f [SX] f&#8217;<br /><br /><span
                              class="scramble_learn"
                            >
                              SC: F [SX] F' U2 F [SX]2 F' U or 17</span
                            >
                          </p>
                        </div>
                        <div id="OLL-19-DIV" class="unit" data-index="18">
                          <div class="orderNum">19</div>
                          <div id="OLL-19-IMG" class="exampleImg">
                            <img src="./images/img92.png" alt="" />
                          </div>
                          <p id="OLL-19-P" class="exampleText">
                            MU [SX] <br />M&#8217; [sh]<br /><span
                              class="scramble_orange"
                            >
                              SC: 18</span
                            >
                          </p>
                        </div>
                        <div id="OLL-20-DIV" class="unit" data-index="19">
                          <div class="orderNum">20</div>
                          <div id="OLL-20-IMG" class="exampleImg">
                            <img src="./images/img65.png" alt="" />
                          </div>
                          <p id="OLL-20-P" class="exampleText">
                            (rUR&#8217;<span class="mainColor2">U&#8217;</span
                            >)<span class="mainColor">M&#8217;2</span>U(R<span
                              class="mainColor2"
                              >U&#8217;</span
                            >R&#8217;<span class="mainColor2">U&#8217;</span
                            >)<span class="mainColor">M&#8217;</span><br /><span
                              class="scramblok"
                            >
                              SC: 20</span
                            >
                          </p>
                        </div>
                        <div id="OLL-21-DIV" class="unit" data-index="20">
                          <div class="orderNum">21</div>
                          <div id="OLL-21-IMG" class="exampleImg">
                            <img src="./images/img98.png" alt="" />
                          </div>
                          <p id="OLL-21-P" class="exampleText">
                            (RU2R&#8217;<span class="mainColor2">U&#8217;</span
                            >)[SX]<br />(R<span class="mainColor2"
                              >U&#8217;</span
                            >R&#8217;)<br />
                            <span style="color: #00a650"> OR</span>
                            F[SX]3F&#8217;<br /><span class="scramblok"
                              >SC: 21</span
                            >
                          </p>
                        </div>
                        <div id="OLL-22-DIV" class="unit" data-index="21">
                          <div class="orderNum">22</div>
                          <div id="OLL-22-IMG" class="exampleImg">
                            <img src="./images/img104.png" alt="" />
                          </div>
                          <p id="OLL-22-P" class="exampleText">
                            (R<span class="mainColor3">U&#8217;</span
                            ><span class="mainColor2">U&#8217;</span
                            >)(R&#8217;2<span class="mainColor2">U&#8217;</span
                            >)(R2<span class="mainColor2">U&#8217;</span
                            >)(R&#8217;2<span class="mainColor2">U&#8217;</span
                            >)(<span class="mainColor3">U&#8217;</span
                            >R)<br /><span class="scramblok">SC: 22</span>
                          </p>
                        </div>
                        <div id="OLL-23-DIV" class="unit" data-index="22">
                          <div class="orderNum">23</div>
                          <div id="OLL-23-IMG" class="exampleImg">
                            <img src="./images/img110.png" alt="" />
                          </div>
                          <p id="OLL-23-P" class="exampleText">
                            (R2<span class="mainColor">D&#8217;</span>)(R<span
                              class="mainColor3"
                              >U&#8217;</span
                            ><span class="mainColor2">U&#8217;</span
                            >R&#8217;<span class="mainColor">D</span>)(R<span
                              class="mainColor3"
                              >U&#8217;</span
                            ><span class="mainColor2">U&#8217;</span
                            >R)<br /><span class="scramblok"
                              >SC:<br />R'U2R'D'<br />R U2 R'<br />D R2</span
                            >
                          </p>
                        </div>
                        <div id="OLL-24-DIV" class="unit" data-index="23">
                          <div class="orderNum">24</div>
                          <div id="OLL-24-IMG" class="exampleImg">
                            <img src="./images/img116.png" alt="" />
                          </div>
                          <p id="OLL-24-P" class="exampleText">
                            (rUR&#8217;<span class="mainColor2">U&#8217;</span
                            >)(r&#8217;FR<span class="mainColor3">F&#8217;</span
                            >) <br /><span class="scramblok"> SC: 25+U2 </span>
                          </p>
                        </div>
                        <div id="OLL-25-DIV" class="unit" data-index="24">
                          <div class="orderNum">25</div>
                          <div id="OLL-25-IMG" class="exampleImg">
                            <img src="./images/img70.png" alt="" />
                          </div>
                          <p id="OLL-25-P" class="exampleText">
                            <span class="mainColor3">F&#8217;</span
                            >(rUR&#8217;<span class="mainColor2">U&#8217;</span
                            >)(r&#8217;FR) <br /><span class="scramblok">
                              SC: 23+U2
                            </span>
                          </p>
                        </div>
                        <div id="OLL-26-DIV" class="unit" data-index="25">
                          <div class="orderNum">26</div>
                          <div id="OLL-26-IMG" class="exampleImg">
                            <img src="./images/img76.png" alt="" />
                          </div>
                          <p id="OLL-26-P" class="exampleText">
                            (R<span class="mainColor3">U&#8217;</span
                            ><span class="mainColor2">U&#8217;</span
                            >)(R&#8217;<span class="mainColor2">U&#8217;</span
                            >R<span class="mainColor2">U&#8217;</span
                            >R&#8217;)<br /><span class="scramblok"
                              >SC: (RUR'U)(RU2R')</span
                            >
                          </p>
                        </div>
                        <div id="OLL-27-DIV" class="unit" data-index="26">
                          <div class="orderNum">27</div>
                          <div id="OLL-27-IMG" class="exampleImg">
                            <img src="./images/img82.png" alt="" />
                          </div>
                          <p id="OLL-27-P" class="exampleText">
                            (RUR&#8217;<span class="mainColor">U</span>)(R<span
                              class="mainColor3"
                              >U&#8217;</span
                            ><span class="mainColor2">U&#8217;</span
                            >R&#8217;)<br /><span class="scramblok"
                              >SC: (RU2R')(U'RU'R')</span
                            >
                          </p>
                        </div>
                        <div id="OLL-28-DIV" class="unit" data-index="27">
                          <div class="orderNum">28</div>
                          <div id="OLL-28-IMG" class="exampleImg">
                            <img src="./images/img88.png" alt="" />
                          </div>
                          <p id="OLL-28-P" class="exampleText">
                            (rUR&#8217;<span class="mainColor2">U&#8217;</span
                            >)(MU)<br />(R<span class="mainColor2"
                              >U&#8217;</span
                            >R&#8217;)<br /><span class="scramblok">
                              SC: 57</span
                            >
                          </p>
                        </div>
                        <div id="OLL-29-DIV" class="unit" data-index="28">
                          <div class="orderNum">29</div>
                          <div id="OLL-29-IMG" class="exampleImg">
                            <img src="./images/img93.png" alt="" />
                          </div>
                          <p id="OLL-29-P" class="exampleText">
                            [SX](R<span class="mainColor2">U&#8217;</span
                            >R&#8217;<span class="mainColor3">F&#8217;</span
                            ><span class="mainColor2">U&#8217;</span
                            >F)(RUR&#8217;)<br /><span class="scramble">
                              SC: </span
                            ><br />(RU'R')(F'UFRUR')(URU'R')
                          </p>
                        </div>
                        <div id="OLL-30-DIV" class="unit" data-index="29">
                          <div class="orderNum">30</div>
                          <div id="OLL-30-IMG" class="exampleImg">
                            <img src="./images/img66.png" alt="" />
                          </div>
                          <p id="OLL-30-P" class="exampleText">
                            f(RU)(R2<span class="mainColor2">U&#8217;</span
                            >R&#8217;U)(R2<span class="mainColor2"
                              >U&#8217;</span
                            >R&#8217;)<span class="mainColor3">f&#8217;</span
                            ><br /><span class="scramble"> SC:</span
                            ><br />f(RUR2)(U'RUR2)(U'R')f'
                          </p>
                        </div>
                        <div id="OLL-31-DIV" class="unit" data-index="30">
                          <div class="orderNum">31</div>
                          <div id="OLL-31-IMG" class="exampleImg">
                            <img src="./images/img99.png" alt="" />
                          </div>
                          <p id="OLL-31-P" class="exampleText">
                            (r&#8217;<span class="mainColor3">F&#8217;UF</span
                            >)(r<span class="mainColor2">U&#8217;</span
                            >r&#8217;<span class="mainColor2">U&#8217;</span
                            >r)<br /><span class="scramble"> SC: </span>38+U' or
                            40+U2
                          </p>
                        </div>
                        <div id="OLL-32-DIV" class="unit" data-index="31">
                          <div class="orderNum">32</div>
                          <div id="OLL-32-IMG" class="exampleImg">
                            <img src="./images/img105.png" alt="" />
                          </div>
                          <p id="OLL-32-P" class="exampleText">
                            (RU)(<span class="mainColor3">B&#8217;</span
                            ><span class="mainColor2">U&#8217;</span
                            >)(R&#8217;URBR&#8217;)<br /><span class="scramble">
                              SC: </span
                            >36 or 39
                          </p>
                        </div>
                        <div id="OLL-33-DIV" class="unit" data-index="32">
                          <div class="orderNum">33</div>
                          <div id="OLL-33-IMG" class="exampleImg">
                            <img src="./images/img111.png" alt="" />
                          </div>
                          <p id="OLL-33-P" class="exampleText">
                            [SX]<br />[sh] <br /><span class="scramblok">
                              SC: 37</span
                            >
                          </p>
                        </div>
                        <div id="OLL-34-DIV" class="unit" data-index="33">
                          <div class="orderNum">34</div>
                          <div id="OLL-34-IMG" class="exampleImg">
                            <img src="./images/img117.png" alt="" />
                          </div>
                          <p id="OLL-34-P" class="exampleText">
                            (RUR2<span class="mainColor2">U&#8217;</span
                            >)(R&#8217;F)(RUR<span class="mainColor2"
                              >U&#8217;</span
                            ><span class="mainColor">F&#8217;</span>)<br /><span
                              class="scramble_orange"
                              >SC: <br />FUR'U'R'<br />F'R<br />UR2U'R'</span
                            >
                          </p>
                        </div>
                        <div id="OLL-35-DIV" class="unit" data-index="34">
                          <div class="orderNum">35</div>
                          <div id="OLL-35-IMG" class="exampleImg">
                            <img src="./images/img71.png" alt="" />
                          </div>
                          <p id="OLL-35-P" class="exampleText">
                            RU2R&#8217; <br />[sh]<br />RU2R&#8217; <br /><span
                              class="scramble_orange"
                            >
                              SC: RU2R&#8217;<br />FR&#8217;F&#8217;R<br />RU2R&#8217;</span
                            >
                          </p>
                        </div>
                        <div id="OLL-36-DIV" class="unit" data-index="35">
                          <div class="orderNum">36</div>
                          <div id="OLL-36-IMG" class="exampleImg">
                            <img src="./images/img77.png" alt="" />
                          </div>
                          <p id="OLL-36-P" class="exampleText">
                            (R&#8217;<span class="mainColor2">U&#8217;</span
                            >R<span class="mainColor2">U&#8217;</span
                            >)(R&#8217;URU)(l<span class="mainColor2"
                              >U&#8217;</span
                            >R&#8217;U)<br /><span class="scramble"> SC: </span
                            >(B'RBR')(U'R'U'R)(UR'UR)
                          </p>
                        </div>
                        <div id="OLL-37-DIV" class="unit" data-index="36">
                          <div class="orderNum">37</div>
                          <div id="OLL-37-IMG" class="exampleImg">
                            <img src="./images/img83.png" alt="" />
                          </div>
                          <p id="OLL-37-P" class="exampleText">
                            F(R<span class="mainColor2">U&#8217;</span
                            >R&#8217;<span class="mainColor2">U&#8217;</span
                            >)(RUR&#8217;<span class="mainColor3">F&#8217;</span
                            >) <br /><span class="scramblok">
                              SC: 33 or 34 or 35+U</span
                            >
                          </p>
                        </div>
                        <div id="OLL-38-DIV" class="unit" data-index="37">
                          <div class="orderNum">38</div>
                          <div id="OLL-38-IMG" class="exampleImg">
                            <img src="./images/img89.png" alt="" />
                          </div>
                          <p id="OLL-38-P" class="exampleText">
                            (RUR&#8217;U)(R<span class="mainColor2"
                              >U&#8217;</span
                            >R&#8217;<span class="mainColor2">U&#8217;</span
                            >)<br />[sh] <br /><span class="scramble_orange">
                              SC: FR’F’R U [SX] RU’R’</span
                            >
                          </p>
                        </div>
                        <div id="OLL-39-DIV" class="unit" data-index="38">
                          <div class="orderNum">39</div>
                          <div id="OLL-39-IMG" class="exampleImg">
                            <img src="./images/img94.png" alt="" />
                          </div>
                          <p id="OLL-39-P" class="exampleText">
                            (RUR&#8217;<span class="mainColor3">F&#8217;</span
                            ><span class="mainColor2">U&#8217;</span
                            >F)U(RU2R&#8217;)<br /><span class="scramble">
                              SC: </span
                            >32
                          </p>
                        </div>
                        <div id="OLL-40-DIV" class="unit" data-index="39">
                          <div class="orderNum">40</div>
                          <div id="OLL-40-IMG" class="exampleImg">
                            <img src="./images/img94_2.png" alt="" />
                          </div>
                          <p id="OLL-40-P" class="exampleText">
                            R&#8217;<br />F[SX]<span class="mainColor3"
                              >F&#8217;</span
                            ><br />(UR)<br /><span class="scramble"> SC: </span
                            ><br />31+U2
                          </p>
                        </div>
                        <div id="OLL-41-DIV" class="unit" data-index="40">
                          <div class="orderNum">41</div>
                          <div id="OLL-41-IMG" class="exampleImg">
                            <img src="./images/img100.png" alt="" />
                          </div>
                          <p id="OLL-41-P" class="exampleText">
                            (RUR&#8217;<span class="mainColor">U</span>)(R<span
                              class="mainColor3"
                              >U&#8217;</span
                            ><span class="mainColor2">U&#8217;</span
                            >R&#8217;)<br />F[SX]<span class="mainColor3"
                              >F&#8217;</span
                            ><br /><span class="scramble_orange"> SC: 42</span>
                          </p>
                        </div>
                        <div id="OLL-42-DIV" class="unit" data-index="41">
                          <div class="orderNum">42</div>
                          <div id="OLL-42-IMG" class="exampleImg">
                            <img src="./images/img106.png" alt="" />
                          </div>
                          <p id="OLL-42-P" class="exampleText">
                            (R&#8217;<span class="mainColor2">U&#8217;</span
                            >R<span class="mainColor2">U&#8217;</span
                            >)(R&#8217;U2R)<br />F[SX]<span class="mainColor3"
                              >F&#8217;</span
                            ><br /><span class="scramble_orange">
                              SC: F(URU'R')F'(R'U2R)(UR'UR)</span
                            >
                          </p>
                        </div>
                        <div id="OLL-43-DIV" class="unit" data-index="42">
                          <div class="orderNum">43</div>
                          <div id="OLL-43-IMG" class="exampleImg">
                            <img
                              class="rotate90"
                              src="./images/img112.png"
                              alt=""
                            />
                          </div>
                          <p id="OLL-43-P" class="exampleText">
                            R'U'F'<br />UFR <br /><span class="scramblok">
                              SC: 41 or 46+U'</span
                            >
                          </p>
                        </div>
                        <div id="OLL-44-DIV" class="unit" data-index="43">
                          <div class="orderNum">44</div>
                          <div id="OLL-44-IMG" class="exampleImg">
                            <img src="./images/img118.png" alt="" />
                          </div>
                          <p id="OLL-44-P" class="exampleText">
                            f[SX]<span class="mainColor3">f&#8217;</span>

                            <br /><br /><span class="scramblok">
                              SC: 45+U2 or 29+U2 or 30+U'</span
                            >
                          </p>
                        </div>
                        <div id="OLL-45-DIV" class="unit" data-index="44">
                          <div class="orderNum">45</div>
                          <div id="OLL-45-IMG" class="exampleImg">
                            <img src="./images/img72.png" alt="" />
                          </div>
                          <p id="OLL-45-P" class="exampleText">
                            F[SX]<span class="mainColor3">F&#8217;</span>
                            <br /><br /><span class="scramblok">
                              SC: 44+U2 or 43</span
                            >
                          </p>
                        </div>
                        <div id="OLL-46-DIV" class="unit" data-index="45">
                          <div class="orderNum">46</div>
                          <div id="OLL-46-IMG" class="exampleImg">
                            <img src="./images/img78.png" alt="" />
                          </div>
                          <p id="OLL-46-P" class="exampleText">
                            (R&#8217;<span class="mainColor2">U&#8217;</span
                            >)[sh]<br />(U R) <br /><span class="scramblok">
                              SC: R'U' <br />FR'F'R<br />UR</span
                            >
                          </p>
                        </div>
                        <div id="OLL-47-DIV" class="unit" data-index="46">
                          <div class="orderNum">47</div>
                          <div id="OLL-47-IMG" class="exampleImg">
                            <img src="./images/img84.png" alt="" />
                          </div>
                          <p id="OLL-47-P" class="exampleText">
                            <span class="mainColor3">b&#8217;</span>(<span
                              class="mainColor2"
                              >U&#8217;</span
                            >R&#8217;UR)2b <br /><span class="scramble">
                              SC: </span
                            >b'<br />(R'U'RU)2<br />b
                          </p>
                        </div>
                        <div id="OLL-48-DIV" class="unit" data-index="47">
                          <div class="orderNum">48</div>
                          <div id="OLL-48-IMG" class="exampleImg">
                            <img src="./images/img90.png" alt="" />
                          </div>
                          <p id="OLL-48-P" class="exampleText">
                            F[SX]2<span class="mainColor3">F&#8217;</span>

                            <br /><span class="scramble_orange">
                              SC: 51+U2</span
                            >
                          </p>
                        </div>
                        <div id="OLL-49-DIV" class="unit" data-index="48">
                          <div class="orderNum">49</div>
                          <div id="OLL-49-IMG" class="exampleImg">
                            <img src="./images/img95.png" alt="" />
                          </div>
                          <p id="OLL-49-P" class="exampleText">
                            (R<span class="mainColor3">B&#8217;</span
                            >)(R&#8217;2FR2B)(R&#8217;2<span class="mainColor3"
                              >F&#8217;</span
                            >R)<br /><span class="scramble"> SC: </span>50+U2
                          </p>
                        </div>
                        <div id="OLL-50-DIV" class="unit" data-index="49">
                          <div class="orderNum">50</div>
                          <div id="OLL-50-IMG" class="exampleImg">
                            <img src="./images/img95_2.png" alt="" />
                          </div>
                          <p id="OLL-50-P" class="exampleText">
                            (r&#8217;U)(r2<span class="mainColor2"
                              >U&#8217;</span
                            >r&#8217;2<span class="mainColor2">U&#8217;</span
                            >)(r2Ur&#8217;)<br /><span class="scramble">
                              SC: </span
                            >49+U2
                          </p>
                        </div>
                        <div id="OLL-51-DIV" class="unit" data-index="50">
                          <div class="orderNum">51</div>
                          <div id="OLL-51-IMG" class="exampleImg">
                            <img src="./images/img101.png" alt="" />
                          </div>
                          <p id="OLL-51-P" class="exampleText">
                            f[SX]2<span class="mainColor3">f&#8217;</span>
                            <br /><br /><span class="scramblok">
                              SC: 47 or 48+U2</span
                            >
                          </p>
                        </div>
                        <div id="OLL-52-DIV" class="unit" data-index="51">
                          <div class="orderNum">52</div>
                          <div id="OLL-52-IMG" class="exampleImg">
                            <img src="./images/img107.png" alt="" />
                          </div>
                          <p id="OLL-52-P" class="exampleText">
                            (R&#8217;<span class="mainColor3">F&#8217;</span
                            ><span class="mainColor2">U&#8217;</span>F<span
                              class="mainColor2"
                              >U&#8217;</span
                            >)(RUR&#8217;<span class="mainColor">U</span
                            >R)<br /><span class="scramble"> SC: </span>52+U2
                          </p>
                        </div>
                        <div id="OLL-53-DIV" class="unit" data-index="52">
                          <div class="orderNum">53</div>
                          <div id="OLL-53-IMG" class="exampleImg">
                            <img src="./images/img113.png" alt="" />
                          </div>
                          <p id="OLL-53-P" class="exampleText">
                            (r&#8217;U2)<br />[SX]<br />(RUR&#8217;Ur)
                            <br /><span class="scramble_learn"> SC: 53+U</span>
                          </p>
                        </div>
                        <div id="OLL-54-DIV" class="unit" data-index="53">
                          <div class="orderNum">54</div>
                          <div id="OLL-54-IMG" class="exampleImg">
                            <img src="./images/img119.png" alt="" />
                          </div>
                          <p id="OLL-54-P" class="exampleText">
                            (rUr&#8217;)<br />[SX]2<br />(r<span
                              class="mainColor2"
                              >U&#8217;</span
                            >r&#8217;)<br /><span class="scramble_orange">
                              SC: 56</span
                            >
                          </p>
                        </div>
                        <div id="OLL-55-DIV" class="unit" data-index="54">
                          <div class="orderNum">55</div>
                          <div id="OLL-55-IMG" class="exampleImg">
                            <img src="./images/img73.png" alt="" />
                          </div>
                          <p id="OLL-55-P" class="exampleText">
                            (r<span class="mainColor3">U&#8217;</span
                            ><span class="mainColor2">U&#8217;</span
                            >R&#8217;<span class="mainColor2">U&#8217;</span>)M
                            [SX]<br />(r<span class="mainColor2">U&#8217;</span
                            >r&#8217;)<br /><span class="scramble_orange">
                              SC: (rUr'U)(RU'R')(M'URU2r')</span
                            >
                          </p>
                        </div>
                        <div id="OLL-56-DIV" class="unit" data-index="55">
                          <div class="orderNum">56</div>
                          <div id="OLL-56-IMG" class="exampleImg">
                            <img src="./images/img79.png" alt="" />
                          </div>
                          <p id="OLL-56-P" class="exampleText">
                            (rUr&#8217;)(UR<span class="mainColor2"
                              >U&#8217;</span
                            >R&#8217;)2(r<span class="mainColor2">U&#8217;</span
                            >r&#8217;)<br /><span class="scramble_orange">
                              SC: 54</span
                            >
                          </p>
                        </div>
                        <div id="OLL-57-DIV" class="unit" data-index="56">
                          <div class="orderNum">57</div>
                          <div id="OLL-57-IMG" class="exampleImg">
                            <img src="./images/img85.png" alt="" />
                          </div>
                          <p id="OLL-57-P" class="exampleText">
                            [SX]<span class="mainColor">M&#8217;</span
                            ><br />(UR<span class="mainColor2">U&#8217;</span
                            >r&#8217;)<span style="color: #00a650"> OR</span> U
                            f[SX]f&#8217; U2 f[SX]f&#8217; <br /><span
                              class="scramblok"
                            >
                              SC: F[SX]F&#8217; U2 F[SX]F&#8217; U or 28</span
                            >
                          </p>
                        </div>
                      </div>
                    </div>
                    <!-- qwer PLL -->
                  </section>

                  <!-- PPL -->
                  <section class="section">
                    <div class="container1">
                      <div
                        class="exampleGrid exampleGrid4 flex"
                        id="permutationLayers"
                      >
                        <div class="firstUnit">
                          <div class="exampleText">
                            <h2 class="fsize24 mainColor">PLL</h2>
                            <p>Permutation of Last Layer</p>
                            <p>
                              <b
                                >[SX] = R U R&#8217;<span class="mainColor2">
                                  U&#8217;</span
                                ></b
                              >
                            </p>
                          </div>
                        </div>
                        <div class="unit" data-index="0">
                          <div class="orderNum">01</div>
                          <div class="exampleImg">
                            <img src="./images/img125.png" alt="" /><b>Ua</b>
                          </div>
                          <p class="exampleText">
                            <span class="mainColor">M&#8217;2</span>U<span
                              class="mainColor"
                              >M</span
                            >U2<span class="mainColor">M&#8217;</span>U<span
                              class="mainColor"
                              >M&#8217;2</span
                            ><br /><span style="color: #00a650"> OR</span
                            ><br />(R<span class="mainColor2">U&#8217;</span
                            >R)(UR)2(<span class="mainColor2">U&#8217;</span
                            >R&#8217;<span class="mainColor2">U&#8217;</span
                            >R&#8217;2)
                          </p>
                        </div>
                        <div class="unit" data-index="1">
                          <div class="orderNum">02</div>
                          <div class="exampleImg">
                            <img src="./images/img131.png" alt="" /><b>Ub</b>
                          </div>
                          <p class="exampleText">
                            <span class="mainColor">M&#8217;2</span>
                            <span class="mainColor2">U&#8217;</span
                            ><span class="mainColor">M</span> U2<span
                              class="mainColor"
                              >M&#8217;</span
                            ><span class="mainColor2">U&#8217;</span
                            ><span class="mainColor">M&#8217;2</span><br /><span
                              style="color: #00a650"
                            >
                              OR</span
                            ><br />(R2 U)(RU)(R&#8217;<span class="mainColor2"
                              >U&#8217;</span
                            >)2(R&#8217;UR&#8217;)
                          </p>
                        </div>
                        <div class="unit" data-index="2">
                          <div class="orderNum">03</div>
                          <div class="exampleImg">
                            <img src="./images/img136.png" alt="" /><b>H</b>
                          </div>
                          <p class="exampleText">
                            <span class="mainColor">M&#8217;2</span>U<span
                              class="mainColor"
                              >M&#8217;2</span
                            >U2<span class="mainColor">M&#8217;2</span>U<span
                              class="mainColor"
                              >M&#8217;2</span
                            >
                          </p>
                        </div>
                        <div class="unit" data-index="3">
                          <div class="orderNum">04</div>
                          <div class="exampleImg">
                            <img src="./images/img120.png" alt="" /><b>Z</b>
                          </div>
                          <p class="exampleText">
                            <span class="mainColor">M&#8217;</span>U(<span
                              class="mainColor"
                              >M&#8217;2</span
                            >U)2<span class="mainColor">M&#8217;</span>U2<span
                              class="mainColor"
                              >M&#8217;2</span
                            ><span class="mainColor2">U&#8217;</span>
                          </p>
                        </div>
                        <div class="unit" data-index="4">
                          <div class="orderNum">05</div>
                          <div class="exampleImg">
                            <img src="./images/img126.png" alt="" /><b>Aa</b>
                          </div>
                          <p class="exampleText">
                            x&#8217;R2<span class="mainColor">D2</span
                            >(R&#8217;<span class="mainColor2">U&#8217;</span
                            >R)<span class="mainColor">D2</span
                            >(R&#8217;UR&#8217;)
                          </p>
                        </div>
                        <div class="unit" data-index="5">
                          <div class="orderNum">06</div>
                          <div class="exampleImg">
                            <img src="./images/img132.png" alt="" /><b>Ab</b>
                          </div>
                          <p class="exampleText">
                            x'(R<span class="mainColor2">U&#8217;</span>R)<span
                              class="mainColor"
                              >D2</span
                            >(R&#8217;UR)<span class="mainColor">D2</span
                            >R&#8217;2
                          </p>
                        </div>
                        <div class="unit" data-index="6">
                          <div class="orderNum">07</div>
                          <div class="exampleImg">
                            <img src="./images/img137.png" alt="" /><b>E</b>
                          </div>
                          <p class="exampleText">
                            x'(R<span class="mainColor2">U&#8217;</span
                            >R&#8217;<span class="mainColor">D</span
                            >)(RUR&#8217;<span class="mainColor">D&#8217;</span
                            >)(RUR&#8217;<span class="mainColor">D</span
                            >)(R<span class="mainColor2">U&#8217;</span
                            >R&#8217;<span class="mainColor">D&#8217;</span>)
                          </p>
                        </div>
                        <div class="unit" data-index="7">
                          <div class="orderNum">08</div>
                          <div class="exampleImg">
                            <img src="./images/img121.png" alt="" /><b>T</b>
                          </div>
                          <p class="exampleText">
                            [SX](R&#8217;F)(R2<span class="mainColor2"
                              >U&#8217;</span
                            >R&#8217;<span class="mainColor2">U&#8217;</span
                            >)(RUR&#8217;<span class="mainColor3">F&#8217;</span
                            >)
                          </p>
                        </div>
                        <div class="unit" data-index="8">
                          <div class="orderNum">09</div>
                          <div class="exampleImg">
                            <img src="./images/img127.png" alt="" /><b>F</b>
                          </div>
                          <p class="exampleText">
                            (R&#8217;<span class="mainColor2">U&#8217;</span
                            ><span class="mainColor3">F&#8217;</span
                            >)<br />[SX](R&#8217;F)(R2<span class="mainColor2"
                              >U&#8217;</span
                            >R&#8217;<span class="mainColor2">U&#8217;</span
                            >)(RUR&#8217;UR)
                          </p>
                        </div>
                        <div class="unit" data-index="9">
                          <div class="orderNum">10</div>
                          <div class="exampleImg">
                            <img src="./images/img133.png" alt="" /><b>V</b>
                          </div>
                          <p class="exampleText">
                            (R&#8217;UR&#8217;d&#8217;)(R&#8217;<span
                              class="mainColor3"
                              >F&#8217;</span
                            >R2<span class="mainColor2">U&#8217;</span
                            >)(R&#8217;UR&#8217;FRF)
                          </p>
                        </div>
                        <div class="unit" data-index="10">
                          <div class="orderNum">11</div>
                          <div class="exampleImg">
                            <img src="./images/img138.png" alt="" /><b>Y</b>
                          </div>
                          <p class="exampleText">
                            F(R<span class="mainColor2">U&#8217;</span
                            >R&#8217;<span class="mainColor2">U&#8217;</span
                            >)(RUR&#8217;<span class="mainColor3">F&#8217;</span
                            >)[SX]<br />[sh]
                          </p>
                        </div>
                        <div class="unit" data-index="11">
                          <div class="orderNum">12</div>
                          <div class="exampleImg">
                            <img src="./images/img122.png" alt="" /><b>Ja</b>
                          </div>
                          <p class="exampleText">
                            z(<span class="mainColor2">U&#8217;</span>R<span
                              class="mainColor"
                              >D&#8217;</span
                            >)(R2UR&#8217;<span class="mainColor2"
                              >U&#8217;</span
                            >)(R2U)(<span class="mainColor">D</span>R&#8217;)
                          </p>
                        </div>
                        <div class="unit" data-index="12">
                          <div class="orderNum">13</div>
                          <div class="exampleImg">
                            <img src="./images/img128.png" alt="" /><b>Jb</b>
                          </div>
                          <p class="exampleText">
                            (RUR&#8217;<span class="mainColor2">F&#8217;</span
                            >)[SX](R&#8217;F)(R2<span class="mainColor2"
                              >U&#8217;</span
                            >R&#8217;<span class="mainColor2">U&#8217;</span>)
                          </p>
                        </div>
                        <div class="unit" data-index="13">
                          <div class="orderNum">14</div>
                          <div class="exampleImg">
                            <img src="./images/img134.png" alt="" /><b>Rb</b>
                          </div>
                          <p class="exampleText">
                            (R&#8217;U2)(R<span class="mainColor3"
                              >U&#8217;</span
                            ><span class="mainColor2">U&#8217;</span
                            >)(R&#8217;F) [SX](R&#8217;<span class="mainColor3"
                              >F&#8217;</span
                            >R2<span class="mainColor2">U&#8217;</span>)
                          </p>
                        </div>
                        <div class="unit" data-index="14">
                          <div class="orderNum">15</div>
                          <div class="exampleImg">
                            <img src="./images/img139.png" alt="" /><b>Ra</b>
                          </div>
                          <p class="exampleText">
                            (R<span class="mainColor2">U&#8217;</span
                            >R&#8217;<span class="mainColor2">U&#8217;</span
                            >)(RUR<span class="mainColor">D</span
                            >)(R&#8217;<span class="mainColor2">U&#8217;</span
                            >R<span class="mainColor">D&#8217;</span
                            >)(R&#8217;U2R&#8217;U&#8217;)
                          </p>
                        </div>
                        <div class="unit" data-index="15">
                          <div class="orderNum">16</div>
                          <div class="exampleImg">
                            <img src="./images/img123.png" alt="" /><b>Gc</b>
                          </div>
                          <p class="exampleText">
                            (R&#8217;2<span class="mainColor2">u&#8217;</span
                            >R<span class="mainColor3">U&#8217;</span
                            >R)(UR&#8217;u)(R2fR&#8217;<span class="mainColor3"
                              >f&#8217;</span
                            >)
                          </p>
                        </div>
                        <div class="unit" data-index="16">
                          <div class="orderNum">17</div>
                          <div class="exampleImg">
                            <img src="./images/img129.png" alt="" /><b>Gd</b>
                          </div>
                          <p class="exampleText">
                            (RUR&#8217;)y'(R2<span class="mainColor3"
                              >u&#8217;</span
                            >R<span class="mainColor2">U&#8217;</span
                            >)(R&#8217;UR&#8217;uR2)
                          </p>
                        </div>
                        <div class="unit" data-index="17">
                          <div class="orderNum">18</div>
                          <div class="exampleImg">
                            <img src="./images/img135.png" alt="" /><b>Ga</b>
                          </div>
                          <p class="exampleText">
                            (R2uR&#8217;)(UR&#8217;<span class="mainColor2"
                              >U&#8217;</span
                            >R<span class="mainColor2">u&#8217;</span
                            >)(R&#8217;2<span class="mainColor3"
                              >F&#8217;UF</span
                            >)
                          </p>
                        </div>
                        <div class="unit" data-index="18">
                          <div class="orderNum">19</div>
                          <div class="exampleImg">
                            <img src="./images/img140.png" alt="" /><b>Gb</b>
                          </div>
                          <p class="exampleText">
                            (R&#8217;d&#8217;F)(R2u)(R&#8217;U)(R<span
                              class="mainColor3"
                              >U&#8217;</span
                            >R<span class="mainColor2">u&#8217;</span>R&#8217;2)
                          </p>
                        </div>
                        <div class="unit" data-index="19">
                          <div class="orderNum">20</div>
                          <div class="exampleImg">
                            <img src="./images/img124.png" alt="" /><b>Nb</b>
                          </div>
                          <p class="exampleText">
                            (R&#8217;UR<span class="mainColor2">U&#8217;</span
                            >)(R&#8217;<span class="mainColor3">F&#8217;</span
                            ><span class="mainColor2">U&#8217;</span
                            >)(FRUR&#8217;F)(R&#8217;<span class="mainColor3"
                              >F&#8217;</span
                            >R<span class="mainColor2">U&#8217;</span>R)
                          </p>
                        </div>
                        <div class="unit" data-index="20">
                          <div class="orderNum">21</div>
                          <div class="exampleImg">
                            <img src="./images/img130.png" alt="" /><b>Na</b>
                          </div>
                          <p class="exampleText">
                            (RUR&#8217;<span class="mainColor">U</span
                            >)(RUR&#8217;<span class="mainColor2">F&#8217;</span
                            >)<br />[SX](R&#8217;F)<br />(R2<span
                              class="mainColor2"
                              >U&#8217;</span
                            >R&#8217;U2)(R<span class="mainColor2"
                              >U&#8217;</span
                            >R&#8217;)
                          </p>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>

                <div class="text-warning">
                  <div class="text-warning">
                    Check also:
                    <a href="https://jperm.net/algs/pll">jperm.net/algs/pll</a>
                  </div>
                  <div>
                    This is a copy of official GAN site with some improvements
                    for my personal use only!<a
                      href="https://www.gancube.com/3x3x3-cfop-guide-of-gancube"
                    >
                      Official GAN site
                    </a>
                  </div>
                  <div class="" style="display: none" id="cubePop">
                    <div class="cubeScene" id="cubeScene">
                      <div class="scene" id="scene"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue';

onMounted(() => {
  let scriptArr = [];
  //   scriptArr.push(
  //     'https://www.gancube.com/wp-content/themes/chtemp/js/jquery.min-1.11.3.js'
  //   );
  //   scriptArr.push(
  //     'https://www.gancube.com/wp-content/themes/chtemp/js/bootstrap-4.1.3/js/bootstrap.min.js'
  //   );
  //   scriptArr.push(
  //     'https://www.gancube.com/wp-content/themes/chtemp/js/Swiper-4.0.5/js/swiper.js'
  //   );
  //   scriptArr.push(
  //     'https://www.gancube.com/wp-content/themes/chtemp/js/layer_mobile/layer.js'
  //   );
  //   scriptArr.push(
  //     'https://www.gancube.com/wp-content/themes/chtemp/js/headroom.js'
  //   );
  //   scriptArr.push(
  //     'https://www.gancube.com/wp-content/themes/chtemp/js/common.js'
  //   );

  //   scriptArr.push(
  //     'https://www.gancube.com/wp-content/themes/Parallax-One-GANcube/CFOP/js/jquery.min-1.11.3.js'
  //   );
  //   scriptArr.push(
  //     'https://www.gancube.com/wp-content/themes/Parallax-One-GANcube/CFOP/js/layer/layer.js'
  //   );
  //   scriptArr.push(
  //     'https://www.gancube.com/wp-content/themes/Parallax-One-GANcube/CFOP/js/vue.min.js'
  //   );

  //   scriptArr.push(
  //     'https://www.gancube.com/wp-content/themes/Parallax-One-GANcube/CFOP/js/cubeSceneData.min.js'
  //   );
  //   scriptArr.push(
  //     'https://www.gancube.com/wp-content/themes/Parallax-One-GANcube/CFOP/js/cubeScene.min.js'
  //   );
  //   scriptArr.push(
  //     'https://www.gancube.com/wp-content/themes/Parallax-One-GANcube/CFOP/js/CFOP.min.js'
  //   );

  for (let i = 0; i < scriptArr.length; i++) {
    const script1 = document.createElement('script');
    script1.setAttribute('src', scriptArr[i]);
    document.head.appendChild(script1);
  }
});

/* ===== F2L ===== */
// function showMixedUp() {
// 	hideF2LAll()
// 	showHideSection([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 22, 23, 32, 33], '', false)
// }

function showBothDown() {
  hideF2LAll();
  addClass('btn-bothDown-F2L', 'buyActive');
  showHideSection([1, 4, 5, 8, 9], '', false);
}

function showUpAndDown() {
  hideF2LAll();
  addClass('btn-upAndDown-F2L', 'buyActive');
  showHideSection([2, 3, 6, 7, 10, 11], '', false);
}

function showUpAndMid() {
  hideF2LAll();
  addClass('btn-upAndMid-F2L', 'buyActive');
  showHideSection([12, 13, 22, 23, 32, 33], '', false);
}

function showBothUp() {
  hideF2LAll();
  addClass('btn-bothUp-F2L', 'buyActive');
  showHideSection(
    [
      14, 15, 16, 17, 18, 19, 20, 21, 24, 25, 26, 27, 28, 29, 30, 31, 34, 35,
      36, 37, 38, 39, 40, 41,
    ],
    '',
    false
  );
}

function showBothUpWhiteUp() {
  hideF2LAll();
  addClass('btn-bothUpWhiteUp-F2L', 'buyActive');
  showHideSection([15, 16, 19, 20], '', false);
  //showHideSection([14, 15, 16, 17, 18, 19, 20, 21], '', false)
}

function showBothUpWhiteFront() {
  hideF2LAll();
  addClass('btn-bothUpWhiteFront-F2L', 'buyActive');
  showHideSection([25, 26, 29, 30], '', false);
  //showHideSection([24, 25, 26, 27, 28, 29, 30, 31], '', false)
}

function showBothUpWhiteSide() {
  hideF2LAll();
  addClass('btn-bothUpWhiteSide-F2L', 'buyActive');
  showHideSection([35, 36, 39, 40], '', false);
  //showHideSection([34, 35, 36, 37, 38, 39, 40, 41], '', false)
}

// function showBothUpBlock() {
// 	hideF2LAll()
// 	addClass('btn-bothUpBlock-F2L', 'buyActive');
// 	showHideSection([14, 17, 18, 21, 24, 27, 28, 31, 34, 37, 38, 41], '', false)
// }

function showF2LAll() {
  removeClassAllBtn();
  addClass('btn-showAll-F2L', 'buyActive');
  showHideSection(
    [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
      40, 41,
    ],
    'F2L',
    false
  );
}

function hideF2LAll() {
  removeClassAllBtn();
  showHideSection(
    [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
      40, 41,
    ],
    'F2L',
    true
  );
}

function showBothUpWhiteUpBlock() {
  hideF2LAll();
  addClass('btn-bothUp-whiteUp-block-F2L', 'buyActive');
  showHideSection([14, 17, 18, 21], '', false);
}

function showBothUpWhiteFrontBlock() {
  hideF2LAll();
  addClass('btn-bothUp-whiteFront-block-F2L', 'buyActive');
  showHideSection([24, 27, 28, 31], '', false);
}

function showBothUpWhiteRightBlock() {
  hideF2LAll();
  addClass('btn-bothUp-whiteRight-block-F2L', 'buyActive');
  showHideSection([34, 37, 38, 41], '', false);
}

/* ===== OLL ===== */
function showOLLAll() {
  removeClassAllBtn_OLL();
  addClass('btn-showAll-OLL', 'buyActive');
  addClass('img-showAll-OLL', 'selectedImg');
  showHideSection(
    [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
      40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57,
    ],
    'OLL',
    false
  );
}

function hideOLLAll() {
  removeClassAllBtn_OLL();
  showHideSection(
    [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
      40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57,
    ],
    'OLL',
    true
  );
}

function showOLL1piece() {
  hideOLLAll();
  addClass('btn-OLL-1piece', 'buyActive');
  addClass('img-OLL_1piece', 'selectedImg');
  showHideSection([1, 2], 'OLL', false);
}

function showOLL2piece() {
  hideOLLAll();
  addClass('btn-OLL-2piece', 'buyActive');
  addClass('img-OLL_2piece', 'selectedImg');
  showHideSection([3, 4], 'OLL', false);
}

function showOLL3piece() {
  hideOLLAll();
  addClass('btn-OLL-3piece', 'buyActive');
  addClass('img-OLL_3piece', 'selectedImg');
  showHideSection(
    [17, 18, 19, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
    'OLL',
    false
  );
}

function showOLL4piece() {
  hideOLLAll();
  addClass('btn-OLL-4piece', 'buyActive');
  addClass('img-OLL_4piece', 'selectedImg');
  showHideSection([5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16], 'OLL', false);
}

function showOLL5piece() {
  hideOLLAll();
  addClass('btn-OLL-5piece', 'buyActive');
  addClass('img-OLL_5piece', 'selectedImg');
  showHideSection(
    [
      20, 21, 22, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
      44, 45, 46,
    ],
    'OLL',
    false
  );
}

function showOLL3missing() {
  hideOLLAll();
  addClass('btn-OLL-3missing', 'buyActive');
  addClass('img-OLL-3missing', 'selectedImg');
  showHideSection([26, 27], 'OLL', false);
}

function showOLL2missing() {
  hideOLLAll();
  addClass('btn-OLL-2missing', 'buyActive');
  addClass('img-OLL-2missing', 'selectedImg');
  showHideSection([23, 24, 25, 28, 57], 'OLL', false);
}

// function showOLL_Lshape() {
// 	hideOLLAll()
// 	addClass('btn-OLL_Lshape', 'buyActive');
// 	showHideSection([13, 14, 15, 16, 47, 48, 49, 50, 53, 54], 'OLL', false)
// }

function showOLL_Tshape() {
  hideOLLAll();
  addClass('btn-OLL_Tshape', 'buyActive');
  addClass('img-OLL_Tshape', 'selectedImg');
  showHideSection([33, 45], 'OLL', false);
}

function showOLL_Lineshape() {
  hideOLLAll();
  addClass('btn-OLL_Lineshape', 'buyActive');
  addClass('img-OLL_Lineshape', 'selectedImg');
  showHideSection([17, 51, 52, 55, 56], 'OLL', false);
}

function showOLL_Crossshape() {
  hideOLLAll();
  addClass('btn-OLL_Crossshape', 'buyActive');
  addClass('img-OLL_Crossshape', 'selectedImg');
  showHideSection([21, 22], 'OLL', false);
}

function showOLL_Lshape1() {
  hideOLLAll();
  addClass('img-OLL_Lshape1', 'selectedImg');
  showHideSection([13, 14, 15, 16], 'OLL', false);
}

function showOLL_Lshape2() {
  hideOLLAll();
  addClass('img-OLL_Lshape2', 'selectedImg');
  showHideSection([47, 48, 49, 50, 53, 54], 'OLL', false);
}

function showOLL_Rocketshape() {
  hideOLLAll();
  addClass('img-OLL_Rocketshape', 'selectedImg');
  showHideSection([29, 30, 41, 42], 'OLL', false);
}

function showOLL_Sshape() {
  hideOLLAll();
  addClass('img-OLL_Sshape', 'selectedImg');
  showHideSection([7, 8, 11, 12], 'OLL', false);
}

function showOLL_buildingshape() {
  hideOLLAll();
  addClass('img-OLL_buildingshape', 'selectedImg');
  showHideSection([31, 32, 43, 44], 'OLL', false);
}

function showOLL_Cshape() {
  hideOLLAll();
  addClass('img-OLL_Cshape', 'selectedImg');
  showHideSection([34, 46], 'OLL', false);
}

function showOLL_toLearn() {
  hideOLLAll();
  addClass('img-learn-40', 'selectedImg');
  showHideSection(
    [9, 10, 15, 16, 19, 31, 35, 37, 38, 40, 46, 52, 54, 55, 56],
    'OLL',
    false
  );
}

// qwer

function showHideSection(arrOfIds, section, hide) {
  console.log(hide);

  if (typeof hide == 'undefined' || hide == null) {
    hide = true;
  }

  var prefix = 'F2L-';
  var postfix1 = '-IMG';
  var postfix2 = '-P';
  var postfix3 = '-DIV';

  if (section == 'OLL') prefix = 'OLL-';

  //console.log(hide)
  //console.log(section)

  for (var i = 0; i < arrOfIds.length; i++) {
    var leadingZero = '';
    if (arrOfIds[i] < 10) leadingZero = '0';

    if (hide) {
      hideElem(prefix + leadingZero + arrOfIds[i].toString() + postfix1);
      hideElem(prefix + leadingZero + arrOfIds[i].toString() + postfix2);
      hideElem(prefix + leadingZero + arrOfIds[i].toString() + postfix3);
    } else {
      showElem(prefix + leadingZero + arrOfIds[i].toString() + postfix1);
      showElem(prefix + leadingZero + arrOfIds[i].toString() + postfix2);
      showElem(prefix + leadingZero + arrOfIds[i].toString() + postfix3);
    }
  }
}

function showElem(id) {
  var elem = document.getElementById(id);
  if (typeof elem != 'undefined' && elem != null) elem.hidden = false;
}

function hideElem(id) {
  var elem = document.getElementById(id);
  if (typeof elem != 'undefined' && elem != null) elem.hidden = true;
}

function removeClass(id, classname) {
  var elem = document.getElementById(id);
  //console.log(elem)
  if (typeof elem != 'undefined' && elem != null)
    elem.classList.remove(classname);
}

function addClass(id, classname) {
  var elem = document.getElementById(id);
  //console.log(elem)
  if (typeof elem != 'undefined' && elem != null) elem.classList.add(classname);
}

function removeClassAllBtn() {
  removeClass('btn-hideAll-F2L', 'buyActive');
  removeClass('btn-showAll-F2L', 'buyActive');
  removeClass('btn-bothDown-F2L', 'buyActive');
  removeClass('btn-upAndDown-F2L', 'buyActive');
  removeClass('btn-upAndMid-F2L', 'buyActive');
  removeClass('btn-bothUp-F2L', 'buyActive');
  removeClass('btn-bothUpWhiteUp-F2L', 'buyActive');
  removeClass('btn-bothUpWhiteFront-F2L', 'buyActive');
  removeClass('btn-bothUpWhiteSide-F2L', 'buyActive');
  removeClass('btn-bothUpBlock-F2L', 'buyActive');
  removeClass('btn-bothUp-whiteUp-block-F2L', 'buyActive');
  removeClass('btn-bothUp-whiteFront-block-F2L', 'buyActive');
  removeClass('btn-bothUp-whiteRight-block-F2L', 'buyActive');
}

function removeClassAllBtn_OLL() {
  removeClass('btn-showAll-OLL', 'buyActive');
  removeClass('btn-OLL_Lineshape', 'buyActive');
  removeClass('btn-OLL_Crossshape', 'buyActive');
  removeClass('btn-OLL_Lshape', 'buyActive');
  removeClass('btn-OLL_Tshape', 'buyActive');
  removeClass('btn-OLL-3missing', 'buyActive');
  removeClass('btn-OLL-2missing', 'buyActive');
  removeClass('btn-OLL-1piece', 'buyActive');
  removeClass('btn-OLL-2piece', 'buyActive');
  removeClass('btn-OLL-3piece', 'buyActive');
  removeClass('btn-OLL-4piece', 'buyActive');
  removeClass('btn-OLL-5piece', 'buyActive');

  removeClass('img-showAll-OLL', 'selectedImg');

  removeClass('img-OLL_1piece', 'selectedImg');
  removeClass('img-OLL_2piece', 'selectedImg');
  removeClass('img-OLL_3piece', 'selectedImg');
  removeClass('img-OLL_4piece', 'selectedImg');
  removeClass('img-OLL_5piece', 'selectedImg');

  removeClass('img-OLL-3missing', 'selectedImg');
  removeClass('img-OLL-2missing', 'selectedImg');

  removeClass('img-OLL_Lineshape', 'selectedImg');
  removeClass('img-OLL_Lshape1', 'selectedImg');
  removeClass('img-OLL_Lshape2', 'selectedImg');
  removeClass('img-OLL_Rocketshape', 'selectedImg');
  removeClass('img-OLL_Sshape', 'selectedImg');
  removeClass('img-OLL_buildingshape', 'selectedImg');
  removeClass('img-OLL_Tshape', 'selectedImg');
  removeClass('img-OLL_Crossshape', 'selectedImg');
  removeClass('img-OLL_Cshape', 'selectedImg');
  removeClass('img-learn-40', 'selectedImg');
}
</script>

<style scoped>
/* @import './css/iconfont.css'; */
/* @import './css/common.css'; */
@import './css/style.css';
/* @import './css/adapt.css';
@import './css/cubeScene.css'; */

.buyActive {
  /* background-color: #28a745 !important; */
  background-color: navy !important;
  /*font-weight: bolder;*/
  color: blue !important;
}
.scramble {
  /* color: #f08200 !important; */
  color: #d636db !important;
}

.scramblok {
  /* color: #f08200 !important; */
  /* color: #d636db !important; */
  background-color: rgba(183, 255, 0, 0.447) !important;
  padding: 2px !important;
}

.scramble_orange {
  /* color: #f08200 !important; */
  /* color: #d636db !important; */
  background-color: rgba(251, 122, 2, 0.578) !important;
  padding: 2px !important;
}

.scramble_learn {
  background-color: rgba(251, 2, 2, 0.579) !important;
  padding: 2px !important;
}

.rotate90 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.img_btn {
  vertical-align: middle;
  display: table-cell;
  margin-right: 6px !important;
}

.img_span {
  vertical-align: middle;
  display: table-cell;
}

.selectedImg {
  background-color: hsla(206, 87%, 61%, 0.65);

  /* background-color: hsla(33, 100%, 47%, 0.5); */
}

.cubePop {
  max-width: 800px;
}

.cubePop:after {
  content: '';
  display: block;
  position: absolute;
  z-index: 9;
  right: 2em;
  top: 1em;
  width: 60px;
  height: 70px;
  background: url(https://www.gancube.com/wp-content/themes/Parallax-One-GANcube/images/logo.png);
  background-repeat: no-repeat;
  background-size: 100%;
}

.cubePop .cubeScene .formulaImg {
  position: absolute;
  z-index: 9;
  left: 4em;
  top: 2em;
  width: 80px;
  height: 80px;
  text-align: center;
}

.cubePop .cubeScene .formulaImg .orderNum {
  position: relative;
  top: -5px;
  font-size: 18px;
  color: var(--mainColor);
  font-weight: 700;
  text-align: center;
}

.cubePop .cubeScene .formulaCode {
  position: absolute;
  bottom: 0.5em;
  width: 100%;
  text-align: center;
  font-size: 1.8em;
  line-height: 1;
}

.cubePop .cubeScene .runFormulaCode {
  position: absolute;
  top: 0.5em;
  width: 100%;
  height: 1.5em;
  text-align: center;
  font-size: 1.8em;
  line-height: 1;
}

.cubePop .cubeScene .runFormulaCode .codeBox {
  position: relative;
  margin: 0 auto;
  width: 50%;
  height: 100%;
  overflow: hidden;
  word-break: normal;
}

.cubePop .cubeScene .runFormulaCode .codeBox .fCode {
  margin: 4px;
  width: 28px;
}

.cubePop .cubeScene .parts {
  padding: 1em 1em 0;
  position: absolute;
  top: 10em;
  left: 0em;
  width: 220px;
}

.cubePop .cubeScene .parts input {
  display: block;
  width: 100%;
  padding: 2px 8px;
  margin-bottom: 1em;
  background-color: #fff;
  border: 1px solid #ddd;
}

.cubePop .cubeScene .parts input[type='button'] {
  height: 30px;
  color: #000;
  background-color: #eee;
}

.cubePop .cubeScene .parts .buttonGroup .buttonGroupBtn {
  width: 16%;
}

.white-text {
  color: #eee;
}

.white-text:hover {
  color: #44a8f2;
}

/* @media (max-width: 768px) {
  .cube {
    font-size: 140%;
  }

  .scene {
    min-height: 330px;
  }

  .cubePop:after {
    width: 50px;
    top: 1.2em;
    right: 1em;
  }

  .cubePop .cubeScene .parts {
    display: block;
    position: relative;
    top: 0;
    left: 0;
    margin: 0 auto;
    width: 280px;
  }

  .cubePop .cubeScene .formulaImg {
    width: 60px;
    left: 1em;
    top: 1em;
  }
} */
</style>
