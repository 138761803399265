<template>
  <!-- <div class="text-warning">{{ isLoggedIn }}</div> -->

  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="container-fluid ms-1">
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mt-3 mb-2 mb-lg-0">
          <li class="me-4">
            <router-link to="/" class="nav-link text-warning"
              ><h4>Finance 3rd Generation</h4></router-link
            >
          </li>
        </ul>

        <ul class="navbar-nav ms-auto mt-3 mb-2 mb-lg-0">
          <li v-if="isLoggedIn">
            <router-link to="/newtrans2" class="nav-link active text-warning"
              >Transactions</router-link
            >
          </li>

          <li v-if="isLoggedIn">
            <router-link to="/periodic" class="nav-link active text-warning"
              >Periodic</router-link
            >
          </li>

          <li v-if="isLoggedIn" class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle text-warning"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Analysis
            </a>

            <ul class="dropdown-menu bg-dark" aria-labelledby="navbarDropdown">
              <li v-if="isLoggedIn">
                <router-link
                  to="/analysisgraph"
                  class="dropdown-item text-warning"
                  >Analysis-Month</router-link
                >
              </li>

              <li v-if="isLoggedIn">
                <router-link
                  to="/analysisyear"
                  class="dropdown-item text-warning"
                  >Analysis Year</router-link
                >
              </li>

              <li v-if="isLoggedIn">
                <router-link to="/analysis" class="dropdown-item text-warning"
                  >Analysis</router-link
                >
              </li>
            </ul>
          </li>

          <li v-if="isLoggedIn" class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle text-warning"
              href="#"
              id="navbarDropdown1"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Settings
            </a>
            <ul class="dropdown-menu bg-dark" aria-labelledby="navbarDropdown1">
              <li>
                <router-link to="/invest" class="dropdown-item text-warning"
                  >Symbols</router-link
                >
              </li>

              <li>
                <router-link
                  to="/invest-list"
                  class="dropdown-item text-warning"
                  >Invest list</router-link
                >
              </li>

              <li>
                <router-link to="/rubik" class="dropdown-item text-warning"
                  >Rubik</router-link
                >
              </li>

              <!-- <li>
                <router-link to="/rubik-old" class="dropdown-item text-warning"
                  >Rubik Old</router-link
                >
              </li> -->

              <li v-if="isLoggedIn">
                <router-link to="/settings" class="dropdown-item text-warning"
                  >General</router-link
                >
              </li>

              <li><hr class="dropdown-divider" /></li>

              <li v-if="isLoggedIn">
                <router-link to="/rules" class="dropdown-item text-warning"
                  >Rules</router-link
                >
              </li>

              <li v-if="isLoggedIn">
                <router-link to="/accounts" class="dropdown-item text-warning"
                  >Accounts</router-link
                >
              </li>

              <li v-if="isLoggedIn">
                <router-link
                  to="/accountGroups"
                  class="dropdown-item text-warning"
                  >Account Groups</router-link
                >
              </li>

              <li v-if="isLoggedIn">
                <router-link
                  to="/settings-gl"
                  class="dropdown-item text-warning"
                  >Global Groups</router-link
                >
              </li>

              <li v-if="isLoggedIn">
                <router-link to="/categories" class="dropdown-item text-warning"
                  >Categories</router-link
                >
              </li>
            </ul>
          </li>

          <li v-else>
            <router-link class="nav-link active text-warning" to="/auth"
              >Login</router-link
            >
          </li>
          <li v-if="isLoggedIn">
            <base-button @click="logout">Logout</base-button>
          </li>

          <div class="btn-group">
            <button
              type="button"
              class="btn btn-warning dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style="height: 2.3rem"
            >
              Change Theme
            </button>
            <ul class="dropdown-menu">
              <li>
                <a
                  @click="changeTheme('custom-bs5-orange.css')"
                  class="dropdown-item"
                  href="#"
                  ><img
                    :src="getImg('orange')"
                    alt="Orange"
                    style="width: 1rem; height: 1rem"
                  />&nbsp; Orange

                  <!-- <svg
                    viewBox="0 0 100 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="37%" cy="40%" r="5px" fill="orange" />
                  </svg> -->
                </a>
              </li>

              <li>
                <a
                  @click="changeTheme('custom-bs5-blue.css')"
                  class="dropdown-item"
                  href="#"
                >
                  <img
                    :src="getImg('blue')"
                    alt="Blue"
                    style="width: 1rem; height: 1rem"
                  />
                  &nbsp;Blue

                  <!-- <svg viewBox="0 0 100 20">
                    <circle cx="60%" cy="40%" r="5px" fill="blue" />
                  </svg> -->
                </a>
              </li>

              <li>
                <a
                  @click="changeTheme('custom-bs5-green.css')"
                  class="dropdown-item"
                  href="#"
                >
                  <img
                    :src="getImg('green')"
                    alt="Blue"
                    style="width: 1rem; height: 1rem"
                  />
                  &nbsp;Green
                  <!-- <svg viewBox="0 0 100 20">
                    <circle cx="44%" cy="40%" r="5px" fill="lime" />
                  </svg> -->
                </a>
              </li>

              <li>
                <a
                  @click="changeTheme('custom-bs5-gray.css')"
                  class="dropdown-item"
                  href="#"
                >
                  <img
                    :src="getImg('gray')"
                    alt="Blue"
                    style="width: 1rem; height: 1rem"
                  />
                  &nbsp;Gray
                  <!-- <svg viewBox="0 0 100 20">
                    <circle cx="54%" cy="40%" r="5px" fill="gray" />
                  </svg> -->
                </a>
              </li>

              <li>
                <a
                  @click="changeTheme('custom-bs5-pink.css')"
                  class="dropdown-item"
                  href="#"
                >
                  <img
                    :src="getImg('pink')"
                    alt="Pink"
                    style="width: 1rem; height: 1rem"
                  />
                  &nbsp;Pink
                  <!-- <svg viewBox="0 0 100 20">
                    <circle cx="54%" cy="40%" r="5px" fill="gray" />
                  </svg> -->
                </a>
              </li>
            </ul>
          </div>

          <li class="nav-link active text-warning" v-if="isLoggedIn">
            {{ getEmail }}&nbsp;&nbsp;
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { useAuthStore } from '../../stores/AuthStore';

import imgOrange from '../../icons/orange.png';
import imgBlue from '../../icons/blue.png';
import imgGreen from '../../icons/green.png';
import imgGray from '../../icons/gray.png';
import imgPink from '../../icons/pink.png';

export default {
  computed: {
    isLoggedIn() {
      return useAuthStore().isAuthenticated;
    },
    getEmail() {
      return useAuthStore().userEmail;
    },
  },
  methods: {
    changeTheme(theme) {
      // console.log('event');
      let themeElementIndexHtml = document.getElementById('theme-link');
      // console.log(themeElement);
      themeElementIndexHtml.setAttribute('href', theme);
      localStorage.setItem('theme', theme);
    },

    logout() {
      this.$router.replace('/auth');
      const authStore = useAuthStore();
      authStore.logout();
      // this.$store.dispatch('logout');
    },
    getImg(color) {
      if (color === 'orange') {
        return imgOrange;
      } else if (color === 'blue') {
        return imgBlue;
      } else if (color === 'green') {
        return imgGreen;
      } else if (color === 'gray') {
        return imgGray;
      } else if (color === 'pink') {
        return imgPink;
      } else return '';
    },
  },
};
</script>

<style scoped>
/* * {
  color: #888888 !important;
} */

/* router-link {
  color:red
} */

header {
  width: 100%;
  height: 5rem;
  /* background-color: #3d008d; */
  /* background-color: rgb(255, 175, 70); */
  background-color: #151617;
  display: flex;
  justify-content: center;
  align-items: center;
}

header a {
  text-decoration: none;
  color: #f391e3;
  display: inline-block;
  padding: 0.7rem 0.3rem;
  /* border: 1px solid transparent; */
}
/* padding: 0.75rem 1.5rem; */
a:active,
a:hover,
/* a.router-link-active {
  color: rgb(247, 130, 5) !important;
} */

h1 {
  margin: 0;
}

h1 a,
h2,
h3 {
  color: white;
  margin: 0;
}

h1 a:hover,
h1 a:active,
h1 a.router-link-active {
  border-color: transparent;
}

header nav {
  width: 95%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

li {
  margin: 0 0.5rem;
  font-size: large;
  font-weight: 600;
}
</style>
