import {
  child,
  endAt,
  equalTo,
  get,
  getDatabase,
  limitToLast,
  orderByChild,
  query,
  ref as reffb,
  startAt,
  update,
} from 'firebase/database';

import config from '../../store/global.js';
import { getAuth } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import moment from 'moment';
import { useAccountStore } from '../../stores/AccountStore';
import { useCategoryStore } from '../../stores/CategoryStore';
import { useSettingStore } from '../../stores/SettingStore';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

//import store from '../../store/index.js';

//const categoryStore = useCategoryStore();

//const categoryStore = useCategoryStore();

//import { useI18n } from 'vue-i18n';

//const { t, n } = useI18n();

export default {
  conlog(p1, p2) {
    if (p2) {
      console.log(p1, p2);
    } else {
      console.log(p1);
    }
  },

  setSortingParamsProto(field, dataSorting, dataSortingOrder, pageStoreAction) {
    //diference between this andsetSortingParams is that
    //here you send as parameter action to be executed
    if (field == dataSorting.value) {
      if (dataSortingOrder.value == 'asc') {
        dataSortingOrder.value = 'desc';
      } else {
        dataSortingOrder.value = 'asc';
      }
    } else {
      dataSortingOrder.value = 'asc';
    }
    dataSorting.value = field;

    if (pageStoreAction) {
      pageStoreAction({
        field: dataSorting.value,
        order: dataSortingOrder.value,
      });
    }
  },

  setSortingParams(field, dataSorting, dataSortingOrder, pageStore) {
    if (field == dataSorting.value) {
      if (dataSortingOrder.value == 'asc') {
        dataSortingOrder.value = 'desc';
      } else {
        dataSortingOrder.value = 'asc';
      }
    } else {
      dataSortingOrder.value = 'asc';
    }
    dataSorting.value = field;

    //console.log(pageStore);
    if (pageStore) {
      pageStore.setSorting({
        field: dataSorting.value,
        order: dataSortingOrder.value,
      });
    }
  },

  setSortingUtil(payload, data) {
    //console.log('setSortingUtil -> ', payload);
    //console.log('setSortingUtil -> ', data);
    //payload = { field: 'field' , order: 'desc' }

    let tmp = [];

    Object.assign(tmp, data);
    data = [];

    this.sortByKey(tmp, payload.field);

    //console.log(tmp);

    if (payload.order == 'desc') data = tmp.reverse();
    else data = tmp;

    //console.log('setSortingUtil -> ', data);

    return data;
  },

  sum(a, b) {
    return a + b;
  },

  getSettings(settingsKey, settingsField) {
    let ret;

    const settingStore = useSettingStore();
    var tmp = settingStore.settings;

    if (tmp[settingsKey]) {
      if (tmp[settingsKey][settingsField]) {
        ret = tmp[settingsKey][settingsField];
      } else ret = tmp[settingsKey];
    }
    //console.log(`getSettings [${settingsKey} | ${settingsField}]`);
    // console.log('========== getSettings ==========');
    //console.log(ret);

    return ret;
  },

  getCategory(level) {
    const categoryStore = useCategoryStore();
    // console.log('getCategory => categoryStore | level: ' + level);

    if (level == 1) {
      return categoryStore.konto1;
    } else if (level == 2) {
      return categoryStore.konto2;
    } else if (level == 3) {
      return categoryStore.konto3;
    } else if (level == 4) {
      return categoryStore.konto4;
    } else return [];

    // console.log(categoryStore);
    // return categoryStore.konto1;
  },

  getCurrentYear(shift) {
    var ret = new Date().toISOString().split('T')[0];
    ret = ret.split('-')[0];

    if (arguments.length == 1) {
      ret = String(Number(ret) + shift);
    }

    return ret;
  },

  getCurrentYearMonth(offset = 0) {
    //from chatgpt
    const now = new Date();
    now.setDate(1); // Set to the 1st of the month to avoid overflow
    now.setMonth(now.getMonth() + offset);

    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0');

    //console.log(`offset: ${offset} YYYY-MM: ${year}-${month}`);

    return `${year}-${month}`;
  },

  getCurrentYearMonth_OLD(offset = null) {
    var ret = new Date().toISOString().split('T')[0];
    ret = ret.split('-')[0] + '-' + ret.split('-')[1];

    if (offset) {
      let year = Number(ret.split('-')[0]);
      let month = Number(ret.split('-')[1]) + offset;

      if (month == 0 || month < 0) {
        month = 12 + month;
        year = year - 1;

        //let removeYears = parseInt((-1 * month) / 12) + 1;
        // console.log(`removeYears: ${removeYears}`);
        // while (removeYears > 1) {
        //   year = year - 1;
        //   removeYears--;
        // }
      }

      if (month == 13) {
        month = 1;
        year = year + 1;
      }

      ret = String(year) + '-' + ('0' + String(month)).slice(-2);
      console.log(
        `getCurrentYearMonth => offset: ${offset} ret1: ${ret} month: ${
          Number(ret.split('-')[1]) + offset
        }`
      );
    }

    return ret;
  },

  getDateDDMMYYYY(dateIso) {
    let ret =
      dateIso.substr(8, 2) +
      '.' +
      dateIso.substr(5, 2) +
      '.' +
      dateIso.substr(0, 4);
    return ret;
  },

  // getFormatedDate(dateString) {
  //   return moment(moment(dateString.substring(0, 10), 'YYYY-MM-DD')).format(
  //     'DD.MM.YYYY'
  //   );
  // },

  getFormatedDate(dateString, parFormat) {
    if (parFormat) {
      return moment(moment(dateString.substring(0, 10), 'YYYY-MM-DD')).format(
        parFormat
      );
    } else {
      return moment(moment(dateString.substring(0, 10), 'YYYY-MM-DD')).format(
        'DD.MM.YYYY'
      );
    }
  },

  getFormatedDate2(dateString, splitSymbol, returnSymbol) {
    if (arguments.length == 1) {
      splitSymbol = '/';
      returnSymbol = '-';
    }
    if (arguments.length == 2) returnSymbol = '-';

    let parts = dateString.split(splitSymbol);
    let formattedDate = `${parts[2]}${returnSymbol}${parts[1]}${returnSymbol}${parts[0]}`;
    return formattedDate;
  },

  getGuidAcc(accountId) {
    // console.log('getGuidAcc=>accountId');
    // console.log(accountId);
    let ret = {};
    const checkIdAccount = accountId ? accountId : null;
    if (checkIdAccount) {
      const accountStore = useAccountStore();
      const allAccount = accountStore.accounts;

      for (const key in allAccount) {
        if (allAccount[key].id === checkIdAccount) {
          ret.description = allAccount[key].description;
          ret.guid = key;
          break;
        }
      }
    }
    return ret;
  },

  getGuidAccountObj(guid, allAccounts = null) {
    let ret = { description: '', guid: '' };

    if (!guid) return ret;

    if (!allAccounts) {
      const accountStore = useAccountStore();
      allAccounts = accountStore.accounts;
    }

    // console.log('getGuidAccountObj => allAccounts');
    // console.log(allAccounts);

    for (const key in allAccounts) {
      if (key === guid) {
        ret.description = allAccounts[key].description;
        ret.guid = key;
        break;
      }
    }
    return ret;
  },

  getGuidKontoObj(guid) {
    let ret = {};
    const categoryStore = useCategoryStore();
    const allKontos = categoryStore.allKontos;

    for (const key in allKontos) {
      if (key === guid) {
        ret.guid = guid;
        ret.description = allKontos[key].description;
        ret.lvl = allKontos[key].lvl;
        ret.guid_parent = allKontos[key].guid_parent;
        ret.type = allKontos[key].type;
        break;
      }
    }
    //console.log(ret);
    return ret;
  },

  isEmptyObj(object) {
    return JSON.stringify(object) === '{}';
  },

  isCategoryIncome(guid) {
    //return true if category is income
    const categoryStore = useCategoryStore();
    const allKontos = categoryStore.allKontos;
    for (const key in allKontos) {
      if (key === guid) {
        return allKontos[key].type;
      }
    }
  },

  getIdKontoObj(id) {
    if (!id) return null;
    let ret = null;
    const categoryStore = useCategoryStore();
    const allKontos = categoryStore.allKontos;

    for (const key in allKontos) {
      if (allKontos[key].id === id) {
        ret = {};
        ret.guid = key;
        ret.description = allKontos[key].description;
        ret.lvl = allKontos[key].lvl;
        ret.guid_parent = allKontos[key].guid_parent;
        break;
      }
    }
    //console.log(ret);
    return ret;
  },

  getMonth(yearMonth) {
    return yearMonth.split('-')[1];
  },

  getYear(yearMonth) {
    return yearMonth.split('-')[0];
  },

  getSumofTwoNumbers(a, b) {
    return Math.round((a + b + Number.EPSILON) * 100) / 100;
  },

  fixCurrency(a, decimals = 2) {
    if (arguments.length > 1) {
      var tmpNumber = Math.pow(10, decimals);
      //a = a.toFixed(decimals);
      //console.log(a);

      return Math.round((a + Number.EPSILON) * tmpNumber) / tmpNumber;

      // var tmpNumber = Math.pow(10, decimals + 1);
      // return Math.round((a + Number.EPSILON) * tmpNumber) / tmpNumber;
    } else return Math.round((a + Number.EPSILON) * 100) / 100;
  },

  sortByKey(array, key) {
    // console.log(array);
    // console.log(key);

    return array.sort(function (a, b) {
      var x = a[key];
      var y = b[key];

      if (typeof x == 'boolean') {
        //we have to make boolean value as number otherwise
        //this sorting function doesn't work for true/false values
        //0 and 1 dont't work, that is why is 1 and 2
        if (x) x = 1;
        else x = 2;

        if (y) y = 1;
        else y = 2;

        //console.log(`boolean | x=${x} | y=${y}`);
      }

      if (typeof x == 'number') {
        if (y === null) y = 1000000000;
      }

      if (!x && x != 0) {
        x = 'ŽŽŽŽŽŽ';
      }

      if (!y && y != 0) {
        y = 'ŽŽŽŽŽŽ';
      }

      if (typeof x == 'string') {
        x = ('' + x).toLowerCase();
      }
      if (typeof y == 'string') {
        y = ('' + y).toLowerCase();
      }

      if (x === y) {
        return 0;
      }

      // nulls sort after anything else
      if (x === null || typeof x == 'undefined') {
        return 1;
      }
      if (y === null || typeof y == 'undefined') {
        return -1;
      }

      return x < y ? -1 : x > y ? 1 : 0;

      // if (reverseSort) {
      //   return x < y ? 1 : x > y ? -1 : 0;
      // } else return x < y ? -1 : x > y ? 1 : 0;
    });
  },

  sortByKey1(a, b, field, ascDesc) {
    var x = a[field];
    var y = b[field];
    if (typeof a[field] == 'string' && typeof b[field] == 'string') {
      x = x.toLocaleLowerCase();
      y = y.toLocaleLowerCase();
    }

    if (ascDesc == 'asc') {
      return x < y ? -1 : x > y ? 1 : 0;
    } else {
      return x < y ? 1 : x > y ? -1 : 0;
    }
  },

  getObjectCopy(parArray) {
    return JSON.parse(JSON.stringify(parArray));
  },

  deleteNode(url) {
    let promise = new Promise(function (resolve, reject) {
      try {
        const db = getDatabase();
        //let userUid = getAuth().currentUser.uid;

        if (url.includes('{uid}'))
          url = url.replace('{uid}', getAuth().currentUser.uid);

        let updates = {};
        updates[url] = null;

        update(reffb(db), updates)
          .then(() => {
            resolve();
          })
          .catch((error) => {
            error.value = error.message;
            this.conlog(error.message);
            reject(new Error(error));
          })
          .finally(console.log(url + ' DELETED!'));

        //`balance_acc/${userUid}/
      } catch (error) {
        reject(new Error(error.message));
      }
    });

    return promise;
  },

  getAnalysisYearMonth(yearMonth, yearly = false) {
    let promise = new Promise(function (resolve, reject) {
      try {
        const firebaseConfig = config.global().firebaseConfig;

        // Initialize Firebase
        const app = initializeApp(firebaseConfig);
        const db = getDatabase(app);
        const auth = getAuth();
        let currentUserUid = auth.currentUser.uid;

        //console.log(`balance_konto/${currentUserUid}/${yearMonth}`);

        let url = `balance_konto/${currentUserUid}/${yearMonth}`;
        // console.log('arguments.length', arguments.length);
        // console.log('guidKonto', guidKonto);

        // if (guidKonto)
        //   url = `balance_konto/${currentUserUid}/${yearMonth}/${guidKonto}`;

        get(child(reffb(db), url))
          .then((snapshot) => {
            if (snapshot.exists()) {
              //console.log(snapshot.val());
              // if (guidKonto) {
              //   let tmpObj = {};
              //   tmpObj[guidKonto] = snapshot.val();
              //   resolve({ snapshot: tmpObj, yearMonth, yearly });
              // } else {
              //   resolve({ snapshot: snapshot.val(), yearMonth, yearly });
              // }
              resolve({ snapshot: snapshot.val(), yearMonth, yearly });
            } else {
              resolve({ snapshot: null, message: 'No data available.' });
              //console.log('No data available.');
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } catch (error) {
        reject(new Error(error.message));
      }
    });

    return promise;
  },

  getAnalysisRawData(year, guidCategory1) {
    let promise = new Promise(function (resolve, reject) {
      try {
        const firebaseConfig = config.global().firebaseConfig;

        // Initialize Firebase
        const app = initializeApp(firebaseConfig);
        const db = getDatabase(app);
        const auth = getAuth();
        let currentUserUid = auth.currentUser.uid;

        console.log(guidCategory1);
        //console.log(`balance_konto/${currentUserUid}/${yearMonth}`);

        let url = `transactions/${currentUserUid}/${year}`;
        let maxRecords = 500;
        let que = query(
          reffb(db, url),
          orderByChild('guidknt1'),
          limitToLast(maxRecords),
          equalTo(guidCategory1)
        );

        get(que)
          .then((snapshot) => {
            // get(child(reffb(db), `transactions/${currentUserUid}/${year}`))
            //   .then((snapshot) => {
            if (snapshot.exists()) {
              //console.log(snapshot.val());
              resolve({ snapshot: snapshot.val(), year });
            } else {
              resolve({ snapshot: null, message: 'No data available.' });
              //console.log('No data available.');
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } catch (error) {
        reject(new Error(error.message));
      }
    });

    return promise;
  },

  prepareAnalysis(
    responseData,
    tmpSum,
    sumIncomes,
    sumExpenses,
    selectedKontos
  ) {
    let tmpArr = [];
    let addToSum = false;
    const props = ['balance', 'lvl', 'description'];

    for (const key in responseData) {
      const item1 = {};
      item1.guid = key;
      for (let i = 0; i < props.length; i++) {
        const categoryObj = this.getGuidKontoObj(key);
        item1[props[i]] = responseData[key][props[i]];
        item1.lvl = categoryObj.lvl;
        item1.type = categoryObj.type;

        item1.description = categoryObj.description;
        item1.guid_parent = categoryObj.guid_parent
          ? categoryObj.guid_parent
          : null;
      }

      if (item1.lvl === 1) {
        addToSum = false;

        if (selectedKontos.length > 0) {
          if (selectedKontos.includes(item1.guid)) {
            addToSum = true;
          }
        } else {
          addToSum = true;
        }

        if (addToSum) {
          tmpSum += item1.balance;
          tmpSum = Math.round((tmpSum + Number.EPSILON) * 100) / 100;

          if (item1.type === true) {
            sumIncomes += item1.balance;
            sumIncomes = Math.round((sumIncomes + Number.EPSILON) * 100) / 100;
          } else {
            sumExpenses += item1.balance;
            sumExpenses =
              Math.round((sumExpenses + Number.EPSILON) * 100) / 100;
          }
        }
      }
      tmpArr.push(item1);
    }

    return {
      arr: tmpArr,
      sum: tmpSum,
      sumIncomesReturn: sumIncomes,
      sumExpensesReturn: sumExpenses,
    };
  },

  getSearchQue(params) {
    let { searchObj, que, url, maxRecords, db } = params;

    // console.log('searchObj');
    // console.log(searchObj);
    // console.log('url');
    // console.log(url);
    // console.log('maxRecords');
    // console.log(maxRecords);
    // console.log('db');
    // console.log(db);

    if (!searchObj.searchTermin) searchObj.searchTermin = this.getCurrentYear();
    // if (!searchObj.searchTermin)
    //   searchObj.searchTermin = this.getCurrentYearMonth();

    if (searchObj.searchTermin) {
      url += '/' + searchObj.searchTermin.substr(0, 4);
      // console.log('getSearchQue->url');
      // console.log(url);

      //console.log('here 1');

      que = query(
        reffb(db, url),
        orderByChild('termin'),
        limitToLast(maxRecords),
        startAt(searchObj.searchTermin),
        endAt(searchObj.searchTermin)
      );

      if (searchObj.searchTermin.length == 4) {
        //we search for all year

        console.log('here 2');
        maxRecords = 2500;
        que = query(
          reffb(db, url),
          orderByChild('termin'),
          limitToLast(maxRecords),
          startAt(searchObj.searchTermin + '-01'),
          endAt(searchObj.searchTermin + '-12')
        );
      }

      // if (searchObj.searchTermin.length == 7) {
      //   //we search for specific year-month for instance 2023-04
      //   console.log('here 3');
      //   que = query(
      //     reffb(db, url),
      //     orderByChild('termin'),
      //     limitToLast(maxRecords),
      //     equalTo(searchObj.searchTermin)
      //   );
      // }

      if (searchObj.searchKonto1) {
        //console.log('here 4');
        que = query(
          reffb(db, url),
          orderByChild('guidknt1'),
          limitToLast(maxRecords),
          equalTo(searchObj.searchKonto1)
        );
      }

      // if (searchObj.searchDesc) {
      //   que = query(
      //     reffb(db, url),
      //     orderByChild('search'),
      //     limitToLast(maxRecords),
      //     startAt(searchObj.searchDesc.toLowerCase()),
      //     endAt(searchObj.searchDesc.toLowerCase() + '\uf8ff')
      //   );
      // }
    } else if (searchObj.searchDesc) {
      console.log('else if (searchObj.searchDesc)');
      //console.log(payload.searchDesc.toLowerCase() + 'z');

      // que = query(
      //   ref(db, url),
      //   orderByChild('description'),
      //   limitToLast(100),
      //   startAt(payload.searchDesc),
      //   endAt(payload.searchDesc)
      // );

      //endAt(payload.searchDesc.toLowerCase() + '\uf8ff');

      // que = query(
      //   reffb(db, url),
      //   orderByChild('search'),
      //   limitToLast(maxRecords),
      //   startAt(searchObj.searchDesc.toLowerCase()),
      //   endAt(searchObj.searchDesc.toLowerCase() + '\uf8ff')
      // );
    }

    return que;
  },

  getArrayWithGuid(responseData, props) {
    let retArray = [];
    for (const key in responseData) {
      const item1 = {};
      item1.guid = key;
      for (let i = 0; i < props.length; i++) {
        if (typeof responseData[key][props[i]] == 'undefined') {
          item1[props[i]] = null;
        } else item1[props[i]] = responseData[key][props[i]];
      }
      retArray.push(item1);
    }
    return retArray;
  },

  getReplaceUndefined(retArray, props) {
    for (let i = 0; i < retArray.length; i++) {
      for (let j = 0; j < props.length; j++) {
        if (typeof retArray[i][props[j]] == 'undefined') {
          retArray[i][props[j]] = null;
        }
      }
    }
    return retArray;
  },

  getFilterTrans(tmpArray, searchObjectField, arrayObjectField) {
    //tmpArray = appUtils.getFilterTrans(tmpArray, searchObject, 'searchKonto1', 'guidknt1')

    // console.log('searchObjectField' + searchObjectField);

    if (searchObjectField) {
      // console.log(
      //   'appUtils.getFilterTrans par[arrayObjectField] => ' + arrayObjectField
      // );

      // console.log(
      //   'appUtils.getFilterTrans par[searchObjectField] => ' + searchObjectField
      // );

      tmpArray = tmpArray.filter(
        (el) => el[arrayObjectField] === searchObjectField
      );
    }
    return tmpArray;
  },

  getCurrencySymbol() {
    const settingStore = useSettingStore();
    let currencySymbol = settingStore.currencySymbol;
    if (!currencySymbol || currencySymbol == 'none') currencySymbol = '';
    return currencySymbol;
  },

  getCurrencySymbolOnly() {
    const currency = this.getCurrencySymbol();
    if (!currency || currency == 'none' || currency == '') return '';
    const locale = 'de-DE';

    let lcCurrencySymbol = (0)
      .toLocaleString(locale, {
        style: 'currency',
        currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      .replace(/\d/g, '')
      .trim();
    return lcCurrencySymbol;
  },

  formatCurrency(parNumber, addSymbol = true) {
    if (!parNumber) return 0;

    if (typeof parNumber == 'string') {
      // console.log(parNumber);
      // console.log(typeof parNumber);
      parNumber = parseFloat(parNumber);
    }

    const currencySymbol = this.getCurrencySymbol();
    if (!currencySymbol || currencySymbol == 'none' || currencySymbol == '')
      addSymbol = false;

    if (addSymbol) {
      return parNumber.toLocaleString('de-DE', {
        style: 'currency',
        currency: currencySymbol,
        minimumFractionDigits: 2,
      });
    } else {
      return parNumber.toLocaleString('de-DE', {
        minimumFractionDigits: 2,
      });
    }
  },

  getKontoDescFull(guidknt1, guidknt2, guidknt3, guidknt4) {
    let ret = '';
    if (guidknt1) ret += this.getGuidKontoDesc(guidknt1);
    if (guidknt2) ret += ' / ' + this.getGuidKontoDesc(guidknt2);
    if (guidknt3) ret += ' / ' + this.getGuidKontoDesc(guidknt3);
    if (guidknt4) ret += ' / ' + this.getGuidKontoDesc(guidknt4);
    return ret;
  },

  getGuidKontoDesc(guid) {
    return this.getGuidKontoObj(guid).description;
  },

  getDateAsString(date) {
    // console.log(typeof date);

    if (date instanceof Date) {
      console.log(date);
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2); // add leading zero if month is less than 10
      const day = ('0' + date.getDate()).slice(-2); // add leading zero if day is less than 10
      const formattedDate = year + '-' + month + '-' + day; // format the date as a string
      return formattedDate;
    } else {
      console.log('getDateAsString not DATE');
      return date;
    }
  },

  getCopyOfObject(array, guidValue, guidFieldName) {
    let saveObj = null;
    let foundObject = array.value.find(
      (obj) => obj[guidFieldName] === guidValue
    );
    if (foundObject) saveObj = this.getObjectCopy(foundObject);
    return saveObj;
  },

  fastFilterGeneral(searchText, fieldArr, e) {
    // console.log('fastFilterGeneral');
    // console.log(e);
    // console.log(searchText);
    // console.log(fieldArr);

    let exactFlag = false;
    if (searchText.includes('#EXACT#')) {
      searchText = searchText.replace('#EXACT#', '');
      exactFlag = true;
    }

    let tmpret = false;
    for (var i = 0; i < fieldArr.length; i++) {
      if (e[fieldArr[i]]) {
        if (
          e[fieldArr[i]].toUpperCase().indexOf(searchText.toUpperCase()) !== -1
        ) {
          if (exactFlag == true) {
            if (e[fieldArr[i]].toUpperCase() == searchText.toUpperCase()) {
              tmpret = true;
              break;
            }
          } else {
            tmpret = true;
            break;
          }
        }
      }
    }

    // console.log(tmpret);
    // console.log('============================');

    return tmpret;
  },

  // fastFilter(tmp, searchText) {
  //   console.log('appUtils.fastFilter');
  //   console.log(tmp);

  //   return tmp.filter(function (e) {
  //     return (
  //       (e.description
  //         ? e.description.toUpperCase().indexOf(searchText.toUpperCase()) !== -1
  //         : false) ||
  //       (e.accDesc
  //         ? e.accDesc.toUpperCase().indexOf(searchText.toUpperCase()) !== -1
  //         : false) ||
  //       (e.kontoDesc
  //         ? e.kontoDesc.toUpperCase().indexOf(searchText.toUpperCase()) !== -1
  //         : false) ||
  //       (e.remark1
  //         ? e.remark1.toUpperCase().indexOf(searchText.toUpperCase()) !== -1
  //         : false)
  //     );
  //   });
  // },

  getAllMonthsOfYear(year, yearMonthArr) {
    //generated by AI
    for (let month = 1; month <= 12; month++) {
      // Append leading zero if month is single digit
      const formattedMonth = month < 10 ? `0${month}` : month;

      // if year month already exist in array we don't add it
      const index = yearMonthArr.indexOf(`${year}-${formattedMonth}`);
      if (index === -1) yearMonthArr.push(`${year}-${formattedMonth}`);
    }
    return yearMonthArr;
  },

  getYearsBetween(year1, year2) {
    //generated by AI
    const years = [];
    for (let year = year1; year <= year2; year++) {
      years.push(year);
    }
    return years;
  },

  setParametersTransactionItem(configComponent, props) {
    configComponent.value.editVisible = true;
    configComponent.value.checkboxVisible = true;
    configComponent.value.deleteVisible = true;

    if (props.configComponent != undefined) {
      if (props.configComponent.editVisible != undefined) {
        configComponent.value.editVisible = props.configComponent.editVisible;
        //   console.log('editVisible');
      }

      if (props.configComponent.checkboxVisible != undefined) {
        configComponent.value.checkboxVisible =
          props.configComponent.checkboxVisible;
        //   console.log('checkboxVisible');
      }

      if (props.configComponent.deleteVisible != undefined) {
        configComponent.value.deleteVisible =
          props.configComponent.deleteVisible;
        //   console.log('deleteVisible');
      }
    }
  },

  showToast(
    txt,
    type = 'info',
    timeout = 2000,
    position = 'top-center',
    transition = 'slide'
  ) {
    toast(txt, {
      theme: 'dark',
      type: type,
      position: position,
      autoClose: timeout,
      transition: transition,
      dangerouslyHTMLString: true,
    });
  },

  formatTextForDialog(parText, parCaption, isDate = false, isCurrency = false) {
    // console.log(parText);
    // console.log(parCaption);
    // console.log(isDate);
    // console.log(isCurrency);

    let ret = '';

    if (isDate) {
      ret = parText
        ? '<span class="text-warning">' +
          parCaption +
          ':</span> ' +
          this.getFormatedDate(parText) +
          '<br />'
        : '';
    } else if (isCurrency) {
      ret = parText
        ? '<span class="text-warning">' +
          parCaption +
          ':</span> ' +
          this.formatCurrency(parText) +
          '<br />'
        : '';
    } else {
      ret = parText
        ? '<span class="text-warning">' +
          parCaption +
          ':</span> ' +
          parText +
          '<br />'
        : '';
    }
    // console.log(ret);
    return ret;
  },

  getTmp(line) {
    var tmp = [];
    var char1 = '';
    var word0 = '';
    var word = '';
    var finalWord = '';
    var start = false;

    for (var j = 0; j < line.length; j++) {
      char1 = line.substr(j, 1);
      word0 += char1;

      if (char1 === ',' && start === false) {
        word0 = word0.replace('"', '');
        finalWord = word0.replace(',', '');
        word0 = '';
        if (finalWord.length > 0) {
          tmp.push(finalWord);
        }
      }

      if (char1 === '"' && start === false) start = true;
      if (start === true) word = word + char1;

      if (word !== '"' && char1 === '"' && start === true) {
        start = false;
        word = word.replace('"', '');
        //finalWord = word.replace('/', '');
        finalWord = word.replace('"', '');
        tmp.push(finalWord);
        word = '';
        word0 = '';
      }
    }

    return tmp;
  },
};
