<template>
  <div>
    <section class="section" id="home-section">
      <div class="grid-item" style="margin-bottom: 10px">
        <img src="./images/img1.png" alt="" />
      </div>

      <div class="grid-item" style="margin-bottom: 10px; margin-top: 10px">
        <div class="exampleText">
          <p>OLL and PLL is vertical view</p>

          <p>OLL: The black bars and square represent the yellow pieces</p>
          <p>PLL: The black bars represent the samecolored pieces</p>
        </div>
        <div>
          <a href="#f2l-section">Go to F2L</a><br />
          <a href="#oll-section">Go to OLL</a><br />
          <a href="#pll-section">Go to PLL</a>
        </div>
      </div>

      <div class="grid-container7">
        <div class="grid-item">
          <img src="./images/img2.png" alt="" />
        </div>
        <div class="grid-item">
          <img src="./images/img3.png" alt="" />
        </div>
        <div class="grid-item">
          <img src="./images/img4.png" alt="" />
        </div>
        <div class="grid-item">
          <img src="./images/img5.png" alt="" />
        </div>
        <div class="grid-item">
          <img src="./images/img6.png" alt="" />
        </div>
        <div class="grid-item">
          <img src="./images/img7.png" alt="" />
        </div>
        <div class="grid-item">
          <img src="./images/img8.png" alt="" />
        </div>
        <div class="grid-item">
          <img src="./images/img9.png" alt="" />
        </div>
        <div class="grid-item">
          <img src="./images/img10.png" alt="" />
        </div>
        <div class="grid-item">
          <img src="./images/img11.png" alt="" />
        </div>
        <div class="grid-item">
          <img src="./images/img12.png" alt="" />
        </div>
        <div class="grid-item">
          <img src="./images/img13.png" alt="" />
        </div>
        <div class="grid-item">
          <img src="./images/img14.png" alt="" />
        </div>
        <div class="grid-item">
          <img src="./images/img15.png" alt="" />
        </div>
        <div class="grid-item">
          <img src="./images/img16.png" alt="" />
        </div>
        <div class="grid-item">
          <img src="./images/img17.png" alt="" />
        </div>
        <div class="grid-item">
          <img src="./images/img18.png" alt="" />
        </div>
        <div class="grid-item">
          <img src="./images/img19.png" alt="" />
        </div>
        <div class="grid-item">
          <img src="./images/img20.png" alt="" />
        </div>
        <div class="grid-item">
          <img src="./images/img21.png" alt="" />
        </div>
        <div class="grid-item">
          <img src="./images/img22.png" alt="" />
        </div>
      </div>
      <div class="">
        <div
          class="grid-item exampleTextNormal"
          style="margin-bottom: 10px; margin-top: 10px"
        >
          <p>
            Lowercase: Turn 2 Layers,
            <b>x (R), y (U), z (F)</b>: Whole rotation<br />
            Letter+ &#8216;:Turn 90% counterclockwise<br />
            Letter+2: Turn 180% clockwise<br />
            Letter+'2: Turn 180% counterclockwise
          </p>
        </div>
        <div class="grid-item" style="margin-bottom: 10px">
          <p class="">
            <span style="color: #0075ce">Blue font: By left index finger</span
            ><br />
            <span style="color: #e50012">Red font: By right thumb</span><br />
            <span style="color: #ef8200"
              >Orange font: By right ring finger</span
            >
          </p>
        </div>
        <div class="grid-item exampleTextNormal" style="margin-bottom: 10px">
          <p class="">
            (): A series of movements<br />
            ()+2: Repeat the same series of movements once. e.g<br />
            (R U R'
            <span class="mainColor2">U'</span>)2=(RU R'
            <span class="mainColor2">U'</span>)(R U R'
            <span class="mainColor2">U'</span>)
          </p>
        </div>
      </div>
    </section>

    <!-- qwer F2L -->
    <!-- F2L new -->
    <section class="section" id="f2l-section" style="margin-bottom: 60px">
      <div class="grid-item" style="margin-bottom: 10px">
        <div style="margin-bottom: -5px">
          <div id="btn-showAll-F2L" class="buy" @click="showF2LAll()">
            <span class="white-text" rel="noopener noreferrer">Show All</span>
          </div>
        </div>

        <div style="margin-bottom: -5px">
          <div
            style="margin-right: 2px"
            id="btn-bothDown-F2L"
            class="buy"
            @click="showBothDown()"
          >
            <span class="white-text" rel="noopener noreferrer">Both down</span>
          </div>

          <div
            style="margin-right: 2px"
            id="btn-upAndMid-F2L"
            class="buy"
            @click="showUpAndMid()"
          >
            <span class="white-text" rel="noopener noreferrer">Up & Mid</span>
          </div>

          <div
            style="margin-right: 2px"
            id="btn-upAndDown-F2L"
            class="buy"
            @click="showUpAndDown()"
          >
            <span class="white-text" rel="noopener noreferrer">Up & down</span>
          </div>

          <div id="btn-bothUp-F2L" class="buy" @click="showBothUp()">
            <span class="white-text" rel="noopener noreferrer">Both up</span>
          </div>
        </div>

        <div style="margin-bottom: -5px">
          <div
            style="width: 240px; margin-right: 2px"
            id="btn-bothUpWhiteUp-F2L"
            class="buy"
            @click="showBothUpWhiteUp()"
          >
            <!-- <a class="white-text" rel="noopener noreferrer"
                            >Both up-White up</a
                          > -->
            <span class="white-text" rel="">Both up-White up</span>
          </div>
          <div
            style="width: 240px; margin-right: 2px"
            id="btn-bothUpWhiteFront-F2L"
            class="buy"
            @click="showBothUpWhiteFront()"
          >
            <span class="white-text" rel="noopener noreferrer"
              >Both up-White front</span
            >
          </div>
          <div
            style="width: 240px"
            id="btn-bothUpWhiteSide-F2L"
            class="buy"
            @click="showBothUpWhiteSide()"
          >
            <span class="white-text" rel="noopener noreferrer"
              >Both up-White right</span
            >
          </div>
        </div>

        <div style="margin-bottom: -5px"></div>

        <div style="margin-bottom: 10px">
          <div
            style="width: 240px; margin-right: 2px"
            id="btn-bothUp-whiteUp-block-F2L"
            class="buy"
            @click="showBothUpWhiteUpBlock()"
          >
            <span class="white-text" rel="noopener noreferrer"
              >Up-White up(blc)</span
            >
          </div>

          <div
            style="width: 240px; margin-right: 2px"
            id="btn-bothUp-whiteFront-block-F2L"
            class="buy"
            @click="showBothUpWhiteFrontBlock()"
          >
            <span class="white-text" rel="noopener noreferrer"
              >Up-White front(blc)</span
            >
          </div>

          <div
            style="width: 240px"
            id="btn-bothUp-whiteRight-block-F2L"
            class="buy"
            @click="showBothUpWhiteRightBlock()"
          >
            <span class="white-text" rel="noopener noreferrer"
              >Up-White right(blc)</span
            >
          </div>
        </div>
      </div>

      <div class="grid-container">
        <div class="grid-item">
          <div class="exampleText">
            <h2 class="fsize24 mainColor"><b>F2L</b></h2>
            <p>First 2 Layers</p>
            <p>
              <b>[SX] = R U R'<span class="mainColor2"> U'</span></b>
            </p>
            <p>
              <b>[sh] = R' F R F'<span class="mainColor2"> </span></b>
            </p>
            <a href="#home-section">Go to the top</a><br />
            <a href="#oll-section">Go to OLL</a><br />
            <a href="#pll-section">Go to PLL</a>
          </div>
        </div>

        <!-- qwer F2L -->

        <div id="F2L-01-DIV" class="grid-item">
          <div class="grid-title">01</div>

          <div id="F2L-01-IMG" class="exampleImg">
            <img src="./images/img28.png" alt="" />
          </div>
          <p id="F2L-01-P" class="exampleText">
            (R<span class="mainColor2">U'</span>R'U)y'(R'U2R<span
              class="mainColor3"
              >U'</span
            ><span class="mainColor2">U'</span>)(R'UR)<br /><br /><b
              ><span class="scramble"> SC: </span></b
            >01
          </p>
        </div>
        <div id="F2L-02-DIV" class="grid-item">
          <div class="grid-title">02</div>
          <div id="F2L-02-IMG" class="exampleImg">
            <img src="./images/img34.png" alt="" />
          </div>
          <p id="F2L-02-P" class="exampleText">
            (UR<span class="mainColor2">U'</span>R'<span class="mainColor2"
              >U'</span
            >)y'(R'UR)<br /><br /><b><span class="scramble"> SC: </span></b
            ><br />F'U'FU[SX]
          </p>
        </div>
        <div id="F2L-03-DIV" class="grid-item">
          <div class="grid-title">03</div>
          <div id="F2L-03-IMG" class="exampleImg">
            <img src="./images/img40.png" alt="" />
          </div>
          <p id="F2L-03-P" class="exampleText">
            (R'<span class="mainColor3">F'</span>RU)(R<span class="mainColor2"
              >U'</span
            >R'F)<br /><br /><span class="scramble_orange"> SC: 03+U</span>
          </p>
        </div>
        <div id="F2L-04-DIV" class="grid-item">
          <div class="grid-title">04</div>
          <div id="F2L-04-IMG" class="exampleImg">
            <img src="./images/img46.png" alt="" />
          </div>
          <p id="F2L-04-P" class="exampleText">
            [SX](RU2R'<span class="mainColor3">U'</span>)(RUR')<br /><br /><span
              class="scramblok"
            >
              SC: 08</span
            >
          </p>
        </div>
        <div id="F2L-05-DIV" class="grid-item">
          <div class="grid-title">05</div>
          <div id="F2L-05-IMG" class="exampleImg">
            <img src="./images/img52.png" alt="" />
          </div>
          <p id="F2L-05-P" class="exampleText">
            (RF)U(R<span class="mainColor2">U'</span>R'<span class="mainColor3"
              >F'</span
            >)(<span class="mainColor2">U'</span>R')<br /><br /><b
              ><span class="scramble"> SC: </span></b
            >09
          </p>
        </div>
        <div id="F2L-06-DIV" class="grid-item">
          <div class="grid-title">06</div>
          <div id="F2L-06-IMG" class="exampleImg">
            <img src="./images/img58.png" alt="" />
          </div>
          <p id="F2L-06-P" class="exampleText">
            y'(R'<span class="mainColor2">U'</span>RU)(R'<span
              class="mainColor2"
              >U'</span
            >R)<br /><br /><b><span class="scramble"> SC: </span></b>10
          </p>
        </div>
        <div id="F2L-07-DIV" class="grid-item">
          <div class="grid-title">07</div>
          <div id="F2L-07-IMG" class="exampleImg">
            <img src="./images/img23.png" alt="" />
          </div>
          <p id="F2L-07-P" class="exampleText">
            (R<span class="mainColor2">U'</span>R'U)(R<span class="mainColor2"
              >U'</span
            >R')<br /><br /><b><span class="scramble"> SC: </span></b>11
          </p>
        </div>
        <div id="F2L-08-DIV" class="grid-item">
          <div class="grid-title">08</div>
          <div id="F2L-08-IMG" class="exampleImg">
            <img src="./images/img29.png" alt="" />
          </div>
          <p id="F2L-08-P" class="exampleText">
            (R<span class="mainColor2">U'</span>R'U)(R<span class="mainColor3"
              >U'</span
            ><span class="mainColor2">U'</span>R'U)(R<span class="mainColor2"
              >U'</span
            >R')<br /><br /><b><span class="scramble"> SC: </span></b>04
          </p>
        </div>
        <div id="F2L-09-DIV" class="grid-item">
          <div class="grid-title">09</div>
          <div id="F2L-09-IMG" class="exampleImg">
            <img src="./images/img35.png" alt="" />
          </div>
          <p id="F2L-09-P" class="exampleText">
            RUF<br />[SX]<br /><span class="mainColor3">F'</span
            >R'<br /><br /><b><span class="scramble"> SC: </span></b>05
          </p>
        </div>
        <div id="F2L-10-DIV" class="grid-item">
          <div class="grid-title">10</div>
          <div id="F2L-10-IMG" class="exampleImg">
            <img src="./images/img41.png" alt="" />
          </div>
          <p id="F2L-10-P" class="exampleText">
            y'(R'UR<span class="mainColor2">U'</span>)(R'UR)<br /><br /><b
              ><span class="scramble"> SC: </span></b
            >06
          </p>
        </div>
        <div id="F2L-11-DIV" class="grid-item">
          <div class="grid-title">11</div>
          <div id="F2L-11-IMG" class="exampleImg">
            <img src="./images/img47.png" alt="" />
          </div>
          <p id="F2L-11-P" class="exampleText">
            [SX](RUR')<br /><br /><b><span class="scramble"> SC: </span></b>07
          </p>
        </div>
        <div id="F2L-12-DIV" class="grid-item">
          <div class="grid-title">12</div>
          <div id="F2L-12-IMG" class="exampleImg">
            <img src="./images/img53.png" alt="" />
          </div>
          <p id="F2L-12-P" class="exampleText">
            [SX]2(RUR')<br /><br /><b><span class="scramble"> SC: </span></b
            >[SX]3
          </p>
        </div>
        <div id="F2L-13-DIV" class="grid-item">
          <div class="grid-title">13</div>
          <div id="F2L-13-IMG" class="exampleImg">
            <img src="./images/img59.png" alt="" />
          </div>
          <p id="F2L-13-P" class="exampleText">
            (R<span class="mainColor2">U'</span>R'U)y'(R'UR) <br /><br /><b
              ><span class="scramble"> SC: </span></b
            >23, 33
          </p>
        </div>
        <div id="F2L-14-DIV" class="grid-item">
          <div class="grid-title">14</div>
          <div id="F2L-14-IMG" class="exampleImg">
            <img src="./images/img24.png" alt="" />
          </div>
          <p id="F2L-14-P" class="exampleText">
            y'(R'U2)[SX] R<br /><br /><b><span class="scramble"> SC: </span></b
            ><br />R' [URU'R'] U2 R
          </p>
        </div>
        <div id="F2L-15-DIV" class="grid-item">
          <div class="grid-title">15</div>
          <div id="F2L-15-IMG" class="exampleImg">
            <img src="./images/img30.png" alt="" />
          </div>
          <p id="F2L-15-P" class="exampleText">
            y'<span class="mainColor2">U'</span>(R'U2)(R<span class="mainColor2"
              >U'</span
            >R'UR)<br /><br /><b><span class="scramble"> SC: </span></b
            ><br />R'U' [SX] U'RU
          </p>
        </div>
        <div id="F2L-16-DIV" class="grid-item">
          <div class="grid-title">16</div>
          <div id="F2L-16-IMG" class="exampleImg">
            <img src="./images/img36.png" alt="" />
          </div>
          <p id="F2L-16-P" class="exampleText">
            y'(R'UR<span class="mainColor3">U'</span
            ><span class="mainColor2">U'</span>)(R'<span class="mainColor2"
              >U'</span
            >R)<br /><br /><b><span class="scramble"> SC: </span></b>16, 36
          </p>
        </div>
        <div id="F2L-17-DIV" class="grid-item">
          <div class="grid-title">17</div>
          <div id="F2L-17-IMG" class="exampleImg">
            <img src="./images/img42.png" alt="" />
          </div>
          <p id="F2L-17-P" class="exampleText">
            FU(R<span class="mainColor2">U'</span>R'<span class="mainColor3"
              >F'</span
            >)(R<span class="mainColor2">U'</span>R')<br /><br /><b
              ><span class="scramble"> SC: </span></b
            ><br />RUR' F[SX]F'
          </p>
        </div>
        <div id="F2L-18-DIV" class="grid-item">
          <div class="grid-title">18</div>
          <div id="F2L-18-IMG" class="exampleImg">
            <img src="./images/img48.png" alt="" />
          </div>
          <p id="F2L-18-P" class="exampleText">
            U(R<span class="mainColor2">U'</span>R'<span class="mainColor2"
              >U'</span
            >)(R<span class="mainColor2">U'</span>R'U)(R<span class="mainColor2"
              >U'</span
            >R')<br /><br /><b><span class="scramble"> SC: </span></b
            >38<br />[SX](RUR'U)[SX]
          </p>
        </div>
        <div id="F2L-19-DIV" class="grid-item">
          <div class="grid-title">19</div>
          <div id="F2L-19-IMG" class="exampleImg">
            <img src="./images/img54.png" alt="" />
          </div>
          <p id="F2L-19-P" class="exampleText">
            (R<span class="mainColor2">U'</span>R'U2)(RUR') <br /><br /><b
              ><span class="scramble"> SC: </span></b
            >19, 29
          </p>
        </div>
        <div id="F2L-20-DIV" class="grid-item">
          <div class="grid-title">20</div>
          <div id="F2L-20-IMG" class="exampleImg">
            <img src="./images/img60.png" alt="" />
          </div>
          <p id="F2L-20-P" class="exampleText">
            U(R<span class="mainColor2">U'</span
            ><span class="mainColor3">U'</span>)(R'UR<span class="mainColor2"
              >U'</span
            >R')<br /><br /><b><span class="scramble"> SC: </span></b>
            [SX](R<span class="mainColor3">U'</span
            ><span class="mainColor2">U'</span>R')<span class="mainColor2"
              >U'</span
            >
          </p>
        </div>
        <div id="F2L-21-DIV" class="grid-item">
          <div class="grid-title">21</div>
          <div id="F2L-21-IMG" class="exampleImg">
            <img src="./images/img25.png" alt="" />
          </div>
          <p id="F2L-21-P" class="exampleText">
            (R<span class="mainColor3">U'</span
            ><span class="mainColor2">U'</span>)(R'<span class="mainColor2"
              >U'</span
            >RUR')<br /><br /><b><span class="scramble"> SC: </span></b
            ><br />RU'R'U RU2R'
          </p>
        </div>
        <div id="F2L-22-DIV" class="grid-item">
          <div class="grid-title">22</div>
          <div id="F2L-22-IMG" class="exampleImg">
            <img src="./images/img31.png" alt="" />
          </div>
          <p id="F2L-22-P" class="exampleText">
            <span class="mainColor2">U'</span>(R<span class="mainColor2"
              >U'</span
            >R'U2)(R<span class="mainColor2">U'</span>R')<br /><br /><b
              ><span class="scramble"> SC: </span></b
            >32
          </p>
        </div>
        <div id="F2L-23-DIV" class="grid-item">
          <div class="grid-title">23</div>
          <div id="F2L-23-IMG" class="exampleImg">
            <img src="./images/img37.png" alt="" />
          </div>
          <p id="F2L-23-P" class="exampleText">
            <span class="mainColor2">U'</span>(RUR')y'(UR'<span
              class="mainColor2"
              >U'</span
            >R)<br /><br /><b><span class="scramble"> SC: </span></b
            ><br />F'U'FU RU'R'U2
          </p>
        </div>
        <div id="F2L-24-DIV" class="grid-item">
          <div class="grid-title">24</div>
          <div id="F2L-24-IMG" class="exampleImg">
            <img src="./images/img43.png" alt="" />
          </div>
          <p id="F2L-24-P" class="exampleText">
            y'U(R'UR<span class="mainColor2">U'</span>)(R'<span
              class="mainColor2"
              >U'</span
            >R)<br /><br /><b><span class="scramble"> SC: </span></b><br />y'
            R'U [SX] RU'
          </p>
        </div>
        <div id="F2L-25-DIV" class="grid-item">
          <div class="grid-title">25</div>
          <div id="F2L-25-IMG" class="exampleImg">
            <img src="./images/img49.png" alt="" />
          </div>
          <p id="F2L-25-P" class="exampleText">
            y'(R'<span class="mainColor2">U'</span>R)<br /><br /><b
              ><span class="scramble"> SC: </span></b
            >y'(R'UR)
          </p>
        </div>
        <div id="F2L-26-DIV" class="grid-item">
          <div class="grid-title">26</div>
          <div id="F2L-26-IMG" class="exampleImg">
            <img src="./images/img55.png" alt="" />
          </div>
          <p id="F2L-26-P" class="exampleText">
            y'U(R'<span class="mainColor2">U'</span>R<span class="mainColor2"
              >U'</span
            >)(R'<span class="mainColor2">U'</span>R)<br /><br /><b
              ><span class="scramble"> SC: </span></b
            >24
          </p>

          <p class="exampleText">
            y'(R'UR<span class="mainColor2">U'</span>)(R'<span
              class="mainColor2"
              >U'</span
            >R) U2
          </p>
        </div>
        <div id="F2L-27-DIV" class="grid-item">
          <div class="grid-title">27</div>
          <div id="F2L-27-IMG" class="exampleImg">
            <img src="./images/img61.png" alt="" />
          </div>
          <p id="F2L-27-P" class="exampleText">
            y'(R<span class="mainColor3">U'</span
            ><span class="mainColor2">U'</span>)(R'2<span class="mainColor2"
              >U'</span
            >)(R2<span class="mainColor2">U'</span>R')<br /><br /><b
              ><span class="scramble"> SC: </span></b
            ><br />y' RUR2U<br />R2U2R'
          </p>
        </div>
        <div id="F2L-28-DIV" class="grid-item">
          <div class="grid-title">28</div>
          <div id="F2L-28-IMG" class="exampleImg">
            <img src="./images/img26.png" alt="" />
          </div>
          <p id="F2L-28-P" class="exampleText">
            (lU)(r<span class="mainColor2">U'</span>r<span class="mainColor2"
              >&#8216;U'</span
            >)l'<br /><br /><b><span class="scramble"> SC: </span></b>37<br />
            (R<span class="mainColor2">U'</span>R'U2)y'(R'<span
              class="mainColor2"
              >U'</span
            >R)
          </p>
        </div>
        <div id="F2L-29-DIV" class="grid-item">
          <div class="grid-title">29</div>
          <div id="F2L-29-IMG" class="exampleImg">
            <img src="./images/img32.png" alt="" />
          </div>
          <p id="F2L-29-P" class="exampleText">
            <span class="mainColor2">U'</span>(R<span class="mainColor3"
              >U'</span
            ><span class="mainColor2">U'</span>)(R'U2)(R<span class="mainColor2"
              >U'</span
            >R')<br /><br /><b><span class="scramble"> SC: </span></b
            ><br />RUR'U2<br />RU2R'U
          </p>
        </div>
        <div id="F2L-30-DIV" class="grid-item">
          <div class="grid-title">30</div>
          <div id="F2L-30-IMG" class="exampleImg">
            <img src="./images/img38.png" alt="" />
          </div>
          <p id="F2L-30-P" class="exampleText">
            <span class="mainColor2">U'</span>[SX](R<span class="mainColor3"
              >U'</span
            ><span class="mainColor2">U'</span>R')<br /><br /><b
              ><span class="scramble"> SC: </span></b
            >18 <br />(R<span class="mainColor2">U'</span
            ><span class="mainColor3">U'</span>)(R'UR<span class="mainColor2"
              >U'</span
            >R')U
          </p>
        </div>
        <div id="F2L-31-DIV" class="grid-item">
          <div class="grid-title">31</div>
          <div id="F2L-31-IMG" class="exampleImg">
            <img src="./images/img44.png" alt="" />
          </div>
          <p id="F2L-31-P" class="exampleText">
            UR<span class="mainColor2">U'</span>R'<br /><br /><b
              ><span class="scramble"> SC: </span></b
            >[SX]
          </p>
        </div>
        <div id="F2L-32-DIV" class="grid-item">
          <div class="grid-title">32</div>
          <div id="F2L-32-IMG" class="exampleImg">
            <img src="./images/img50.png" alt="" />
          </div>
          <p id="F2L-32-P" class="exampleText">
            <span class="mainColor2">U'</span>(R<span class="mainColor3"
              >U'</span
            ><span class="mainColor2">U'</span>R'U)(RUR')<br /><br /><b
              ><span class="scramble"> SC: </span></b
            >22
          </p>
        </div>
        <div id="F2L-33-DIV" class="grid-item">
          <div class="grid-title">33</div>
          <div id="F2L-33-IMG" class="exampleImg">
            <img src="./images/img56.png" alt="" />
          </div>
          <p id="F2L-33-P" class="exampleText">
            y'U(R'<span class="mainColor2">U'</span>R)<span class="mainColor"
              >d'</span
            >(RUR')<br /><br /><b><span class="scramble"> SC: </span></b>13
          </p>
        </div>
        <div id="F2L-34-DIV" class="grid-item">
          <div class="grid-title">34</div>
          <div id="F2L-34-IMG" class="exampleImg">
            <img src="./images/img62.png" alt="" />
          </div>
          <p id="F2L-34-P" class="exampleText">
            y'<span class="mainColor2">U'</span>(R'UR)<br /><br /><b
              ><span class="scramble"> SC: </span></b
            >F'U'FU
          </p>
        </div>
        <div id="F2L-35-DIV" class="grid-item">
          <div class="grid-title">35</div>
          <div id="F2L-35-IMG" class="exampleImg">
            <img src="./images/img27.png" alt="" />
          </div>
          <p id="F2L-35-P" class="exampleText">
            y'U(R'<span class="mainColor2">U'</span>R<span class="mainColor3"
              >U'</span
            ><span class="mainColor2">U'</span>)(R'UR)<br /><br /><b
              ><span class="scramble"> SC: </span></b
            >15, 35
          </p>
        </div>
        <div id="F2L-36-DIV" class="grid-item">
          <div class="grid-title">36</div>
          <div id="F2L-36-IMG" class="exampleImg">
            <img src="./images/img33.png" alt="" />
          </div>
          <p id="F2L-36-P" class="exampleText">
            y'U(R'U2R<span class="mainColor3">U'</span
            ><span class="mainColor2">U'</span>)(R'UR)<br /><br /><b
              ><span class="scramble"> SC: </span></b
            ><br />y' R'U'R U2<br />R' U2 RU'
          </p>
        </div>
        <div id="F2L-37-DIV" class="grid-item">
          <div class="grid-title">37</div>
          <div id="F2L-37-IMG" class="exampleImg">
            <img src="./images/img39.png" alt="" />
          </div>
          <p id="F2L-37-P" class="exampleText">
            (R<span class="mainColor2">U'</span>R'U2)y'(R'<span
              class="mainColor2"
              >U'</span
            >R)<br /><br /><b><span class="scramble"> SC: </span></b
            ><br />F'UF<br />U2 RUR'
          </p>
        </div>
        <div id="F2L-38-DIV" class="grid-item">
          <div class="grid-title">38</div>
          <div id="F2L-38-IMG" class="exampleImg">
            <img src="./images/img45.png" alt="" />
          </div>
          <p id="F2L-38-P" class="exampleText">
            (R<span class="mainColor2">U'</span>R'U)(R<span class="mainColor2"
              >U'</span
            >R'U2)(R<span class="mainColor2">U'</span>R')<br /><br /><b
              ><span class="scramble"> SC: </span></b
            ><br />RUR' U2 [SX] RUR'
          </p>
        </div>
        <div id="F2L-39-DIV" class="grid-item">
          <div class="grid-title">39</div>
          <div id="F2L-39-IMG" class="exampleImg">
            <img src="./images/img51.png" alt="" />
          </div>
          <p id="F2L-39-P" class="exampleText">
            <span class="mainColor2">U'</span>(RUR'U)(RUR') <br /><br /><b
              ><span class="scramble"> SC: </span></b
            >

            (R<span class="mainColor2">U'</span>R'U)(RUR')U2
          </p>
        </div>
        <div id="F2L-40-DIV" class="grid-item">
          <div class="grid-title">40</div>
          <div id="F2L-40-IMG" class="exampleImg">
            <img src="./images/img57.png" alt="" />
          </div>
          <p id="F2L-40-P" class="exampleText">
            (RUR')<br /><br /><b><span class="scramble"> SC: </span></b>17, 31,
            39 or RU'R'
          </p>
        </div>
        <div id="F2L-41-DIV" class="grid-item">
          <div class="grid-title">41</div>
          <div id="F2L-41-IMG" class="exampleImg">
            <img src="./images/img63.png" alt="" />
          </div>
          <p id="F2L-41-P" class="exampleText">
            U'(R<span class="mainColor2">U'</span>R'U)(RUR')<br /><br /><b
              ><span class="scramble"> SC: </span></b
            ><br />RU'R'U'<br />RUR'U
          </p>
        </div>
      </div>
    </section>

    <!-- qwer OLL -->
    <!-- OLL new -->
    <section class="section" id="oll-section" style="margin-bottom: 60px">
      <div class="grid-item" style="margin-bottom: 10px">
        <div style="margin-bottom: 10px">
          <span class="img_span"
            ><img
              id="img-showAll-OLL"
              @click="showOLLAll()"
              class="img_btn"
              src="./img-all-40.png"
          /></span>
        </div>

        <div style="margin-bottom: 10px">
          <span class="img_span"
            ><img
              id="img-OLL_1piece"
              @click="showOLL1piece()"
              class="img_btn"
              src="./img1-40.png"
          /></span>
          <span class="img_span"
            ><img
              id="img-OLL_2piece"
              @click="showOLL2piece()"
              class="img_btn"
              src="./img2-40.png"
          /></span>
          <span class="img_span"
            ><img
              id="img-OLL_3piece"
              @click="showOLL3piece()"
              class="img_btn"
              src="./img3-40.png"
          /></span>
          <span class="img_span"
            ><img
              id="img-OLL_4piece"
              @click="showOLL4piece()"
              class="img_btn"
              src="./img4-40.png"
          /></span>
          <span class="img_span"
            ><img
              id="img-OLL_5piece"
              @click="showOLL5piece()"
              class="img_btn"
              src="./img5-40.png"
          /></span>
          <span class="img_span"
            ><img
              id="img-OLL-3missing"
              @click="showOLL3missing()"
              class="img_btn"
              src="./img-empty3-40.png"
          /></span>
          <span class="img_span"
            ><img
              id="img-OLL-2missing"
              @click="showOLL2missing()"
              class="img_btn"
              src="./img-empty2-40.png"
          /></span>
        </div>

        <div style="margin-bottom: 15px">
          <span class="img_span"
            ><img
              id="img-OLL_Rocketshape"
              @click="showOLL_Rocketshape()"
              class="img_btn"
              src="./img100-40.png"
          /></span>
          <span class="img_span"
            ><img
              id="img-OLL_Lshape1"
              @click="showOLL_Lshape1()"
              class="img_btn"
              src="./img109-40.png"
          /></span>
          <span class="img_span"
            ><img
              id="img-OLL_Lshape2"
              @click="showOLL_Lshape2()"
              class="img_btn"
              src="./img119-40.png"
          /></span>
          <span class="img_span"
            ><img
              id="img-OLL_Lineshape"
              @click="showOLL_Lineshape()"
              class="img_btn"
              src="./img101-40.png"
          /></span>
          <span class="img_span"
            ><img
              id="img-OLL_Sshape"
              @click="showOLL_Sshape()"
              class="img_btn"
              src="./img103-40.png"
          /></span>
        </div>

        <div style="margin-bottom: 15px">
          <span class="img_span"
            ><img
              id="img-OLL_buildingshape"
              @click="showOLL_buildingshape()"
              class="img_btn"
              src="./img99-40.png"
          /></span>
          <span class="img_span"
            ><img
              id="img-OLL_Tshape"
              @click="showOLL_Tshape()"
              class="img_btn"
              src="./img111-40.png"
          /></span>
          <span class="img_span"
            ><img
              id="img-OLL_Crossshape"
              @click="showOLL_Crossshape()"
              class="img_btn"
              src="./img98-40.png"
          /></span>
          <span class="img_span"
            ><img
              id="img-OLL_Cshape"
              @click="showOLL_Cshape()"
              class="img_btn"
              src="./img78-40.png"
          /></span>
          <span class="img_span"
            ><img
              id="img-learn-40"
              @click="showOLL_toLearn()"
              class="img_btn"
              src="./img-learn-40.png"
          /></span>
        </div>
      </div>

      <div class="grid-container">
        <div class="grid-item">
          <h2><b>OLL</b></h2>

          <div class="exampleText">
            <p>Orientation of Last Layer</p>
            <p>
              <b>[SX] = R U R'<span class="mainColor2"> U'</span></b>
            </p>
            <p>
              <b>[sh] = R' F R F'<span class="mainColor2"> </span></b>
            </p>
            <a href="#home-section">Go to the top</a><br />
            <a href="#f2l-section">Go to F2L</a><br />
            <a href="#pll-section">Go to PLL</a>
          </div>
        </div>

        <div id="OLL-01-DIV" class="grid-item">
          <div class="grid-title">01</div>
          <div id="OLL-01-IMG" class="exampleImg">
            <img src="./images/img96.png" alt="" />
          </div>
          <p id="OLL-01-P" class="exampleText">
            (RU2R')<br />[sh]<br />U2[sh]<br />
            <br />
          </p>

          <span class="scramblok">SC: (FR'F'R) U2 (FR'F') R2 U2 R'</span>
        </div>

        <div id="OLL-02-DIV" class="grid-item">
          <div class="grid-title">02</div>
          <div id="OLL-02-IMG" class="exampleImg">
            <img src="./images/img102.png" alt="" />
          </div>
          <div id="OLL-02-P" class="exampleText">
            F[SX]<span class="mainColor3">F'</span><br />f[SX]<span
              class="mainColor3"
              >f'</span
            ><br /><br /><span class="scramblok">SC: <br />02+U2</span>
          </div>
        </div>

        <div id="OLL-03-DIV" class="grid-item">
          <div class="grid-title">03</div>
          <div id="OLL-03-IMG" class="exampleImg">
            <img src="./images/img108.png" alt="" />
          </div>
          <p id="OLL-03-P" class="exampleText">
            f[SX]<span class="mainColor3">f'</span
            ><span class="mainColor2"> U'</span><br />F[SX]<span
              class="mainColor3"
              >F'</span
            ><br /><br />
          </p>
          <span class="scramblok"> SC: <br />04+U2</span>
        </div>

        <div id="OLL-04-DIV" class="grid-item">
          <div class="grid-title">04</div>
          <div id="OLL-04-IMG" class="exampleImg">
            <img src="./images/img114.png" alt="" />
          </div>
          <p id="OLL-04-P" class="exampleText">
            f[SX]<span class="mainColor3">f'</span> U<br />F[SX]<span
              class="mainColor3"
              >F'</span
            ><br /><br />
          </p>
          <span class="scramblok"> SC: <br />03+U2</span>
        </div>
        <div id="OLL-05-DIV" class="grid-item">
          <div class="grid-title">05</div>
          <div id="OLL-05-IMG" class="exampleImg">
            <img src="./images/img68.png" alt="" />
          </div>
          <p id="OLL-05-P" class="exampleText">
            (r'U2)(RUR'U)r<br /><br /><span class="scramblok">
              SC: 08, 12+U</span
            >
          </p>
        </div>
        <div id="OLL-06-DIV" class="grid-item">
          <div class="grid-title">06</div>
          <div id="OLL-06-IMG" class="exampleImg">
            <img src="./images/img74.png" alt="" />
          </div>
          <p id="OLL-06-P" class="exampleText">
            (r<span class="mainColor3">U'</span
            ><span class="mainColor2">U'</span>)(R'<span class="mainColor2"
              >U'</span
            >R<span class="mainColor2">U'</span>r')<br /><br />
          </p>
          <span class="scramblok"> SC: 07, 11+U', 13+U</span>
        </div>
        <div id="OLL-07-DIV" class="grid-item">
          <div class="grid-title">07</div>
          <div id="OLL-07-IMG" class="exampleImg">
            <img src="./images/img80.png" alt="" />
          </div>
          <p id="OLL-07-P" class="exampleText">
            (rUR'U)(R<span class="mainColor3">U'</span
            ><span class="mainColor2">U'</span>r')<br /><br />
          </p>
          <span class="scramble_orange"> SC: 06, 16</span>
        </div>
        <div id="OLL-08-DIV" class="grid-item">
          <div class="grid-title">08</div>
          <div id="OLL-08-IMG" class="exampleImg">
            <img src="./images/img86.png" alt="" />
          </div>
          <p id="OLL-08-P" class="exampleText">
            (r'<span class="mainColor2">U'</span>R<span class="mainColor2"
              >U'</span
            >)(R'U2r)<br /><span class="scramble_orange"> SC: 05, 10, 15</span>
          </p>
        </div>
        <div id="OLL-09-DIV" class="grid-item">
          <div class="grid-title">09</div>
          <div id="OLL-09-IMG" class="exampleImg">
            <img src="./images/img91.png" alt="" />
          </div>
          <p id="OLL-09-P" class="exampleText">
            [SX](R'FR)[SX]<span class="mainColor3">F'</span><br /><span
              class="scramble_orange"
            >
              SC: F (URU'R')(R'F'R)(URU'R')</span
            >
          </p>
        </div>

        <div id="OLL-10-DIV" class="grid-item">
          <div class="grid-title">10</div>
          <div id="OLL-10-IMG" class="exampleImg">
            <img src="./images/img64.png" alt="" />
          </div>
          <p id="OLL-10-P" class="exampleText">
            (RUR'<span class="mainColor">U</span>) <br />[sh]<br />(R<span
              class="mainColor2"
              >U'</span
            ><span class="mainColor2">U'</span>R')<br />
            <span class="scramble_orange"> SC: 14+U'</span>
          </p>
        </div>

        <div id="OLL-11-DIV" class="grid-item">
          <div class="grid-title">11</div>
          <div id="OLL-11-IMG" class="exampleImg">
            <img src="./images/img97.png" alt="" />
          </div>
          <p id="OLL-11-P" class="exampleText">
            (r'R2UR'U)(R<span class="mainColor3">U'</span
            ><span class="mainColor2">U'</span>R'U)<span class="mainColor"
              >M'</span
            ><br />
          </p>

          <div class="scramble">SC:<br />M U'RU2<br />(R'U'RU')<br />R2r</div>
        </div>

        <div id="OLL-12-DIV" class="grid-item">
          <div class="grid-title">12</div>
          <div id="OLL-12-IMG" class="exampleImg">
            <img src="./images/img103.png" alt="" />
          </div>
          <p id="OLL-12-P" class="exampleText">
            <span class="mainColor">M'</span>(R'<span class="mainColor2"
              >U'</span
            >R<span class="mainColor2">U'</span>)(R'U2R<span class="mainColor2"
              >U'</span
            >)M<br />
          </p>

          <div class="scramble">SC: <br />M'(UR'U2R)(UR'UR)M</div>
        </div>
        <div id="OLL-13-DIV" class="grid-item">
          <div class="grid-title">13</div>
          <div id="OLL-13-IMG" class="exampleImg">
            <img src="./images/img109.png" alt="" />
          </div>
          <p id="OLL-13-P" class="exampleText">
            f(RU)(R2<span class="mainColor2">U'</span>)(R'UR<span
              class="mainColor2"
              >U'</span
            >)<span class="mainColor">f'</span>
          </p>
          <div class="scramble">
            SC: 09+U2 <br />[SX](R'FR)[SX] <span class="mainColor3">F'</span>U2
          </div>
        </div>
        <div id="OLL-14-DIV" class="grid-item">
          <div class="grid-title">14</div>
          <div id="OLL-14-IMG" class="exampleImg">
            <img src="./images/img115.png" alt="" />
          </div>
          <p id="OLL-14-P" class="exampleText">
            (R'F)(RUR'<span class="mainColor3">F'</span>R)(F<span
              class="mainColor2"
              >U'</span
            ><span class="mainColor3">F'</span>)
          </p>
          <div class="scramble">SC: (FUF')(R'FRU'R')(F'R)</div>
        </div>

        <div id="OLL-15-DIV" class="grid-item">
          <div class="grid-title">15</div>
          <div id="OLL-15-IMG" class="exampleImg">
            <img src="./images/img69.png" alt="" />
          </div>
          <p id="OLL-15-P" class="exampleText">
            (r'<span class="mainColor2">U'</span>r)(R'<span class="mainColor2"
              >U'</span
            >RU)(r'Ur)<br /><span class="scramble_orange">
              SC: (r'U'r)(U'R'UR)(r'Ur)</span
            >
          </p>
        </div>
        <div id="OLL-16-DIV" class="grid-item">
          <div class="grid-title">16</div>
          <div id="OLL-16-IMG" class="exampleImg">
            <img src="./images/img75.png" alt="" />
          </div>
          <p id="OLL-16-P" class="exampleText">
            (rUr')<br />[SX]<br />(r<span class="mainColor2">U'</span
            ><span class="mainColor">r'</span>)<br /><span
              class="scramble_orange"
            >
              SC:<br />(rUr')(URU'R')(rU'r')</span
            >
          </p>
        </div>
        <div id="OLL-17-DIV" class="grid-item">
          <div class="grid-title">17</div>
          <div id="OLL-17-IMG" class="exampleImg">
            <img src="./images/img81.png" alt="" />
          </div>
          <p id="OLL-17-P" class="exampleText">
            (l<span class="mainColor2">U'</span>l')f(R2BR'UR'<span
              class="mainColor2"
              >U'</span
            ><span class="mainColor3">f'</span>)<br /><span
              class="scramble_learn"
            >
              SC: 19</span
            >
          </p>
        </div>
        <div id="OLL-18-DIV" class="grid-item">
          <div class="grid-title">18</div>
          <div id="OLL-18-IMG" class="exampleImg">
            <img src="./images/img87.png" alt="" />
          </div>
          <p id="OLL-18-P" class="exampleText">
            (rUR'U)(R<span class="mainColor3">U'</span
            ><span class="mainColor2">U'</span>r')(r'<span class="mainColor2"
              >U'</span
            >R<span class="mainColor2">U'</span>)(R'U2r)<br /><span
              style="color: #00a650"
            >
              OR</span
            ><br />U f[SX]2 f' U2 f [SX] f'<br /><br /><span
              class="scramble_learn"
            >
              SC: F [SX] F' U2 F [SX]2 F' U or 17</span
            >
          </p>
        </div>
        <div id="OLL-19-DIV" class="grid-item">
          <div class="grid-title">19</div>
          <div id="OLL-19-IMG" class="exampleImg">
            <img src="./images/img92.png" alt="" />
          </div>
          <p id="OLL-19-P" class="exampleText">
            MU [SX] <br />M' [sh]<br /><span class="scramble_orange">
              SC: 18</span
            >
          </p>
        </div>
        <div id="OLL-20-DIV" class="grid-item">
          <div class="grid-title">20</div>
          <div id="OLL-20-IMG" class="exampleImg">
            <img src="./images/img65.png" alt="" />
          </div>
          <p id="OLL-20-P" class="exampleText">
            (rUR'<span class="mainColor2">U'</span>)<span class="mainColor"
              >M'2</span
            >U(R<span class="mainColor2">U'</span>R'<span class="mainColor2"
              >U'</span
            >)<span class="mainColor">M'</span><br /><span class="scramblok">
              SC: 20</span
            >
          </p>
        </div>
        <div id="OLL-21-DIV" class="grid-item">
          <div class="grid-title">21</div>
          <div id="OLL-21-IMG" class="exampleImg">
            <img src="./images/img98.png" alt="" />
          </div>
          <p id="OLL-21-P" class="exampleText">
            (RU2R'<span class="mainColor2">U'</span>)[SX]<br />(R<span
              class="mainColor2"
              >U'</span
            >R')<br />
            <span style="color: #00a650"> OR</span>
            F[SX]3F'<br /><span class="scramblok">SC: 21</span>
          </p>
        </div>
        <div id="OLL-22-DIV" class="grid-item">
          <div class="grid-title">22</div>
          <div id="OLL-22-IMG" class="exampleImg">
            <img src="./images/img104.png" alt="" />
          </div>
          <p id="OLL-22-P" class="exampleText">
            (R<span class="mainColor3">U'</span
            ><span class="mainColor2">U'</span>)(R'2<span class="mainColor2"
              >U'</span
            >)(R2<span class="mainColor2">U'</span>)(R'2<span class="mainColor2"
              >U'</span
            >)(<span class="mainColor3">U'</span>R)<br /><span class="scramblok"
              >SC: 22</span
            >
          </p>
        </div>
        <div id="OLL-23-DIV" class="grid-item">
          <div class="grid-title">23</div>
          <div id="OLL-23-IMG" class="exampleImg">
            <img src="./images/img110.png" alt="" />
          </div>
          <p id="OLL-23-P" class="exampleText">
            (R2<span class="mainColor">D'</span>)(R<span class="mainColor3"
              >U'</span
            ><span class="mainColor2">U'</span>R'<span class="mainColor">D</span
            >)(R<span class="mainColor3">U'</span
            ><span class="mainColor2">U'</span>R)<br /><span class="scramblok"
              >SC:<br />R'U2R'D'<br />R U2 R'<br />D R2</span
            >
          </p>
        </div>
        <div id="OLL-24-DIV" class="grid-item">
          <div class="grid-title">24</div>
          <div id="OLL-24-IMG" class="exampleImg">
            <img src="./images/img116.png" alt="" />
          </div>
          <p id="OLL-24-P" class="exampleText">
            (rUR'<span class="mainColor2">U'</span>)(r'FR<span
              class="mainColor3"
              >F'</span
            >) <br /><span class="scramblok"> SC: 25+U2 </span>
          </p>
        </div>
        <div id="OLL-25-DIV" class="grid-item">
          <div class="grid-title">25</div>
          <div id="OLL-25-IMG" class="exampleImg">
            <img src="./images/img70.png" alt="" />
          </div>
          <p id="OLL-25-P" class="exampleText">
            <span class="mainColor3">F'</span>(rUR'<span class="mainColor2"
              >U'</span
            >)(r'FR) <br /><span class="scramblok"> SC: 23+U2 </span>
          </p>
        </div>
        <div id="OLL-26-DIV" class="grid-item">
          <div class="grid-title">26</div>
          <div id="OLL-26-IMG" class="exampleImg">
            <img src="./images/img76.png" alt="" />
          </div>
          <p id="OLL-26-P" class="exampleText">
            (R<span class="mainColor3">U'</span
            ><span class="mainColor2">U'</span>)(R'<span class="mainColor2"
              >U'</span
            >R<span class="mainColor2">U'</span>R')<br /><span class="scramblok"
              >SC: (RUR'U)(RU2R')</span
            >
          </p>
        </div>
        <div id="OLL-27-DIV" class="grid-item">
          <div class="grid-title">27</div>
          <div id="OLL-27-IMG" class="exampleImg">
            <img src="./images/img82.png" alt="" />
          </div>
          <p id="OLL-27-P" class="exampleText">
            (RUR'<span class="mainColor">U</span>)(R<span class="mainColor3"
              >U'</span
            ><span class="mainColor2">U'</span>R')<br /><span class="scramblok"
              >SC: (RU2R')(U'RU'R')</span
            >
          </p>
        </div>
        <div id="OLL-28-DIV" class="grid-item">
          <div class="grid-title">28</div>
          <div id="OLL-28-IMG" class="exampleImg">
            <img src="./images/img88.png" alt="" />
          </div>
          <p id="OLL-28-P" class="exampleText">
            (rUR'<span class="mainColor2">U'</span>)(MU)<br />(R<span
              class="mainColor2"
              >U'</span
            >R')<br /><span class="scramblok">
              SC:<a href="#OLL-57-DIV">57</a></span
            >
          </p>
        </div>
        <div id="OLL-29-DIV" class="grid-item">
          <div class="grid-title">29</div>
          <div id="OLL-29-IMG" class="exampleImg">
            <img src="./images/img93.png" alt="" />
          </div>
          <p id="OLL-29-P" class="exampleText">
            [SX](R<span class="mainColor2">U'</span>R'<span class="mainColor3"
              >F'</span
            ><span class="mainColor2">U'</span>F)(RUR')
          </p>

          <div class="scramble">SC: <br />(RU'R')(F'UFRUR')(URU'R')</div>
        </div>
        <div id="OLL-30-DIV" class="grid-item">
          <div class="grid-title">30</div>
          <div id="OLL-30-IMG" class="exampleImg">
            <img src="./images/img66.png" alt="" />
          </div>
          <p id="OLL-30-P" class="exampleText">
            f(RU)(R2<span class="mainColor2">U'</span>R'U)(R2<span
              class="mainColor2"
              >U'</span
            >R')<span class="mainColor3">f'</span>
          </p>
          <div class="scramble">SC:<br />f(RUR2)(U'RUR2)(U'R')f'</div>
        </div>

        <div id="OLL-31-DIV" class="grid-item">
          <div class="grid-title">31</div>
          <div id="OLL-31-IMG" class="exampleImg">
            <img src="./images/img99.png" alt="" />
          </div>
          <p id="OLL-31-P" class="exampleText">
            (r'<span class="mainColor3">F'UF</span>)(r<span class="mainColor2"
              >U'</span
            >r'<span class="mainColor2">U'</span>r)<br /><span class="scramble">
              SC: </span
            ><a href="#OLL-38-DIV">38</a>+U' or <a href="#OLL-40-DIV">40</a>+U2
          </p>
        </div>

        <div id="OLL-32-DIV" class="grid-item">
          <div class="grid-title">32</div>
          <div id="OLL-32-IMG" class="exampleImg">
            <img src="./images/img105.png" alt="" />
          </div>
          <p id="OLL-32-P" class="exampleText">
            (RU)(<span class="mainColor3">B'</span
            ><span class="mainColor2">U'</span>)(R'URBR')<br />
          </p>
          <div class="scramble">SC: 36 or 39</div>
        </div>
        <div id="OLL-33-DIV" class="grid-item">
          <div class="grid-title">33</div>
          <div id="OLL-33-IMG" class="exampleImg">
            <img src="./images/img111.png" alt="" />
          </div>
          <p id="OLL-33-P" class="exampleText">
            [SX]<br />[sh] <br /><span class="scramblok"> SC: 37</span>
          </p>
        </div>
        <div id="OLL-34-DIV" class="grid-item">
          <div class="grid-title">34</div>
          <div id="OLL-34-IMG" class="exampleImg">
            <img src="./images/img117.png" alt="" />
          </div>
          <p id="OLL-34-P" class="exampleText">
            (RUR2<span class="mainColor2">U'</span>)(R'F)(RUR<span
              class="mainColor2"
              >U'</span
            ><span class="mainColor">F'</span>)<br /><span
              class="scramble_orange"
              >SC: <br />FUR'U'R'<br />F'R<br />UR2U'R'</span
            >
          </p>
        </div>
        <div id="OLL-35-DIV" class="grid-item">
          <div class="grid-title">35</div>
          <div id="OLL-35-IMG" class="exampleImg">
            <img src="./images/img71.png" alt="" />
          </div>
          <p id="OLL-35-P" class="exampleText">
            RU2R' <br />[sh]<br />RU2R' <br /><span class="scramble_orange">
              SC: RU2R'<br />FR'F'R<br />RU2R'</span
            >
          </p>
        </div>
        <div id="OLL-36-DIV" class="grid-item">
          <div class="grid-title">36</div>
          <div id="OLL-36-IMG" class="exampleImg">
            <img src="./images/img77.png" alt="" />
          </div>
          <p id="OLL-36-P" class="exampleText">
            (R'<span class="mainColor2">U'</span>R<span class="mainColor2"
              >U'</span
            >)(R'URU)(l<span class="mainColor2">U'</span>R'U)
          </p>

          <div class="scramble">
            SC:<br />
            (B'RBR')(U'R'U'R)(UR'UR)
          </div>
        </div>
        <div id="OLL-37-DIV" class="grid-item">
          <div class="grid-title">37</div>
          <div id="OLL-37-IMG" class="exampleImg">
            <img src="./images/img83.png" alt="" />
          </div>
          <p id="OLL-37-P" class="exampleText">
            F(R<span class="mainColor2">U'</span>R'<span class="mainColor2"
              >U'</span
            >)(RUR'<span class="mainColor3">F'</span>) <br /><br /><span
              class="scramblok"
            >
              SC: 33 or 34 or 35+U</span
            >
          </p>
        </div>
        <div id="OLL-38-DIV" class="grid-item">
          <div class="grid-title">38</div>
          <div id="OLL-38-IMG" class="exampleImg">
            <img src="./images/img89.png" alt="" />
          </div>
          <p id="OLL-38-P" class="exampleText">
            (RUR'U)(R<span class="mainColor2">U'</span>R'<span
              class="mainColor2"
              >U'</span
            >)<br />[sh] <br /><span class="scramble_orange">
              SC: FR’F’R U [SX] RU’R’</span
            >
          </p>
          <a href="#OLL-31-DIV">Go to 31</a>
        </div>
        <div id="OLL-39-DIV" class="grid-item">
          <div class="grid-title">39</div>
          <div id="OLL-39-IMG" class="exampleImg">
            <img src="./images/img94.png" alt="" />
          </div>
          <p id="OLL-39-P" class="exampleText">
            (RUR'<span class="mainColor3">F'</span
            ><span class="mainColor2">U'</span>F)U(RU2R')<br /><br /><span
              class="scramble"
            >
              SC: </span
            >32
          </p>
        </div>
        <div id="OLL-40-DIV" class="grid-item">
          <div class="grid-title">40</div>
          <div id="OLL-40-IMG" class="exampleImg">
            <img src="./images/img94_2.png" alt="" />
          </div>
          <p id="OLL-40-P" class="exampleText">
            R'<br />F[SX]<span class="mainColor3">F'</span
            ><br />(UR)<br /><br /><span class="scramble"> SC: </span
            ><a href="#OLL-31-DIV">31</a>+U2
          </p>
        </div>
        <div id="OLL-41-DIV" class="grid-item">
          <div class="grid-title">41</div>
          <div id="OLL-41-IMG" class="exampleImg">
            <img src="./images/img100.png" alt="" />
          </div>
          <p id="OLL-41-P" class="exampleText">
            (RUR'<span class="mainColor">U</span>)(R<span class="mainColor3"
              >U'</span
            ><span class="mainColor2">U'</span>R')<br />F[SX]<span
              class="mainColor3"
              >F'</span
            ><br /><br /><span class="scramble_orange"> SC: 42</span>
          </p>
        </div>
        <div id="OLL-42-DIV" class="grid-item">
          <div class="grid-title">42</div>
          <div id="OLL-42-IMG" class="exampleImg">
            <img src="./images/img106.png" alt="" />
          </div>
          <p id="OLL-42-P" class="exampleText">
            (R'<span class="mainColor2">U'</span>R<span class="mainColor2"
              >U'</span
            >)(R'U2R)<br />F[SX]<span class="mainColor3">F'</span
            ><br /><br /><span class="scramble_orange">
              SC: F(URU'R')F'(R'U2R)(UR'UR)</span
            >
          </p>
        </div>
        <div id="OLL-43-DIV" class="grid-item">
          <div class="grid-title">43</div>
          <div id="OLL-43-IMG" class="exampleImg">
            <img class="rotate90" src="./images/img112.png" alt="" />
          </div>
          <p id="OLL-43-P" class="exampleText">
            R'U'F'<br />UFR <br /><br /><span class="scramblok">
              SC: 41 or 46+U'</span
            >
          </p>
        </div>
        <div id="OLL-44-DIV" class="grid-item">
          <div class="grid-title">44</div>
          <div id="OLL-44-IMG" class="exampleImg">
            <img src="./images/img118.png" alt="" />
          </div>
          <p id="OLL-44-P" class="exampleText">
            f[SX]<span class="mainColor3">f'</span>

            <br /><br /><span class="scramblok">
              SC: 45+U2 or 29+U2 or 30+U'</span
            >
          </p>
        </div>
        <div id="OLL-45-DIV" class="grid-item">
          <div class="grid-title">45</div>
          <div id="OLL-45-IMG" class="exampleImg">
            <img src="./images/img72.png" alt="" />
          </div>
          <p id="OLL-45-P" class="exampleText">
            F[SX]<span class="mainColor3">F'</span> <br /><br /><span
              class="scramblok"
            >
              SC: 44+U2 or 43</span
            >
          </p>
        </div>
        <div id="OLL-46-DIV" class="grid-item">
          <div class="grid-title">46</div>
          <div id="OLL-46-IMG" class="exampleImg">
            <img src="./images/img78.png" alt="" />
          </div>
          <p id="OLL-46-P" class="exampleText">
            (R'<span class="mainColor2">U'</span>)[sh]<br />(U R)
            <br /><br /><span class="scramblok">
              SC: R'U' <br />FR'F'R<br />UR</span
            >
          </p>
        </div>
        <div id="OLL-47-DIV" class="grid-item">
          <div class="grid-title">47</div>
          <div id="OLL-47-IMG" class="exampleImg">
            <img src="./images/img84.png" alt="" />
          </div>
          <p id="OLL-47-P" class="exampleText">
            <span class="mainColor3">b'</span>(<span class="mainColor2">U'</span
            >R'UR)2b <br /><br /><span class="scramble"> SC: </span
            >b'<br />(R'U'RU)2<br />b
          </p>
        </div>

        <div id="OLL-48-DIV" class="grid-item">
          <div class="grid-title">48</div>
          <div id="OLL-48-IMG" class="exampleImg">
            <img src="./images/img90.png" alt="" />
          </div>
          <p id="OLL-48-P" class="exampleText">
            F[SX]2<span class="mainColor3">F'</span>

            <br /><br /><span class="scramble_orange"> SC: f[SX]2f' </span>
          </p>
        </div>
        <div id="OLL-49-DIV" class="grid-item">
          <div class="grid-title">49</div>
          <div id="OLL-49-IMG" class="exampleImg">
            <img src="./images/img95.png" alt="" />
          </div>
          <p id="OLL-49-P" class="exampleText">
            (R<span class="mainColor3">B'</span>)(R'2FR2B)(R'2<span
              class="mainColor3"
              >F'</span
            >R)<br /><br /><span class="scramble"> SC: </span>50+U2
          </p>
        </div>
        <div id="OLL-50-DIV" class="grid-item">
          <div class="grid-title">50</div>
          <div id="OLL-50-IMG" class="exampleImg">
            <img src="./images/img95_2.png" alt="" />
          </div>
          <p id="OLL-50-P" class="exampleText">
            (r'U)(r2<span class="mainColor2">U'</span>r'2<span
              class="mainColor2"
              >U'</span
            >)(r2Ur')<br /><br /><span class="scramble"> SC: </span>49+U2
          </p>
        </div>
        <div id="OLL-51-DIV" class="grid-item">
          <div class="grid-title">51</div>
          <div id="OLL-51-IMG" class="exampleImg">
            <img src="./images/img101.png" alt="" />
          </div>
          <p id="OLL-51-P" class="exampleText">
            f[SX]2<span class="mainColor3">f'</span> <br /><br /><span
              class="scramblok"
            >
              SC: 47 or 48+U2</span
            >
          </p>
        </div>
        <div id="OLL-52-DIV" class="grid-item">
          <div class="grid-title">52</div>
          <div id="OLL-52-IMG" class="exampleImg">
            <img src="./images/img107.png" alt="" />
          </div>
          <p id="OLL-52-P" class="exampleText">
            (R'<span class="mainColor3">F'</span
            ><span class="mainColor2">U'</span>F<span class="mainColor2"
              >U'</span
            >)(RUR'<span class="mainColor">U</span>R)<br /><br /><span
              class="scramble"
            >
              SC: </span
            >52+U2
          </p>
        </div>
        <div id="OLL-53-DIV" class="grid-item">
          <div class="grid-title">53</div>
          <div id="OLL-53-IMG" class="exampleImg">
            <img src="./images/img113.png" alt="" />
          </div>
          <p id="OLL-53-P" class="exampleText">
            (r'U2)<br />[SX]<br />(RUR'Ur) <br /><br /><span
              class="scramble_learn"
            >
              SC: 53+U</span
            >
          </p>
        </div>
        <div id="OLL-54-DIV" class="grid-item">
          <div class="grid-title">54</div>
          <div id="OLL-54-IMG" class="exampleImg">
            <img src="./images/img119.png" alt="" />
          </div>
          <p id="OLL-54-P" class="exampleText">
            (rUr')<br />[SX]2<br />(r<span class="mainColor2">U'</span
            >r')<br /><br /><span class="scramble_orange">
              SC: (rUr')(UR<span class="mainColor2">U'</span>R')2(r<span
                class="mainColor2"
                >U'</span
              >r')
            </span>
          </p>
        </div>
        <div id="OLL-55-DIV" class="grid-item">
          <div class="grid-title">55</div>
          <div id="OLL-55-IMG" class="exampleImg">
            <img src="./images/img73.png" alt="" />
          </div>
          <p id="OLL-55-P" class="exampleText">
            (r<span class="mainColor3">U'</span
            ><span class="mainColor2">U'</span>R'<span class="mainColor2"
              >U'</span
            >)M [SX]<br />(r<span class="mainColor2">U'</span
            >r')<br /><br /><span class="scramble_orange">
              SC: (rUr'U)(RU'R')(M'URU2r')</span
            >
          </p>
        </div>
        <div id="OLL-56-DIV" class="grid-item">
          <div class="grid-title">56</div>
          <div id="OLL-56-IMG" class="exampleImg">
            <img src="./images/img79.png" alt="" />
          </div>
          <p id="OLL-56-P" class="exampleText">
            (rUr')(UR<span class="mainColor2">U'</span>R')2(r<span
              class="mainColor2"
              >U'</span
            >r')<br /><br /><span class="scramble_orange"> SC: 54</span>
          </p>
        </div>
        <div id="OLL-57-DIV" class="grid-item">
          <div class="grid-title">57</div>
          <div id="OLL-57-IMG" class="exampleImg">
            <img src="./images/img85.png" alt="" />
          </div>
          <p id="OLL-57-P" class="exampleText">
            [SX]<span class="mainColor">M'</span>(UR<span class="mainColor2"
              >U'</span
            >r')<br /><span style="color: #00a650"> OR</span> U f[SX]f' U2
            f[SX]f' <br /><br /><span class="scramblok">
              SC: F[SX]F' U2 F[SX]F' U <br />or
              <a href="#OLL-28-DIV">28</a></span
            >
          </p>
        </div>
      </div>
    </section>

    <!-- qwer PLL -->
    <!-- PLL new -->
    <section class="section" id="pll-section">
      <div class="exampleText text-warning"></div>

      <div class="grid-container">
        <div class="grid-item">
          <h2 class="mainColor"><b>PLL</b></h2>

          <div class="exampleText">
            <p class="exampleText">Permutation of Last Layer</p>
            <p>
              <b>[SX] = R U R'<span class="mainColor2"> U'</span></b>
            </p>
            <p>
              <b>[sh] = R' F R F'<span class="mainColor2"> </span></b>
            </p>
            <a href="#home-section">Go to the top</a><br />
            <a href="#f2l-section">Go to F2L</a><br />
            <a href="#oll-section">Go to OLL</a><br />
          </div>
        </div>

        <div class="grid-item">
          <div class="grid-title">01</div>
          <div class="exampleImg">
            <img src="./images/img125.png" alt="" /><b>Ua</b>
          </div>
          <p class="exampleText">
            <span class="mainColor">M'2</span>U<span class="mainColor">M</span
            >U2<span class="mainColor">M'</span>U<span class="mainColor"
              >M'2</span
            ><br /><span style="color: #00a650"> OR</span><br />(R<span
              class="mainColor2"
              >U'</span
            >R)(UR)2(<span class="mainColor2">U'</span>R'<span
              class="mainColor2"
              >U'</span
            >R'2)
          </p>
        </div>
        <div class="grid-item">
          <div class="grid-title">02</div>
          <div class="exampleImg">
            <img src="./images/img131.png" alt="" /><b>Ub</b>
          </div>
          <p class="exampleText">
            <span class="mainColor">M'2</span>
            <span class="mainColor2">U'</span
            ><span class="mainColor">M</span> U2<span class="mainColor">M'</span
            ><span class="mainColor2">U'</span><span class="mainColor">M'2</span
            ><br /><span style="color: #00a650"> OR</span><br />(R2
            U)(RU)(R'<span class="mainColor2">U'</span>)2(R'UR')
          </p>
        </div>
        <div class="grid-item">
          <div class="grid-title">03</div>
          <div class="exampleImg">
            <img src="./images/img136.png" alt="" /><b>H</b>
          </div>
          <p class="exampleText">
            <span class="mainColor">M'2</span>U<span class="mainColor">M'2</span
            >U2<span class="mainColor">M'2</span>U<span class="mainColor"
              >M'2</span
            >
          </p>
        </div>
        <div class="grid-item">
          <div class="grid-title">04</div>
          <div class="exampleImg">
            <img src="./images/img120.png" alt="" /><b>Z</b>
          </div>
          <p class="exampleText">
            <span class="mainColor">M'</span>U(<span class="mainColor">M'2</span
            >U)2<span class="mainColor">M'</span>U2<span class="mainColor"
              >M'2</span
            ><span class="mainColor2">U'</span>
          </p>
        </div>

        <div class="grid-item">
          <div class="grid-title">05</div>
          <div class="exampleImg">
            <img src="./images/img126.png" alt="" /><b>Aa</b>
          </div>
          <p class="exampleText">
            x'R2<span class="mainColor">D2</span>(R'<span class="mainColor2"
              >U'</span
            >R)<span class="mainColor">D2</span>(R'UR')
          </p>
        </div>
        <div class="grid-item">
          <div class="grid-title">06</div>
          <div class="exampleImg">
            <img src="./images/img132.png" alt="" /><b>Ab</b>
          </div>
          <p class="exampleText">
            x'(R<span class="mainColor2">U'</span>R)<span class="mainColor"
              >D2</span
            >(R'UR)<span class="mainColor">D2</span>R'2
          </p>
        </div>
        <div class="grid-item">
          <div class="grid-title">07</div>
          <div class="exampleImg">
            <img src="./images/img137.png" alt="" /><b>E</b>
          </div>
          <p class="exampleText">
            x'(R<span class="mainColor2">U'</span>R'<span class="mainColor"
              >D</span
            >)(RUR'<span class="mainColor">D'</span>)(RUR'<span
              class="mainColor"
              >D</span
            >)(R<span class="mainColor2">U'</span>R'<span class="mainColor"
              >D'</span
            >)
          </p>
        </div>
        <div class="grid-item">
          <div class="grid-title">08</div>
          <div class="exampleImg">
            <img src="./images/img121.png" alt="" /><b>T</b>
          </div>
          <p class="exampleText">
            [SX](R'F)(R2<span class="mainColor2">U'</span>R'<span
              class="mainColor2"
              >U'</span
            >)(RUR'<span class="mainColor3">F'</span>)
          </p>
        </div>
        <div class="grid-item">
          <div class="grid-title">09</div>
          <div class="exampleImg">
            <img src="./images/img127.png" alt="" /><b>F</b>
          </div>
          <p class="exampleText">
            (R'<span class="mainColor2">U'</span
            ><span class="mainColor3">F'</span>)<br />[SX](R'F)(R2<span
              class="mainColor2"
              >U'</span
            >R'<span class="mainColor2">U'</span>)(RUR'UR)
          </p>
        </div>
        <div class="grid-item">
          <div class="grid-title">10</div>
          <div class="exampleImg">
            <img src="./images/img133.png" alt="" /><b>V</b>
          </div>
          <p class="exampleText">
            (R'UR'd')(R'<span class="mainColor3">F'</span>R2<span
              class="mainColor2"
              >U'</span
            >)(R'UR'FRF)
          </p>
        </div>
        <div class="grid-item">
          <div class="grid-title">11</div>
          <div class="exampleImg">
            <img src="./images/img138.png" alt="" /><b>Y</b>
          </div>
          <p class="exampleText">
            F(R<span class="mainColor2">U'</span>R'<span class="mainColor2"
              >U'</span
            >)(RUR'<span class="mainColor3">F'</span>)[SX]<br />[sh]
          </p>
        </div>
        <div class="grid-item">
          <div class="grid-title">12</div>
          <div class="exampleImg">
            <img src="./images/img122.png" alt="" /><b>Ja</b>
          </div>
          <p class="exampleText">
            z(<span class="mainColor2">U'</span>R<span class="mainColor"
              >D'</span
            >)(R2UR'<span class="mainColor2">U'</span>)(R2U)(<span
              class="mainColor"
              >D</span
            >R') <br /><br />
            or 13 [left handed]
          </p>
        </div>
        <div class="grid-item">
          <div class="grid-title">13</div>
          <div class="exampleImg">
            <img src="./images/img128.png" alt="" /><b>Jb</b>
          </div>
          <p class="exampleText">
            (RUR'<span class="mainColor2">F'</span>)[SX](R'F)(R2<span
              class="mainColor2"
              >U'</span
            >R'<span class="mainColor2">U'</span>)
          </p>
        </div>
        <div class="grid-item">
          <div class="grid-title">14</div>
          <div class="exampleImg">
            <img src="./images/img134.png" alt="" /><b>Rb</b>
          </div>
          <p class="exampleText">
            (R'U2)(R<span class="mainColor3">U'</span
            ><span class="mainColor2">U'</span>)(R'F) [SX](R'<span
              class="mainColor3"
              >F'</span
            >R2<span class="mainColor2">U'</span>)
          </p>
        </div>
        <div class="grid-item">
          <div class="grid-title">15</div>
          <div class="exampleImg">
            <img src="./images/img139.png" alt="" /><b>Ra</b>
          </div>
          <p class="exampleText">
            (R<span class="mainColor2">U'</span>R'<span class="mainColor2"
              >U'</span
            >)(RUR<span class="mainColor">D</span>)(R'<span class="mainColor2"
              >U'</span
            >R<span class="mainColor">D'</span>)(R'U2R'U')
          </p>
        </div>
        <div class="grid-item">
          <div class="grid-title">16</div>
          <div class="exampleImg">
            <img src="./images/img123.png" alt="" /><b>Gc</b>
          </div>
          <p class="exampleText">
            (R'2<span class="mainColor2">u'</span>R<span class="mainColor3"
              >U'</span
            >R)(UR'u)(R2fR'<span class="mainColor3">f'</span>)
          </p>
        </div>
        <div class="grid-item">
          <div class="grid-title">17</div>
          <div class="exampleImg">
            <img src="./images/img129.png" alt="" /><b>Gd</b>
          </div>
          <p class="exampleText">
            (RUR')y'(R2<span class="mainColor3">u'</span>R<span
              class="mainColor2"
              >U'</span
            >)(R'UR'uR2)
          </p>
        </div>
        <div class="grid-item">
          <div class="grid-title">18</div>
          <div class="exampleImg">
            <img src="./images/img135.png" alt="" /><b>Ga</b>
          </div>
          <p class="exampleText">
            (R2uR')(UR'<span class="mainColor2">U'</span>R<span
              class="mainColor2"
              >u'</span
            >)(R'2<span class="mainColor3">F'UF</span>)
          </p>
        </div>
        <div class="grid-item">
          <div class="grid-title">19</div>
          <div class="exampleImg">
            <img src="./images/img140.png" alt="" /><b>Gb</b>
          </div>
          <p class="exampleText">
            (R'd'F)(R2u)(R'U)(R<span class="mainColor3">U'</span>R<span
              class="mainColor2"
              >u'</span
            >R'2)
          </p>
        </div>
        <div class="grid-item">
          <div class="grid-title">20</div>
          <div class="exampleImg">
            <img src="./images/img124.png" alt="" /><b>Nb</b>
          </div>
          <p class="exampleText">
            (R'UR<span class="mainColor2">U'</span>)(R'<span class="mainColor3"
              >F'</span
            ><span class="mainColor2">U'</span>)(FRUR'F)(R'<span
              class="mainColor3"
              >F'</span
            >R<span class="mainColor2">U'</span>R)
          </p>
        </div>
        <div class="grid-item">
          <div class="grid-title">21</div>
          <div class="exampleImg">
            <img src="./images/img130.png" alt="" /><b>Na</b>
          </div>
          <p class="exampleText">
            (RUR'<span class="mainColor">U</span>)(RUR'<span class="mainColor2"
              >F'</span
            >)<br />[SX](R'F)<br />(R2<span class="mainColor2">U'</span
            >R'U2)(R<span class="mainColor2">U'</span>R')
          </p>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="text-warning">
        <div class="text-warning">
          Check also:
          <a href="https://jperm.net/algs/pll">jperm.net/algs/pll</a>
        </div>
        <div>
          This is a copy of official GAN site with some improvements for my
          personal use only!<a
            href="https://www.gancube.com/3x3x3-cfop-guide-of-gancube"
          >
            Official GAN site
          </a>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import { onMounted } from 'vue';

onMounted(() => {
  let scriptArr = [];

  for (let i = 0; i < scriptArr.length; i++) {
    const script1 = document.createElement('script');
    script1.setAttribute('src', scriptArr[i]);
    document.head.appendChild(script1);
  }
});

/* ===== F2L ===== */
// function showMixedUp() {
// 	hideF2LAll()
// 	showHideSection([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 22, 23, 32, 33], '', false)
// }

function showBothDown() {
  hideF2LAll();
  addClass('btn-bothDown-F2L', 'buyActive');
  showHideSection([1, 4, 5, 8, 9], '', false);
}

function showUpAndDown() {
  hideF2LAll();
  addClass('btn-upAndDown-F2L', 'buyActive');
  showHideSection([2, 3, 6, 7, 10, 11], '', false);
}

function showUpAndMid() {
  hideF2LAll();
  addClass('btn-upAndMid-F2L', 'buyActive');
  showHideSection([12, 13, 22, 23, 32, 33], '', false);
}

function showBothUp() {
  hideF2LAll();
  addClass('btn-bothUp-F2L', 'buyActive');
  showHideSection(
    [
      14, 15, 16, 17, 18, 19, 20, 21, 24, 25, 26, 27, 28, 29, 30, 31, 34, 35,
      36, 37, 38, 39, 40, 41,
    ],
    '',
    false
  );
}

function showBothUpWhiteUp() {
  hideF2LAll();
  addClass('btn-bothUpWhiteUp-F2L', 'buyActive');
  showHideSection([15, 16, 19, 20], '', false);
  //showHideSection([14, 15, 16, 17, 18, 19, 20, 21], '', false)
}

function showBothUpWhiteFront() {
  hideF2LAll();
  addClass('btn-bothUpWhiteFront-F2L', 'buyActive');
  showHideSection([25, 26, 29, 30], '', false);
  //showHideSection([24, 25, 26, 27, 28, 29, 30, 31], '', false)
}

function showBothUpWhiteSide() {
  hideF2LAll();
  addClass('btn-bothUpWhiteSide-F2L', 'buyActive');
  showHideSection([35, 36, 39, 40], '', false);
  //showHideSection([34, 35, 36, 37, 38, 39, 40, 41], '', false)
}

// function showBothUpBlock() {
// 	hideF2LAll()
// 	addClass('btn-bothUpBlock-F2L', 'buyActive');
// 	showHideSection([14, 17, 18, 21, 24, 27, 28, 31, 34, 37, 38, 41], '', false)
// }

function showF2LAll() {
  removeClassAllBtn();
  addClass('btn-showAll-F2L', 'buyActive');
  showHideSection(
    [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
      40, 41,
    ],
    'F2L',
    false
  );
}

function hideF2LAll() {
  removeClassAllBtn();
  showHideSection(
    [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
      40, 41,
    ],
    'F2L',
    true
  );
}

function showBothUpWhiteUpBlock() {
  hideF2LAll();
  addClass('btn-bothUp-whiteUp-block-F2L', 'buyActive');
  showHideSection([14, 17, 18, 21], '', false);
}

function showBothUpWhiteFrontBlock() {
  hideF2LAll();
  addClass('btn-bothUp-whiteFront-block-F2L', 'buyActive');
  showHideSection([24, 27, 28, 31], '', false);
}

function showBothUpWhiteRightBlock() {
  hideF2LAll();
  addClass('btn-bothUp-whiteRight-block-F2L', 'buyActive');
  showHideSection([34, 37, 38, 41], '', false);
}

/* ===== OLL ===== */
function showOLLAll() {
  removeClassAllBtn_OLL();
  addClass('btn-showAll-OLL', 'buyActive');
  addClass('img-showAll-OLL', 'selectedImg');
  showHideSection(
    [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
      40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57,
    ],
    'OLL',
    false
  );
}

function hideOLLAll() {
  removeClassAllBtn_OLL();
  showHideSection(
    [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
      40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57,
    ],
    'OLL',
    true
  );
}

function showOLL1piece() {
  hideOLLAll();
  addClass('btn-OLL-1piece', 'buyActive');
  addClass('img-OLL_1piece', 'selectedImg');
  showHideSection([1, 2], 'OLL', false);
}

function showOLL2piece() {
  hideOLLAll();
  addClass('btn-OLL-2piece', 'buyActive');
  addClass('img-OLL_2piece', 'selectedImg');
  showHideSection([3, 4], 'OLL', false);
}

function showOLL3piece() {
  hideOLLAll();
  addClass('btn-OLL-3piece', 'buyActive');
  addClass('img-OLL_3piece', 'selectedImg');
  showHideSection(
    [17, 18, 19, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
    'OLL',
    false
  );
}

function showOLL4piece() {
  hideOLLAll();
  addClass('btn-OLL-4piece', 'buyActive');
  addClass('img-OLL_4piece', 'selectedImg');
  showHideSection([5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16], 'OLL', false);
}

function showOLL5piece() {
  hideOLLAll();
  addClass('btn-OLL-5piece', 'buyActive');
  addClass('img-OLL_5piece', 'selectedImg');
  showHideSection(
    [
      20, 21, 22, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
      44, 45, 46,
    ],
    'OLL',
    false
  );
}

function showOLL3missing() {
  hideOLLAll();
  addClass('btn-OLL-3missing', 'buyActive');
  addClass('img-OLL-3missing', 'selectedImg');
  showHideSection([26, 27], 'OLL', false);
}

function showOLL2missing() {
  hideOLLAll();
  addClass('btn-OLL-2missing', 'buyActive');
  addClass('img-OLL-2missing', 'selectedImg');
  showHideSection([23, 24, 25, 28, 57], 'OLL', false);
}

// function showOLL_Lshape() {
// 	hideOLLAll()
// 	addClass('btn-OLL_Lshape', 'buyActive');
// 	showHideSection([13, 14, 15, 16, 47, 48, 49, 50, 53, 54], 'OLL', false)
// }

function showOLL_Tshape() {
  hideOLLAll();
  addClass('btn-OLL_Tshape', 'buyActive');
  addClass('img-OLL_Tshape', 'selectedImg');
  showHideSection([33, 45], 'OLL', false);
}

function showOLL_Lineshape() {
  hideOLLAll();
  addClass('btn-OLL_Lineshape', 'buyActive');
  addClass('img-OLL_Lineshape', 'selectedImg');
  showHideSection([17, 51, 52, 55, 56], 'OLL', false);
}

function showOLL_Crossshape() {
  hideOLLAll();
  addClass('btn-OLL_Crossshape', 'buyActive');
  addClass('img-OLL_Crossshape', 'selectedImg');
  showHideSection([21, 22], 'OLL', false);
}

function showOLL_Lshape1() {
  hideOLLAll();
  addClass('img-OLL_Lshape1', 'selectedImg');
  showHideSection([13, 14, 15, 16], 'OLL', false);
}

function showOLL_Lshape2() {
  hideOLLAll();
  addClass('img-OLL_Lshape2', 'selectedImg');
  showHideSection([47, 48, 49, 50, 53, 54], 'OLL', false);
}

function showOLL_Rocketshape() {
  hideOLLAll();
  addClass('img-OLL_Rocketshape', 'selectedImg');
  showHideSection([29, 30, 41, 42], 'OLL', false);
}

function showOLL_Sshape() {
  hideOLLAll();
  addClass('img-OLL_Sshape', 'selectedImg');
  showHideSection([7, 8, 11, 12], 'OLL', false);
}

function showOLL_buildingshape() {
  hideOLLAll();
  addClass('img-OLL_buildingshape', 'selectedImg');
  showHideSection([31, 32, 43, 44], 'OLL', false);
}

function showOLL_Cshape() {
  hideOLLAll();
  addClass('img-OLL_Cshape', 'selectedImg');
  showHideSection([34, 46], 'OLL', false);
}

function showOLL_toLearn() {
  hideOLLAll();
  addClass('img-learn-40', 'selectedImg');
  showHideSection(
    [9, 10, 15, 16, 19, 31, 35, 37, 38, 40, 46, 52, 54, 55, 56],
    'OLL',
    false
  );
}

function showHideSection(arrOfIds, section, hide) {
  console.log(hide);

  if (typeof hide == 'undefined' || hide == null) {
    hide = true;
  }

  var prefix = 'F2L-';
  var postfix1 = '-IMG';
  var postfix2 = '-P';
  var postfix3 = '-DIV';

  if (section == 'OLL') prefix = 'OLL-';

  for (var i = 0; i < arrOfIds.length; i++) {
    var leadingZero = '';
    if (arrOfIds[i] < 10) leadingZero = '0';

    if (hide) {
      hideElem(prefix + leadingZero + arrOfIds[i].toString() + postfix1);
      hideElem(prefix + leadingZero + arrOfIds[i].toString() + postfix2);
      hideElem(prefix + leadingZero + arrOfIds[i].toString() + postfix3);
    } else {
      showElem(prefix + leadingZero + arrOfIds[i].toString() + postfix1);
      showElem(prefix + leadingZero + arrOfIds[i].toString() + postfix2);
      showElem(prefix + leadingZero + arrOfIds[i].toString() + postfix3);
    }
  }
}

function showElem(id) {
  var elem = document.getElementById(id);
  if (typeof elem != 'undefined' && elem != null) elem.hidden = false;
}

function hideElem(id) {
  var elem = document.getElementById(id);
  if (typeof elem != 'undefined' && elem != null) elem.hidden = true;
}

function removeClass(id, classname) {
  var elem = document.getElementById(id);
  if (typeof elem != 'undefined' && elem != null)
    elem.classList.remove(classname);
}

function addClass(id, classname) {
  var elem = document.getElementById(id);
  if (typeof elem != 'undefined' && elem != null) elem.classList.add(classname);
}

function removeClassAllBtn() {
  removeClass('btn-hideAll-F2L', 'buyActive');
  removeClass('btn-showAll-F2L', 'buyActive');
  removeClass('btn-bothDown-F2L', 'buyActive');
  removeClass('btn-upAndDown-F2L', 'buyActive');
  removeClass('btn-upAndMid-F2L', 'buyActive');
  removeClass('btn-bothUp-F2L', 'buyActive');
  removeClass('btn-bothUpWhiteUp-F2L', 'buyActive');
  removeClass('btn-bothUpWhiteFront-F2L', 'buyActive');
  removeClass('btn-bothUpWhiteSide-F2L', 'buyActive');
  removeClass('btn-bothUpBlock-F2L', 'buyActive');
  removeClass('btn-bothUp-whiteUp-block-F2L', 'buyActive');
  removeClass('btn-bothUp-whiteFront-block-F2L', 'buyActive');
  removeClass('btn-bothUp-whiteRight-block-F2L', 'buyActive');
}

function removeClassAllBtn_OLL() {
  removeClass('btn-showAll-OLL', 'buyActive');
  removeClass('btn-OLL_Lineshape', 'buyActive');
  removeClass('btn-OLL_Crossshape', 'buyActive');
  removeClass('btn-OLL_Lshape', 'buyActive');
  removeClass('btn-OLL_Tshape', 'buyActive');
  removeClass('btn-OLL-3missing', 'buyActive');
  removeClass('btn-OLL-2missing', 'buyActive');
  removeClass('btn-OLL-1piece', 'buyActive');
  removeClass('btn-OLL-2piece', 'buyActive');
  removeClass('btn-OLL-3piece', 'buyActive');
  removeClass('btn-OLL-4piece', 'buyActive');
  removeClass('btn-OLL-5piece', 'buyActive');

  removeClass('img-showAll-OLL', 'selectedImg');

  removeClass('img-OLL_1piece', 'selectedImg');
  removeClass('img-OLL_2piece', 'selectedImg');
  removeClass('img-OLL_3piece', 'selectedImg');
  removeClass('img-OLL_4piece', 'selectedImg');
  removeClass('img-OLL_5piece', 'selectedImg');

  removeClass('img-OLL-3missing', 'selectedImg');
  removeClass('img-OLL-2missing', 'selectedImg');

  removeClass('img-OLL_Lineshape', 'selectedImg');
  removeClass('img-OLL_Lshape1', 'selectedImg');
  removeClass('img-OLL_Lshape2', 'selectedImg');
  removeClass('img-OLL_Rocketshape', 'selectedImg');
  removeClass('img-OLL_Sshape', 'selectedImg');
  removeClass('img-OLL_buildingshape', 'selectedImg');
  removeClass('img-OLL_Tshape', 'selectedImg');
  removeClass('img-OLL_Crossshape', 'selectedImg');
  removeClass('img-OLL_Cshape', 'selectedImg');
  removeClass('img-learn-40', 'selectedImg');
}
</script>

<style scoped>
.buyActive {
  /* background-color: #28a745 !important; */
  background-color: navy !important;
  /*font-weight: bolder;*/
  color: blue !important;
}
.scramble {
  /* color: #f08200 !important; */
  /* color: #d636db !important; */
  color: rgb(62, 8, 161);
  padding-top: 20px;
}

.scramblok {
  /* color: #f08200 !important; */
  /* color: #d636db !important; */
  background-color: rgba(183, 255, 0, 0.447) !important;
  padding: 2px !important;
  color: black;
}

.scramble_orange {
  /* color: #f08200 !important; */
  /* color: #d636db !important; */
  background-color: rgba(251, 122, 2, 0.578) !important;
  padding: 2px !important;
  color: black;
}

.scramble_learn {
  background-color: rgba(251, 2, 2, 0.579) !important;
  padding: 2px !important;
  color: black;
}

.rotate90 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.img_btn {
  vertical-align: middle;
  display: table-cell;
  margin-right: 6px !important;
}

.img_span {
  /* vertical-align: middle; */
  display: table-cell;
}

.selectedImg {
  background-color: hsla(206, 87%, 61%, 0.65);

  /* background-color: hsla(33, 100%, 47%, 0.5); */
}

.exampleImg {
  padding-bottom: 20px;
}

img {
  margin-right: 10px;
}

.white-text {
  color: #eee;
}

.white-text:hover {
  color: #44a8f2;
}

.section {
  margin: 0 auto 20px;
  padding: 16px 0px;
}

.grid-container {
  display: grid; /* Enables grid layout */
  grid-template-columns: repeat(
    4,
    1fr
  ); /* 3 columns, each taking equal space */
  gap: 10px; /* Adds spacing between grid items */
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  letter-spacing: 3px;
}

.grid-container7 {
  display: grid; /* Enables grid layout */
  grid-template-columns: repeat(
    7,
    1fr
  ); /* 3 columns, each taking equal space */
  gap: 10px; /* Adds spacing between grid items */
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  letter-spacing: 3px;
}

.grid-item {
  background-color: #f4fbfe;
  padding: 10px; /* Adds padding inside each grid item */
  text-align: center; /* Centers text inside grid items */
  color: #f08200;
  border: 2px solid #f08200;
  font-weight: bold;
  border-radius: 6px;
}

/* .grid-item .orderNum {
  font-size: 16px;
} */

.grid-title {
  color: red;
  padding-bottom: 10px;
}

@media (max-width: 800px) {
  .grid-container {
    grid-template-columns: 1fr; /* Single column layout for smaller screens */
  }
}

.mainColor {
  color: #f08200;
}

.mainColor2 {
  color: #0075ce;
}

.mainColor3 {
  color: #e50012;
}

.exampleText {
  color: black;
  margin-bottom: 1px !important;
}

.exampleTextNormal {
  color: black;
  font-weight: normal;
}

a {
  color: #f08200;
}

/* html {
  scroll-behavior: smooth;
} */
</style>
