//import 'bootstrap/dist/css/bootstrap.min.css';

import 'bootstrap';
import '@vuepic/vue-datepicker/dist/main.css';

import { createApp, defineAsyncComponent, markRaw } from 'vue';
import {
  faCircleArrowLeft,
  faCircleArrowRight,
  faFilter,
  faFloppyDisk,
  faHouse,
  faMagnifyingGlass,
  faPenToSquare,
  faArrowTurnDown,
  faSquarePlus,
  faSquareMinus,
  faTableCells,
  faTrash,
  faUser,
  faUserSecret,
} from '@fortawesome/free-solid-svg-icons';

import App from './App.vue';
import BaseBadge from './components/ui/BaseBadge.vue';
import BaseButton from './components/ui/BaseButton.vue';
import BaseCard from './components/ui/BaseCard.vue';
import BaseDatePicker from './components/ui/BaseDatePicker.vue';
import BaseSpinner from './components/ui/BaseSpinner.vue';
import BaseTextSearch from './components/ui/BaseTextSearch.vue';
import BaseToast from './components/ui/BaseToast.vue';
import BaseYearSelector from './components/ui/BaseYearSelector.vue';
import CandyTheme from 'fusioncharts/themes/fusioncharts.theme.candy';
import Charts from 'fusioncharts/fusioncharts.charts';
import Datepicker from '@vuepic/vue-datepicker';
import { FCComponent } from 'vue-fusioncharts';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import FusionCharts from 'fusioncharts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import OceanTheme from 'fusioncharts/themes/fusioncharts.theme.ocean';
import VueFusionCharts from 'vue-fusioncharts';
import { createPinia } from 'pinia';
import { faFileZipper } from '@fortawesome/free-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import router from './router.js';

//import Bootstrap from 'bootstrap';

// import i18n from './i18n/index.js';

// import PrimeVue from 'primevue/config';
// import 'primevue/resources/themes/lara-dark-green/theme.css';
// import Button from 'primevue/button';
// import Toast from 'primevue/toast';
// import ToastService from 'primevue/toastservice';

//import store from './store/index.js';

const BaseDialog = defineAsyncComponent(() =>
  import('./components/ui/BaseDialog.vue')
);

Charts(FusionCharts);

/* add icons to the library */
library.add(
  faUserSecret,
  faUser,
  faHouse,
  faCircleArrowLeft,
  faCircleArrowRight,
  faTableCells,
  faTrash,
  faPenToSquare,
  faArrowTurnDown,
  faFloppyDisk,
  faMagnifyingGlass,
  faUserSecret,
  faSquarePlus,
  faSquareMinus,
  faFileZipper,
  faFilter
);

const pinia = createPinia();

const app = createApp(App);

// app.config.errorHandler = function (err, vm, info) {
//   console.error(`Error: ${err.toString()}\nInfo: ${info}`);
// };

// app.use(PrimeVue);
// app.use(ToastService);

app.use(
  VueFusionCharts,
  FusionCharts,
  Charts,
  FusionTheme,
  OceanTheme,
  CandyTheme
);
//app.use(FusionCharts, Charts);

pinia.use(({ store }) => {
  store.$router = markRaw(router);
});

app.use(router);
app.use(pinia);
//app.use(store);
//app.use(i18n);
//app.use(Bootstrap);

app.component('font-awesome-icon', FontAwesomeIcon);
app.component('base-card', BaseCard);
app.component('base-button', BaseButton);
app.component('base-badge', BaseBadge);
app.component('base-spinner', BaseSpinner);
app.component('base-text-search', BaseTextSearch);
app.component('base-toast', BaseToast);
app.component('base-date-picker', BaseDatePicker);
app.component('base-dialog', BaseDialog);
app.component('base-year-selector', BaseYearSelector);
app.component('fusioncharts', FCComponent);
app.component('Datepicker', Datepicker);

// app.component('Button', Button);
// app.component('Toast', Toast);

app.mount('#app');
