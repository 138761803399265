<template>
  <div class="jr-dark">
    <!-- copy from analysis.html Fin2g -->

    <pre style="color: white">{{ arrTotalIncomes }}</pre>

    <!-- <pre style="color: white">{{ arrLvl1Display }}</pre> -->
    <pre style="color: white">{{ tableDataIncome }}</pre>

    <div>
      <!-- <pre>{{ tableDataExpence }}</pre> -->
      <!-- <pre>{{ arrRawDataDisplayExpence }}</pre> -->
      <!-- <pre>{{ arrLvl1Display }}</pre> -->
      <!-- <pre>{{ filterIncomesRaw }}</pre> -->
    </div>

    <!-- #region == BUTTONS WITH YEARS & MONTHS == -->
    <div class="row">
      <div class="col-md-12">
        <!-- select year -->
        <div class="btn-group" role="group" aria-label="...">
          <button type="button" class="btn btn-warning" @click="changeYear(-1)">
            <font-awesome-icon icon="fa-solid fa-circle-arrow-left" />
          </button>

          <button
            type="button"
            class="btn btn-warning"
            :class="getClassSelected(getYear.previous, 'Y')"
            @click="selectYear(getYear.previous)"
          >
            {{ getYear.previous }}
          </button>

          <button
            type="button"
            class="btn btn-warning"
            :class="getClassSelected(getYear.current, 'Y')"
            @click="selectYear(getYear.current)"
          >
            {{ getYear.current }}
          </button>

          <button
            type="button"
            class="btn btn-warning"
            :class="getClassSelected(getYear.next, 'Y')"
            @click="selectYear(getYear.next)"
          >
            {{ getYear.next }}
          </button>
          <button type="button" class="btn btn-warning" @click="changeYear(1)">
            <font-awesome-icon icon="fa-solid fa-circle-arrow-right" />
          </button>
        </div>

        <!-- select month -->
        <div class="btn-group ms-2" role="group" aria-label="...">
          <button
            type="button"
            class="btn btn-warning"
            :class="getClassSelected('13', 'M')"
            @click="selectMonth('13')"
          >
            <span translate=".allMonths">All months</span>
          </button>
          <button
            type="button"
            class="btn btn-warning"
            :class="getClassSelected('01', 'M')"
            @click="selectMonth('01')"
          >
            1
          </button>
          <button
            type="button"
            class="btn btn-warning"
            :class="getClassSelected('02', 'M')"
            @click="selectMonth('02')"
          >
            2
          </button>
          <button
            type="button"
            class="btn btn-warning"
            :class="getClassSelected('03', 'M')"
            @click="selectMonth('03')"
          >
            3
          </button>
          <button
            type="button"
            class="btn btn-warning"
            :class="getClassSelected('04', 'M')"
            @click="selectMonth('04')"
          >
            4
          </button>
          <button
            type="button"
            class="btn btn-warning"
            :class="getClassSelected('05', 'M')"
            @click="selectMonth('05')"
          >
            5
          </button>
          <button
            type="button"
            class="btn btn-warning"
            :class="getClassSelected('06', 'M')"
            @click="selectMonth('06')"
          >
            6
          </button>
          <button
            type="button"
            class="btn btn-warning"
            :class="getClassSelected('07', 'M')"
            @click="selectMonth('07')"
          >
            7
          </button>
          <button
            type="button"
            class="btn btn-warning"
            :class="getClassSelected('08', 'M')"
            @click="selectMonth('08')"
          >
            8
          </button>
          <button
            type="button"
            class="btn btn-warning"
            :class="getClassSelected('09', 'M')"
            @click="selectMonth('09')"
          >
            9
          </button>
          <button
            type="button"
            class="btn btn-warning"
            :class="getClassSelected('10', 'M')"
            @click="selectMonth('10')"
          >
            10
          </button>
          <button
            type="button"
            class="btn btn-warning"
            :class="getClassSelected('11', 'M')"
            @click="selectMonth('11')"
          >
            11
          </button>
          <button
            type="button"
            class="btn btn-warning"
            :class="getClassSelected('12', 'M')"
            @click="selectMonth('12')"
          >
            12
          </button>
          <button
            type="button"
            class="btn btn-warning jr-savings"
            style="font-weight: bolder"
          >
            <span translate=".savingsFor">Savings for</span> {{ yearmonth }}:
            {{ appUtils.formatCurrency(sumSavings) }}
          </button>
        </div>

        <!-- select chart type -->
        <div class="btn-group ms-2" role="group" aria-label="...">
          <button
            type="button"
            class="btn btn-warning"
            :class="getClassSelected('pie2d', 'G')"
            @click="chartTypeVal = 'pie2d'"
          >
            <span>pie2d</span>
          </button>
          <button
            type="button"
            class="btn btn-warning"
            :class="getClassSelected('column2d', 'G')"
            @click="chartTypeVal = 'column2d'"
          >
            <span>column2d</span>
          </button>
        </div>
      </div>
    </div>
    <!-- #endregion -->

    <br />

    <!-- #region == CHARTS == -->

    <div
      class="accordion accordion-flush mb-3"
      id="accordionPanelsStayOpenExample"
    >
      <div class="accordion-item bg-dark">
        <h2 class="accordion-header" id="panelsStayOpen-headingOne">
          <button
            class="accordion-button bg-dark"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#panelsStayOpen-collapseOne"
            aria-expanded="true"
            aria-controls="panelsStayOpen-collapseOne"
          >
            <h4 class="text-warning">Chart</h4>
          </button>
        </h2>
        <div
          id="panelsStayOpen-collapseOne"
          class="accordion-collapse collapse show"
          aria-labelledby="panelsStayOpen-headingOne"
        >
          <div class="accordion-body">
            <div class="row">
              <div class="col-md-6">
                <fusioncharts
                  :type="chartType"
                  :showPercentInToolTip="0"
                  :showPercentValues="0"
                  :width="width"
                  :height="500"
                  :dataFormat="'json'"
                  :dataSource="chartDataIncomes"
                  @dataPlotClick="eventsIncome"
                ></fusioncharts>
              </div>
              <!-- datasource="{{chartDataIncome}}" -->

              <div class="col-md-6">
                <fusioncharts
                  :type="chartType"
                  :showPercentInToolTip="0"
                  :showPercentValues="0"
                  :width="width"
                  :height="500"
                  :dataFormat="'json'"
                  :dataSource="chartDataExpenses"
                  @dataPlotClick="eventsExpense"
                ></fusioncharts>
                <!-- datasource="{{chartData}}" -->
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="accordion-item bg-dark">
        <h2 class="accordion-header" id="flush-headingOne">
          <button
            class="accordion-button collapsed bg-dark"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseOne"
            aria-expanded="true"
            aria-controls="flush-collapseOne"
          >
            <h4 class="text-warning">Data</h4>
          </button>
        </h2>
        <div
          id="flush-collapseOne"
          class="accordion-collapse collapse show"
          aria-labelledby="flush-headingOne"
          data-bs-parent="#accordionFlushExample"
        >
          <div class="accordion-body">
            <div class="row">
              <!-- #region == INCOMES == -->
              <!-- INCOMES -->
              <div class="col-md-6">
                <div
                  v-if="tableDataIncome.length > 0"
                  class="panel panel-primary"
                >
                  <div class="panel-heading">
                    <i class="fa fa-table"></i
                    ><span translate=".analysisIncome"> Analysis</span>
                    <!-- <button class="btn btn-success btn-xs pull-right" @click="getDataAnalysis()"><span translate="general.refresh">Refresh </span><i class="fa fa-refresh"></i></button>-->
                  </div>

                  <div class="panel-body">
                    <!-- <caption> -->
                    <span translate="general.number_of_rec"
                      >Existing Items</span
                    >
                    ({{ tableDataIncome.length }})
                    <!-- </caption> -->

                    <table class="table table-striped table-dark">
                      <thead>
                        <tr>
                          <th>
                            <a
                              :href="null"
                              class="jr-link"
                              @click="fOrder('description', 'incomes')"
                              >Category</a
                            >
                          </th>
                          <th style="vertical-align: middle; text-align: right">
                            <a
                              :href="null"
                              class="jr-link"
                              @click="fOrder('x13', 'incomes')"
                              translate="general.amount"
                              >Amount</a
                            >
                          </th>
                          <th style="vertical-align: middle; text-align: right">
                            <a
                              :href="null"
                              class="jr-link"
                              @click="fOrder('x13', 'incomes')"
                              translate="general.percent"
                              >Percent</a
                            >
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <!-- <tr ng-repeat="item in tableDataIncome | orderBy: gridSortOrder.incomes"> -->
                        <tr v-for="item in tableDataIncome" :key="item.guid">
                          <td>
                            <a
                              class="jr-link"
                              :href="null"
                              @click="getIncomeExpenseRaw(item.guid)"
                              >{{ item.description }}</a
                            >
                          </td>
                          <td style="vertical-align: middle; text-align: right">
                            {{ appUtils.formatCurrency(item.currency) }}
                          </td>
                          <td style="vertical-align: middle; text-align: right">
                            {{ item.percent }} %
                          </td>
                        </tr>

                        <tr>
                          <th></th>
                          <th style="vertical-align: middle; text-align: right">
                            <span translate="general.sum">Sum:</span>
                            {{ appUtils.formatCurrency(sumTotalIncome) }}
                          </th>
                          <th style="vertical-align: middle; text-align: right">
                            <span translate="general.sum">Sum:</span>
                            {{ sumTotalIncomePercent }} %
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <!-- SHOWING SUBCATEGORIES (INCOMES) -->
                <div
                  v-if="tableDataIncomeCategory2.length > 1"
                  class="panel panel-primary"
                >
                  <div class="panel-heading">
                    <i class="fa fa-table"></i
                    ><span translate=".analysisIncome"> Analysis</span
                    ><span>{{ incomeCategory }}</span>
                  </div>
                  <div class="panel-body">
                    <table class="table table-striped table-responsive">
                      <caption>
                        <span translate="general.number_of_rec"
                          >number_of_rec</span
                        >
                        ({{
                          tableDataIncomeCategory2.length
                        }})
                      </caption>
                      <thead>
                        <tr>
                          <th>
                            <a
                              @click="fOrder('descknt1', 'incomes2')"
                              href
                              translate="html.tblMain.category1"
                              >category1</a
                            >
                          </th>
                          <th>
                            <a
                              @click="fOrder('descknt2', 'incomes2')"
                              href
                              translate="html.tblMain.category2"
                              >category2</a
                            >
                          </th>
                          <th style="text-align: right">
                            <a
                              @click="fOrder('sumamount', 'incomes2')"
                              href
                              translate="general.amount"
                              >Amount</a
                            >
                          </th>
                        </tr>
                      </thead>

                      <tr
                        ng-repeat="item in tableDataIncomeCategory2 | orderBy: gridSortOrder.incomes2"
                      >
                        <td style="vertical-align: middle">
                          {{ item.descknt1 }}
                        </td>
                        <td style="vertical-align: middle">
                          {{ item.descknt2 }}
                        </td>
                        <td style="vertical-align: middle; text-align: right">
                          {{ appUtils.formatCurrency(item.sumamount) }}
                        </td>
                      </tr>

                      <tr>
                        <th></th>
                        <th></th>
                        <th style="vertical-align: middle; text-align: right">
                          <span translate="general.sum">Total:</span>
                          {{ appUtils.formatCurrency(sumIncomeCategory2) }}
                        </th>
                      </tr>
                    </table>
                  </div>
                </div>

                <!-- SHOWING RAW DATA FROM DB (INCOMES) -->
                <input
                  type="text"
                  class="form-control"
                  v-model="filterIncomesRaw"
                  placeholder="Filter..."
                />

                <div
                  v-if="tableDataShowIncome.length > 0"
                  class="panel panel-primary"
                >
                  <div class="panel-heading">
                    <i class="fa fa-table"></i>

                    <!--<i class="fa fa-table"></i><span translate=".analysisIncome"> Analysis</span><span>{{incomeCategory}}</span>-->
                  </div>

                  <div class="panel-body">
                    <table class="table table-striped table-dark">
                      <!-- filtering on client -->

                      <caption>
                        <span translate="general.number_of_rec"
                          >Number of records</span
                        >
                        ({{
                          tableDataShowIncome.length
                        }})
                      </caption>

                      <thead>
                        <tr>
                          <th>
                            <base-link
                              :fieldName="'rawDate'"
                              :fieldTitle="'Date'"
                              @sort-data="sortDataIncomes"
                            ></base-link>
                          </th>
                          <th>
                            <base-link
                              :fieldName="'accountDesc'"
                              :fieldTitle="'Account'"
                              @sort-data="sortDataIncomes"
                            ></base-link>
                          </th>
                          <th>
                            <base-link
                              :fieldName="'description'"
                              :fieldTitle="'Description'"
                              @sort-data="sortDataIncomes"
                            ></base-link>
                          </th>
                          <th>
                            <base-link
                              :fieldName="'categoryDescFull'"
                              :fieldTitle="'Category'"
                              @sort-data="sortDataIncomes"
                            ></base-link>
                          </th>
                          <th style="text-align: right">
                            <base-link
                              :fieldName="'amount'"
                              :fieldTitle="'Amount'"
                              @sort-data="sortDataIncomes"
                            ></base-link>
                          </th>
                        </tr>
                      </thead>

                      <!-- <thead>
                        <tr>
                          <th>
                            <base-link
                              :fieldName="'date'"
                              :fieldTitle="'Date'"
                              @sort-data="sortDataIncomes"
                            ></base-link>
                          </th>
                          <th>
                            <base-link
                              :fieldName="'accountDesc'"
                              :fieldTitle="'Account'"
                              @sort-data="sortDataIncomes"
                            ></base-link>
                          </th>
                          <th>
                            <a class="jr-link" :href="null">Description</a>
                          </th>
                          <th>
                            <a class="jr-link" :href="null">Category</a>
                          </th>
                          <th style="text-align: right">
                            <a class="jr-link" :href="null">Amount</a>
                          </th>
                        </tr>
                      </thead> -->

                      <tbody>
                        <tr
                          v-for="item in tableDataShowIncome"
                          :key="item.guid"
                        >
                          <!-- <tr
                ng-repeat="item in tableDataShowIncome | orderBy: gridSortOrder.incomesDetails | filter: criteriaMatch(criteriaIncomes,'income')"
              > -->
                          <td style="vertical-align: middle">
                            {{ item.date }}
                          </td>
                          <td style="vertical-align: middle">
                            {{ item.accountDesc }}
                          </td>
                          <td style="vertical-align: middle">
                            {{ item.description }}
                          </td>
                          <td style="vertical-align: middle">
                            {{ item.categoryDescFull }}
                          </td>
                          <td style="vertical-align: middle; text-align: right">
                            {{ appUtils.formatCurrency(item.amount) }}
                          </td>
                        </tr>

                        <tr>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th style="vertical-align: middle; text-align: right">
                            <span translate="general.sum">Total:</span>
                            {{ appUtils.formatCurrency(sumIncomesRawData) }}
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <!-- #endregion -->

              <!-- #region == EXPENSES == -->
              <!-- EXPENSES -->
              <div class="col-md-6">
                <div
                  v-if="tableDataExpence.length > 0"
                  class="panel panel-primary"
                >
                  <div class="panel-heading">
                    <i class="fa fa-table"></i
                    ><span translate=".analysisExpense"> Analysis</span>
                  </div>

                  <div class="panel-body">
                    <!-- <caption> -->
                    <span translate="general.number_of_rec"
                      >Existing Items</span
                    >
                    ({{ tableDataExpence.length }})
                    <!-- </caption> -->
                    <table class="table table-striped table-dark">
                      <thead>
                        <tr>
                          <th>
                            <a
                              :href="null"
                              class="jr-link"
                              @click="fOrder('description', 'expenses')"
                              >Category</a
                            >
                          </th>

                          <th style="vertical-align: middle; text-align: right">
                            <a
                              :href="null"
                              class="jr-link"
                              @click="fOrder('x13', 'expenses')"
                              >Amount</a
                            >
                          </th>
                          <th style="vertical-align: middle; text-align: right">
                            <a
                              :href="null"
                              class="jr-link"
                              @click="fOrder('x13', 'expenses')"
                              translate="general.percent"
                              >Percent</a
                            >
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in tableDataExpence" :key="item.guid">
                          <td>
                            <a
                              class="jr-link"
                              :href="null"
                              @click="getIncomeExpenseRaw(item.guid)"
                              >{{ item.description }}</a
                            >
                          </td>
                          <td style="vertical-align: middle; text-align: right">
                            {{ appUtils.formatCurrency(item.currency) }}
                          </td>
                          <td style="vertical-align: middle; text-align: right">
                            {{ item.percent }} %
                          </td>
                        </tr>

                        <tr>
                          <th></th>
                          <th style="vertical-align: middle; text-align: right">
                            <span translate="general.sum">Sum:</span>
                            {{ appUtils.formatCurrency(sumTotalExpence) }}
                          </th>
                          <th style="vertical-align: middle; text-align: right">
                            <span translate="general.sum">Sum:</span>
                            {{ sumTotalExpencePercent }} %
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <!-- SHOWING SUBCATEGORIES (EXPENSES) -->
                <div
                  v-if="tableDataCategory2.length > 1"
                  class="panel panel-primary"
                >
                  <div class="panel-heading">
                    <i class="fa fa-table"></i
                    ><span translate=".analysisExpense"> Analysis</span
                    ><span>{{ expenseCategory }}</span>
                  </div>
                  <div class="panel-body">
                    <table class="table table-striped table-responsive">
                      <caption>
                        <span translate="general.number_of_rec"
                          >number_of_rec</span
                        >
                        ({{
                          tableDataCategory2.length
                        }})
                      </caption>
                      <thead>
                        <tr>
                          <th>
                            <a
                              @click="fOrder('descknt1', 'expenses2')"
                              href
                              translate="html.tblMain.category1"
                              >category1</a
                            >
                          </th>
                          <th>
                            <a
                              @click="fOrder('descknt2', 'expenses2')"
                              href
                              translate="html.tblMain.category2"
                              >category2</a
                            >
                          </th>
                          <th style="text-align: right">
                            <a
                              @click="fOrder('sumamount', 'expenses2')"
                              href
                              translate="general.amount"
                              >Amount</a
                            >
                          </th>
                        </tr>
                      </thead>

                      <tr
                        ng-repeat="item in tableDataCategory2 | orderBy: gridSortOrder.expenses2"
                      >
                        <td style="vertical-align: middle">
                          {{ item.descknt1 }}
                        </td>
                        <td style="vertical-align: middle">
                          {{ item.descknt2 }}
                        </td>
                        <td style="vertical-align: middle; text-align: right">
                          {{ appUtils.formatCurrency(item.sumamount) }}
                        </td>
                      </tr>

                      <tr>
                        <th></th>
                        <th></th>
                        <th style="vertical-align: middle; text-align: right">
                          <span translate="general.sum">Total:</span>
                          {{ appUtils.formatCurrency(sumExpenseCategory2) }} €
                        </th>
                      </tr>
                    </table>
                  </div>
                </div>

                <!-- SHOWING RAW DATA FROM DB (EXPENSES) -->
                <input
                  type="text"
                  class="form-control"
                  v-model="filterExpencesRaw"
                  placeholder="Filter..."
                />
                <div
                  v-if="tableDataShow.length > 0"
                  class="panel panel-primary"
                >
                  <div class="panel-heading">
                    <i class="fa fa-table"></i>
                    <!-- <span>{{ gridCaptionObj.expensesRawData }}</span> -->

                    <!--<i class="fa fa-table"></i><span translate=".analysisExpense"> Analysis</span><span>{{expenseCategory}}</span>-->
                  </div>
                  <div class="panel-body">
                    <table class="table table-striped table-dark">
                      <!-- filtering on client -->

                      <caption>
                        <span translate="general.number_of_rec"
                          >Number of records</span
                        >
                        ({{
                          tableDataShow.length
                        }})
                      </caption>
                      <thead>
                        <tr>
                          <th>
                            <base-link
                              :fieldName="'rawDate'"
                              :fieldTitle="'Date'"
                              @sort-data="sortDataExpences"
                            ></base-link>
                          </th>
                          <th>
                            <base-link
                              :fieldName="'accountDesc'"
                              :fieldTitle="'Account'"
                              @sort-data="sortDataExpences"
                            ></base-link>
                          </th>
                          <th>
                            <base-link
                              :fieldName="'description'"
                              :fieldTitle="'Description'"
                              @sort-data="sortDataExpences"
                            ></base-link>
                          </th>
                          <th>
                            <base-link
                              :fieldName="'categoryDescFull'"
                              :fieldTitle="'Category'"
                              @sort-data="sortDataExpences"
                            ></base-link>
                          </th>
                          <th style="text-align: right">
                            <base-link
                              :fieldName="'amount'"
                              :fieldTitle="'Amount'"
                              @sort-data="sortDataExpences"
                            ></base-link>
                          </th>
                        </tr>
                      </thead>
                      <!-- 
              <tr
                ng-repeat="item in tableDataShow | orderBy: gridSortOrder.expensesDetails | filter: criteriaMatch(criteriaExpenses,'expense')"
              > -->
                      <tbody>
                        <tr v-for="item in tableDataShow" :key="item.guid">
                          <td style="vertical-align: middle">
                            {{ item.date }}
                          </td>
                          <td style="vertical-align: middle">
                            {{ item.accountDesc }}
                          </td>
                          <td style="vertical-align: middle">
                            {{ item.description }}
                          </td>
                          <td style="vertical-align: middle">
                            {{ item.categoryDescFull }}
                          </td>
                          <td style="vertical-align: middle; text-align: right">
                            {{ appUtils.formatCurrency(item.amount) }}
                          </td>
                        </tr>
                        <tr>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th style="vertical-align: middle; text-align: right">
                            <span translate="general.sum">Total:</span>
                            {{ appUtils.formatCurrency(sumExpencesRawData) }}
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <!-- #endregion -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- #endregion -->
  </div>
</template>

<script setup>
import { ref, reactive, onBeforeMount } from 'vue';
import { computed } from '@vue/runtime-core';
import appUtils from '../../components/utils/appUtils.js';
import utilsAnalysis from '../../components/utils/utilsAnalysis.js';
import { useTransStore } from '../../stores/TransStore';
import { useAccountStore } from '../../stores/AccountStore';
import BaseLink from '../../components/ui/BaseLink.vue';

const currentOffset = ref(0);
let year = reactive({});
let month = ref('13');
let sumSavings = ref(0);

// const monthMonitor = new Proxy(month, {
//   set(target, key, value) {
//     console.log(`Setting ${key} to ${value}`);
//     return Reflect.set(target, key, value);
//   },
// });

let chartTypeVal = ref('pie2d');
let chartDataSource = reactive({});
let width = '100%';

let arrDataYearLvl1 = reactive([]);
let arrLvl1Display = reactive([]);

// let arrRawDataDisplayExpence = reactive([]);
// let arrRawDataDisplayIncome = reactive([]);

//let arrTotalIncomes = reactive({});
// let arrTotalExpenses = reactive({});
// let arrTotalSavings = reactive({});

// let arrDataYearLvl1 = [];
// let arrLvl1Display = [];

let arrRawDataDisplayExpence = [];
let arrRawDataDisplayIncome = [];

let arrTotalIncomes = {};
let arrTotalExpenses = {};
let arrTotalSavings = {};

let sumTotalIncome = ref(0);
let sumTotalExpence = ref(0);
let sumTotalIncomePercent = ref(0);
let sumTotalExpencePercent = ref(0);

// let filters = reactive({});
let filterExpencesRaw = ref('');
let filterIncomesRaw = ref('');

let sumIncomesRawData = ref(0);
let sumExpencesRawData = ref(0);

let sortExpences = ref('x13');
//let sortExpences = ref('description');

const dataSortingRawExpences = ref('');
const dataSortingOrderRawExpences = ref('asc');

const dataSortingRawIncomes = ref('');
const dataSortingOrderRawIncomes = ref('asc');

chartDataSource = {
  chart: {
    caption: 'Expences/Incomes',
    numberSuffix: ' ' + appUtils.getCurrencySymbolOnly(),
    showPercentValues: '0',
    showPercentInTooltip: '1',
    showlegend: '0',
    theme: 'ocean',
    bgColor: '#212529',
    bgAlpha: '90',
    canvasbgColor: '#212529',
    canvasbgAlpha: '90',
    baseFontColor: '#0066cc',
    outCnvBaseFontColor: '#f8f9fa',
    labelFontColor: '#f8f9fa',

    //theme: 'candy',
  },
  data: [],
};

onBeforeMount(() => {
  year = reactive(utilsAnalysis.getYears());

  //console.log(monthMonitor);

  //selectYear(year.current);
  selectYear(year.current);
  //filters = reactive({ expencesRaw: null, incomesRaw: null });
});

function selectYear(parYear) {
  year.selected = parYear;
  getData();
}

function getClassSelected(par, type) {
  // Y => year | M => month | G => graph
  if (type == 'Y') return year.selected === par ? 'jractive' : '';
  else if (type == 'M') return month.value === par ? 'jractive' : '';
  else if (type == 'G') return chartTypeVal.value === par ? 'jractive' : '';
}

function changeYear(offset) {
  var tmp = year.selected;
  currentOffset.value = currentOffset.value + offset;
  const tmpObj = utilsAnalysis.getYearsOffset(currentOffset.value);
  tmpObj.selected = tmp;

  Object.assign(year, tmpObj);
}

const getYear = computed(function () {
  return year;
});

function fOrder(column, objectItem) {
  appUtils.conlog(column, objectItem);
  sortExpences.value = column;
}

function selectMonth(parMonth) {
  //debugger;
  //console.trace();
  month.value = parMonth;
  getDataDisplay();
}

function getIncomeExpenseRaw(categoryGuid1) {
  //console.log(categoryGuid1);

  //console.log(appUtils.isCategoryIncome(categoryGuid1));
  let isIncome = appUtils.isCategoryIncome(categoryGuid1);
  if (isIncome) arrRawDataDisplayIncome.length = 0;
  else arrRawDataDisplayExpence.length = 0;

  let category1Desc = appUtils.getGuidKontoObj(categoryGuid1).description;

  //arrRawDataDisplayExpence.length = 0;
  let promise = appUtils.getAnalysisRawData(year.selected, categoryGuid1);
  promise.then((values) => {
    const accountStore = useAccountStore();
    const transStore = useTransStore();

    let props = transStore.getFields;
    const allAccounts = accountStore.accounts;

    for (const key in values.snapshot) {
      const item = {};
      item.guid = key;
      item.category1Desc = category1Desc;
      item.categoryDescFull = '';

      if (values.snapshot[key]['guidaccount']) {
        let guidAccountObj = appUtils.getGuidAccountObj(
          values.snapshot[key]['guidaccount'],
          allAccounts
        );
        item.accountDesc = guidAccountObj.description;
      } else item.accountDesc = '';

      for (let i = 0; i < props.length; i++) {
        if (props[i] === 'date')
          if (values.snapshot[key]['date']) {
            item[props[i]] = appUtils.getDateDDMMYYYY(
              values.snapshot[key]['date']
            );
            item['rawDate'] = values.snapshot[key]['date'];
          } else {
            //if date is not available we take termin YYYY-MM
            item[props[i]] = values.snapshot[key]['termin'];
          }
        else item[props[i]] = values.snapshot[key][props[i]];
      }

      item.categoryDescFull = appUtils.getKontoDescFull(
        item.guidknt1,
        item.guidknt2,
        item.guidknt3,
        item.guidknt4
      );

      if (!item.description) item.description = '';
      // console.log('item', item);
      // console.log(getTermin.value);

      if (getTermin.value.slice(-2) == '13') {
        //show raw data for all year
        if (isIncome) arrRawDataDisplayIncome.push(item);
        else arrRawDataDisplayExpence.push(item);
      } else {
        //just show data for selected month
        if (item.termin == getTermin.value) {
          if (isIncome) arrRawDataDisplayIncome.push(item);
          else arrRawDataDisplayExpence.push(item);
        }
      }
    }

    console.log(arrRawDataDisplayExpence);
  });
}

function filterRawData(parFilter, parArray, parSuma) {
  let tmp = [];
  if (parFilter) {
    tmp = parArray
      .filter(
        (el) =>
          el.amount != 0 &&
          (el.description.toUpperCase().includes(parFilter.toUpperCase()) ||
            el.accountDesc.toUpperCase().includes(parFilter.toUpperCase()) ||
            el.categoryDescFull.toUpperCase().includes(parFilter.toUpperCase()))
      )
      .sort((a, b) => {
        return b.termin - a.termin;
      });
  } else {
    tmp = parArray
      .filter((el) => el.amount != 0)
      .sort((a, b) => {
        return b.termin - a.termin;
      });
  }

  parSuma.value = 0;
  for (let i = 0; i < tmp.length; i++) {
    parSuma.value = appUtils.getSumofTwoNumbers(parSuma.value, tmp[i].amount);
  }
  return tmp;
}

const tableDataShow = computed(() => {
  let tmp = appUtils.getObjectCopy(arrRawDataDisplayExpence);
  return filterRawData(filterExpencesRaw.value, tmp, sumExpencesRawData);
});

const tableDataShowIncome = computed(() => {
  let tmp = appUtils.getObjectCopy(arrRawDataDisplayIncome);
  return filterRawData(filterIncomesRaw.value, tmp, sumIncomesRawData);
});

const tableDataIncomeCategory2 = computed(() => {
  return [];
});

const tableDataCategory2 = computed(() => {
  return [];
});

// const tableData = computed(() => {
//   return [];
// });

const chartType = computed(() => {
  return chartTypeVal.value;
});

const getTermin = computed(() => {
  return year.selected + '-' + month.value;
});

const yearmonth = computed(() => {
  return month.value == '13'
    ? year.selected
    : year.selected + '.' + month.value;
});

function eventsIncome(e) {
  // console.log('eventsIncome=>', e);
  // console.log(e.data.categoryLabel);
  // console.log(e.data.toolText);

  getIncomeExpenseRaw(e.data.toolText);
}

function eventsExpense(e) {
  // console.log('eventsExpense=>', e);
  // console.log(e.data.categoryLabel);
  getIncomeExpenseRaw(e.data.toolText);
}

function getSubCaption(parArray) {
  // let ret = '';
  // if (month.value === '00') {
  //   ret = '[' + String(parArray.x13) + ' EUR]';
  // } else {
  //   ret = '[' + String(parArray['x' + month.value]) + ' EUR]';
  // }
  return month.value === '00'
    ? '[' + String(parArray.x13) + ' ' + appUtils.getCurrencySymbolOnly() + ']'
    : '[' +
        String(parArray['x' + month.value]) +
        ' ' +
        appUtils.getCurrencySymbolOnly() +
        ']';
}

const chartDataIncomes = computed(() => {
  let ret = appUtils.getObjectCopy(chartDataSource);
  ret.chart.caption = 'Incomes for ' + year.selected;
  ret.chart.subCaption = getSubCaption(arrTotalIncomes);

  ret.data = arrLvl1Display
    .filter((el) => el.x13 != 0 && el.type === true)
    .sort((a, b) => {
      return b.x13 - a.x13;
    })
    .map(({ description, x13, guid }) => ({
      label: description,
      value: String(x13),
      toolText: guid,
    }));
  return ret;
});

const chartDataExpenses = computed(() => {
  let ret = appUtils.getObjectCopy(chartDataSource);
  ret.chart.caption =
    'Expenses for ' +
    year.selected +
    ' (more than 50 ' +
    appUtils.getCurrencySymbolOnly() +
    ')';
  ret.chart.subCaption = getSubCaption(arrTotalExpenses);

  // .filter((el) => el.x13 != 0 && el.type === false && el.x13 < -100)
  ret.data = arrLvl1Display
    .filter((el) => el.x13 != 0 && el.type === false && el.x13 < -50)
    .sort((a, b) => {
      return b.x13 - a.x13;
    })
    .map(({ description, x13, guid }) => ({
      label: description,
      value: String(x13),
      toolText: guid,
    }));
  return ret;
});

function getData() {
  arrRawDataDisplayIncome.length = 0;
  arrRawDataDisplayExpence.length = 0;

  utilsAnalysis.initializeArr(arrDataYearLvl1, appUtils.getCategory(1));
  utilsAnalysis.initializeArr(arrLvl1Display, appUtils.getCategory(1));

  utilsAnalysis.initializeSums(arrTotalIncomes);
  utilsAnalysis.initializeSums(arrTotalExpenses);
  utilsAnalysis.initializeSums(arrTotalSavings);

  let promises = [];
  for (let i = 1; i < 13; i++) {
    let month = ('0' + String(i)).slice(-2);
    promises.push(
      appUtils.getAnalysisYearMonth(year.selected + '-' + month, true)
    );
  }

  //avoiding error Maximum recursive updates
  //let arrTotalIncomesTmp = appUtils.getObjectCopy(arrTotalIncomes);
  //let arrTotalExpensesTmp = appUtils.getObjectCopy(arrTotalExpenses);

  Promise.all(promises).then((values) => {
    for (let i = 0; i < 13 - 1; i++) {
      utilsAnalysis.prepareAnalysisYear(
        values[i],
        arrDataYearLvl1,
        null,
        null,
        null,
        arrTotalIncomes,
        arrTotalExpenses
      );
    }

    //console.log(arrDataYearLvl1);

    // console.log(arrTotalIncomesTmp);
    // console.log(arrTotalExpensesTmp);

    //console.log(arrTotalIncomes);
    //console.log(arrTotalExpenses);

    //let arrDataYearLvl1Tmp = appUtils.getObjectCopy(arrDataYearLvl1);
    //console.log(arrDataYearLvl1Tmp);

    //utilsAnalysis.getSumMonths(arrDataYearLvl1Tmp);
    utilsAnalysis.getSumMonths(arrDataYearLvl1);

    //console.log(arrDataYearLvl1Tmp);
    //console.log(arrDataYearLvl1);

    utilsAnalysis.getYearlyTotals(
      arrTotalIncomes,
      arrTotalExpenses,
      arrTotalSavings
    );

    // for (let i = 1; i <= 13; i++) {
    //   let month = ('0' + String(i)).slice(-2);
    //   arrTotalIncomes['x' + month] = arrTotalIncomesTmp['x' + month];
    //   arrTotalExpenses['x' + month] = arrTotalExpensesTmp['x' + month];
    // }

    sumSavings.value = appUtils.fixCurrency(arrTotalSavings.x13);

    //copy for display so we don't have to hit database for different months but just for year
    Object.assign(arrLvl1Display, arrDataYearLvl1);
    //Object.assign(arrLvl1Display, arrDataYearLvl1Tmp);

    //console.log(arrLvl1Display);
  });
}

function getDataDisplay() {
  arrRawDataDisplayIncome.length = 0;
  arrRawDataDisplayExpence.length = 0;

  arrLvl1Display.length = 0;
  for (let i = 0; i < arrDataYearLvl1.length; i++) {
    let item = {};
    item.guid = arrDataYearLvl1[i].guid;
    item.description = arrDataYearLvl1[i].description;
    item.type = arrDataYearLvl1[i].type;
    item.x13 = arrDataYearLvl1[i]['x' + month.value];
    arrLvl1Display.push(item);
  }
  sumSavings.value = appUtils.fixCurrency(arrTotalSavings['x' + month.value]);
}

const tableDataIncome = computed(() => {
  let tmp = [];

  if (arrLvl1Display.length > 0) {
    tmp = arrLvl1Display
      .filter((el) => el.x13 != 0 && el.type === true)
      .sort((a, b) => {
        return b[sortExpences.value] - a[sortExpences.value];
        //return b['x13'] - a['x13'];
      })
      .map(({ description, x13, guid }) => ({
        description: description,
        sumamount: x13,
        percent: 0,
        guid: guid,
      }));
  }

  sumTotalIncome.value = 0;
  for (let i = 0; i < tmp.length; i++) {
    sumTotalIncome.value = appUtils.getSumofTwoNumbers(
      sumTotalIncome.value,
      tmp[i].sumamount
    );
  }
  sumTotalIncome.value = sumTotalIncome.value.toFixed(2);

  sumTotalIncomePercent.value = 0;
  for (let i = 0; i < tmp.length; i++) {
    tmp[i].percent = appUtils.fixCurrency(
      (100 * tmp[i].sumamount) / sumTotalIncome.value
    );
    sumTotalIncomePercent.value = appUtils.fixCurrency(
      sumTotalIncomePercent.value + tmp[i].percent
    );
    tmp[i].currency = tmp[i].sumamount.toFixed(2);
  }

  if (sumTotalIncomePercent.value > 99.9) sumTotalIncomePercent.value = 100;

  return tmp;
});

const tableDataExpence = computed(() => {
  let tmp = [];

  if (arrLvl1Display.length > 0) {
    tmp = arrLvl1Display
      .filter((el) => el.x13 != 0 && el.type === false)
      .sort((a, b) => {
        return a[sortExpences.value] - b[sortExpences.value];
      })
      .map(({ description, x13, guid }) => ({
        description: description,
        sumamount: x13,
        percent: 0,
        guid: guid,
        currency: 0,
      }));
  }

  sumTotalExpence.value = 0;
  for (let i = 0; i < tmp.length; i++) {
    sumTotalExpence.value = appUtils.getSumofTwoNumbers(
      sumTotalExpence.value,
      tmp[i].sumamount
    );
  }
  sumTotalExpence.value = sumTotalExpence.value.toFixed(2);

  sumTotalExpencePercent.value = 0;
  for (let i = 0; i < tmp.length; i++) {
    tmp[i].currency = tmp[i].sumamount.toFixed(2);
    tmp[i].percent = appUtils.fixCurrency(
      (100 * tmp[i].sumamount) / sumTotalExpence.value
    );
    sumTotalExpencePercent.value = appUtils.fixCurrency(
      sumTotalExpencePercent.value + tmp[i].percent
    );
  }

  sumTotalExpencePercent.value = sumTotalExpencePercent.value.toFixed(2);

  if (sumTotalExpencePercent.value > 99.9) sumTotalExpencePercent.value = 100;

  return tmp;
});

function sortDataIncomesExpences(
  field,
  incomeExpence,
  arrRaw,
  dataSorting = null,
  dataSortingOrder = null
) {
  //incomeExpence E or I - Expence, Income
  console.log(dataSorting);
  console.log(dataSortingOrder);

  let payload = {};

  // appUtils.setSortingParams(field, dataSorting, dataSortingOrder);
  // payload = { field: field, order: dataSortingOrder.value };

  if (incomeExpence == 'E') {
    appUtils.setSortingParams(
      field,
      dataSortingRawExpences,
      dataSortingOrderRawExpences
    );
    payload = { field: field, order: dataSortingOrderRawExpences.value };
  } else if (incomeExpence == 'I') {
    appUtils.setSortingParams(
      field,
      dataSortingRawIncomes,
      dataSortingOrderRawIncomes
    );
    payload = { field: field, order: dataSortingOrderRawIncomes.value };
  }

  let tmp = appUtils.getObjectCopy(arrRaw);
  tmp = appUtils.setSortingUtil(payload, tmp);
  arrRaw.length = 0;

  if (incomeExpence == 'E') {
    arrRawDataDisplayExpence = reactive(tmp);
  } else if (incomeExpence == 'I') {
    arrRawDataDisplayIncome = reactive(tmp);
  }
}

function sortDataIncomes(field) {
  sortDataIncomesExpences(
    field,
    'I',
    arrRawDataDisplayIncome,
    dataSortingRawIncomes,
    dataSortingOrderRawIncomes
  );
}

function sortDataExpences(field) {
  sortDataIncomesExpences(
    field,
    'E',
    arrRawDataDisplayExpence,
    dataSortingRawExpences,
    dataSortingOrderRawExpences
  );
}
</script>
