import { createRouter, createWebHistory } from 'vue-router';

import AccountsList from './pages/accounts/AccountList.vue';
import Groups from './pages/groups/AccountGroups.vue';
import GlobalGroupsList from './pages/settings/GlobalGroups.vue';
import AddTransactionSetup from './pages/transactions/AddTransactionSetup.vue';
import Analysis from './pages/analysis/Analysis.vue';
import AnalysisYear from './pages/analysis/AnalysisYear.vue';
import AnalysisList from './pages/analysis/AnalysisList.vue';
import CategoryList from './pages/categories/CategoryList.vue';
import ChartSetup from './pages/analysis/ChartTestSetup.vue';
import ChartTest from './pages/analysis/ChartTest.vue';
import Rubik from './pages/rubik/helper.vue';
import RubikOld from './pages/rubik/helper-old.vue';

//import CoachesList from './pages/coaches/CoachesList.vue';
import NotFound from './pages/NotFound.vue';
// import { defineAsyncComponent } from 'vue';
import { useAuthStore } from './stores/AuthStore';
//import store from './store/index.js';

// const UserAuth = defineAsyncComponent(() =>
//   import('./pages/auth/UserAuth.vue')
// );
const UserAuth = () => import('./pages/auth/UserAuth.vue');

const PeriodicTrans = () => import('./pages/transactions/PeriodicTrans.vue');

const Rules = () => import('./pages/rules/Rules.vue');

// () => import('./MyPage.vue')
// defineAsyncComponent(() => import('./MyPage.vue'))
//====================================

// const Settings = defineAsyncComponent(() =>
//   import('./pages/settings/UserSettings.vue')
// );
const Settings = () => import('./pages/settings/UserSettings.vue');

const InvestSymbols = () => import('./pages/investSymbols/investSymbols.vue');
const InvestList = () => import('./pages/investments/InvestmentList.vue');

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', redirect: '/auth' },
    { path: '/chart', component: ChartTest },
    { path: '/chartsetup', component: ChartSetup },
    { path: '/newtrans2', component: AddTransactionSetup },
    { path: '/categories', component: CategoryList },
    { path: '/accounts', component: AccountsList },
    { path: '/analysis', component: AnalysisList },
    { path: '/analysisyear', component: AnalysisYear },
    { path: '/analysisgraph', component: Analysis },
    { path: '/periodic', component: PeriodicTrans },
    { path: '/settings', component: Settings },
    { path: '/settings-gl', component: GlobalGroupsList },
    { path: '/rules', component: Rules },
    { path: '/rubik', component: Rubik },
    { path: '/rubik-old', component: RubikOld },
    { path: '/accountGroups', component: Groups },
    { path: '/invest', component: InvestSymbols },
    { path: '/invest-list', component: InvestList },

    // {
    //   path: '/coaches/:id',
    //   component: CoachDetail,
    //   props: true,
    //   children: [
    //     { path: 'contact', component: ContactCoach }, // /coaches/c1/contact
    //   ],
    // },
    // {
    //   path: '/register',
    //   component: CoachRegistration,
    //   meta: { requiresAuth: true },
    // },

    { path: '/auth', component: UserAuth, meta: { requiresUnauth: true } },
    { path: '/:notFound(.*)', component: NotFound },
  ],
});

router.beforeEach(function (to, _, next) {
  const authStore = useAuthStore();
  let isAuth = authStore.isAuthenticated;

  // console.log('isAuth');
  // console.log(isAuth);

  if (to.meta.requiresAuth && !isAuth) {
    next('/auth');
  } else if (to.meta.requiresUnauth && isAuth) {
    next('/newTrans2');
  } else {
    next();
  }
});

export default router;
